var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget") || (depth0 != null ? lookupProperty(depth0,"widget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":19}}}) : helper)))
    + "-upload-widget\" class=\"inline-block image_upload_widget\">\n    <div class=\"image-disabled "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_editable_by_current_user") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":77}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-upload-background\"></div>\n        <span class=\"image-disabled-text flex\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"disabled_text") || (depth0 != null ? lookupProperty(depth0,"disabled_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disabled_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":78}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"disabled_text") || (depth0 != null ? lookupProperty(depth0,"disabled_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disabled_text","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":31}}}) : helper)))
    + "\n        </span>\n    </div>\n    <div class=\"image_upload_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_editable_by_current_user") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":90}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"image-upload-background\"></div>\n        <button class=\"image-delete-button\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"delete_text") || (depth0 != null ? lookupProperty(depth0,"delete_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":73}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            &times;\n        </button>\n        <span class=\"image-delete-text\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"delete_text") || (depth0 != null ? lookupProperty(depth0,"delete_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":13,"column":52},"end":{"line":13,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"delete_text") || (depth0 != null ? lookupProperty(depth0,"delete_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"delete_text","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":29}}}) : helper)))
    + "\n        </span>\n        <span class=\"image-upload-text\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"upload_text") || (depth0 != null ? lookupProperty(depth0,"upload_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":16,"column":52},"end":{"line":16,"column":69}}}) : helper)))
    + "\" role=\"button\" tabindex=\"0\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"upload_text") || (depth0 != null ? lookupProperty(depth0,"upload_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":29}}}) : helper)))
    + "\n        </span>\n        <span class=\"upload-spinner-background\">\n            <img class=\"image_upload_spinner\" src=\"/static/images/loading/tail-spin.svg\" alt=\"\" />\n        </span>\n    </div>\n    <img class=\"image-block\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":23,"column":34},"end":{"line":23,"column":45}}}) : helper)))
    + "\"/>\n    <input type=\"file\" name=\"file_input\" class=\"notvisible image_file_input\"  value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"upload_text") || (depth0 != null ? lookupProperty(depth0,"upload_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"upload_text","hash":{},"data":data,"loc":{"start":{"line":24,"column":85},"end":{"line":24,"column":102}}}) : helper)))
    + "\" />\n    <div class=\"image_file_input_error text-error\"></div>\n</div>\n";
},"useData":true});