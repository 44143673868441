var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-profile\" data-name=\"organization-profile\" class=\"settings-section\">\n    <form class=\"form-horizontal admin-realm-form org-profile-form\">\n        <div class=\"alert\" id=\"admin-realm-deactivation-status\"></div>\n\n        <div id=\"org-org-profile\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":49}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/create-your-organization-profile"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"org-profile"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"organization-settings-parent\">\n                <div class=\"input-group admin-realm\">\n                    <label for=\"id_realm_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":73}}}))
    + "</label>\n                    <input type=\"text\" id=\"id_realm_name\" name=\"realm_name\" class=\"admin-realm-name setting-widget prop-element\"\n                      autocomplete=\"off\" data-setting-widget-type=\"string\"\n                      value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"realm_name") : depth0), depth0))
    + "\" maxlength=\"40\" />\n                </div>\n                <div class=\"input-group admin-realm\">\n                    <label for=\"realm_org_type\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":71},"end":{"line":21,"column":97}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/organization-type"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_org_type\" class=\"setting-widget prop-element\" id=\"id_realm_org_type\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"realm_org_type_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/communities-directory","label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_want_advertise_in_communities_directory") : stack1),"is_disabled":(depth0 != null ? lookupProperty(depth0,"disable_want_advertise_in_communities_directory") : depth0),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_want_advertise_in_communities_directory") : depth0),"prefix":"id_","setting_name":"realm_want_advertise_in_communities_directory"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"input-group admin-realm\">\n                    <label for=\"realm_description\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":51},"end":{"line":36,"column":84}}}))
    + "</label>\n                    <textarea id=\"id_realm_description\" name=\"realm_description\" class=\"admin-realm-description setting-widget prop-element\"\n                      maxlength=\"1000\" data-setting-widget-type=\"string\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"realm_description") : depth0), depth0))
    + "</textarea>\n                </div>\n            </div>\n        </div>\n\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":13},"end":{"line":43,"column":50}}}))
    + "</div>\n        <div class=\"realm-icon-section\">\n            "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),depth0,{"name":"image_upload_widget","hash":{"image":(depth0 != null ? lookupProperty(depth0,"realm_icon_url") : depth0),"is_editable_by_current_user":(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete icon",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":45}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload icon",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":47,"column":45}}}),"widget":"realm-icon"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <a href=\"/login/?preview=true\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"button rounded sea-green w-200 block\" id=\"id_org_profile_preview\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":49}}}))
    + "\n            <i class=\"fa fa-external-link\" aria-hidden=\"true\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":69},"end":{"line":54,"column":106}}}))
    + "\"></i>\n        </a>\n\n        <div class=\"subsection-header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":42}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/create-your-organization-profile#add-a-wide-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A wide image for the upper left corner of the app.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":11},"end":{"line":64,"column":70}}}))
    + "</p>\n        <div class=\"realm-logo-group\">\n            <div class=\"realm-logo-block realm-logo-section\">\n                <h5 class=\"realm-logo-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Day logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":45},"end":{"line":67,"column":62}}}))
    + "</h5>\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),depth0,{"name":"image_upload_widget","hash":{"image":(depth0 != null ? lookupProperty(depth0,"realm_logo_url") : depth0),"is_editable_by_current_user":(depth0 != null ? lookupProperty(depth0,"user_can_change_logo") : depth0),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":32},"end":{"line":71,"column":49}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":70,"column":49}}}),"widget":"realm-day-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"realm-logo-block realm-logo-section\">\n                <h5 class=\"realm-logo-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Night logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":45},"end":{"line":76,"column":64}}}))
    + "</h5>\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),depth0,{"name":"image_upload_widget","hash":{"image":(depth0 != null ? lookupProperty(depth0,"realm_night_logo_url") : depth0),"is_editable_by_current_user":(depth0 != null ? lookupProperty(depth0,"user_can_change_logo") : depth0),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":32},"end":{"line":80,"column":49}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload logo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":49}}}),"widget":"realm-night-logo"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <h3 class=\"light\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":86,"column":44}}}))
    + "\n            <i class=\"fa fa-info-circle settings-info-icon realm_deactivation_tooltip tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only owners can deactivate the organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":146},"end":{"line":87,"column":199}}}))
    + "\"></i>\n        </h3>\n        <div class=\"deactivate-realm-section\">\n            <div class=\"input-group\">\n                <button class=\"button rounded btn-danger\" id=\"deactivate_realm_button\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":20},"end":{"line":92,"column":52}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});