var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"edit-bot-buttons\">\n                <button type=\"submit\" class=\"btn open_edit_bot_form\" data-sidebar-form=\"edit-bot\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":105},"end":{"line":8,"column":122}}}))
    + "\" data-email=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":8,"column":136},"end":{"line":8,"column":145}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-pencil blue\" aria-hidden=\"true\"></i>\n                </button>\n                <a type=\"submit\" download=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"zuliprc") || (depth0 != null ? lookupProperty(depth0,"zuliprc") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"zuliprc","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":54}}}) : helper)))
    + "\" class=\"btn download_bot_zuliprc\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":96},"end":{"line":11,"column":121}}}))
    + "\" data-email=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":11,"column":135},"end":{"line":11,"column":144}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-download sea-green\" aria-hidden=\"true\"></i>\n                </a>\n                <button type=\"submit\" id=\"copy_zuliprc\" class=\"btn copy_zuliprc\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":109}}}))
    + "\">\n                    <i class=\"fa fa-clipboard copy-gold\"></i>\n                </button>\n                <button type=\"submit\" class=\"btn deactivate_bot danger-red\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":83},"end":{"line":17,"column":106}}}))
    + "\" data-user-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":17,"column":122},"end":{"line":17,"column":133}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-user-times\" aria-hidden=\"true\"></i>\n                </button>\n                <button type=\"submit\" class=\"btn open_bots_subscribed_streams\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribed streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":86},"end":{"line":20,"column":113}}}))
    + "\" data-user-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":20,"column":129},"end":{"line":20,"column":140}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-hashtag purple\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"api_key\">\n            <span class=\"field\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":32},"end":{"line":38,"column":48}}}))
    + "</span>\n            <div class=\"api-key-value-and-button no-select\">\n                <!-- have the `.text-select` in `.no-select` so that the value doesn't have trailing whitespace. -->\n                <span class=\"value text-select\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"api_key") || (depth0 != null ? lookupProperty(depth0,"api_key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"api_key","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":59}}}) : helper)))
    + "</span>\n                <button type=\"submit\" class=\"button no-style btn-secondary regenerate_bot_api_key\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":106},"end":{"line":42,"column":135}}}))
    + "\" data-user-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":42,"column":151},"end":{"line":42,"column":162}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-refresh\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n            <div class=\"api_key_error text-error\"></div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button round btn-warning reactivate_bot\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":71},"end":{"line":49,"column":94}}}))
    + "\" data-user-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":49,"column":110},"end":{"line":49,"column":121}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":123},"end":{"line":49,"column":146}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"bot-information-box white-box\">\n    <div class=\"image overflow-hidden\">\n        <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"avatar_url") || (depth0 != null ? lookupProperty(depth0,"avatar_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar_url","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":32}}}) : helper)))
    + "\" class=\"avatar\" />\n        <div class=\"details\">\n            <div class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":38}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"bot_info\" data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":27,"column":51}}}) : helper)))
    + "\">\n        <div class=\"type\">\n            <div class=\"field\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":31},"end":{"line":29,"column":48}}}))
    + "</div>\n            <div class=\"value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":39}}}) : helper)))
    + "</div>\n        </div>\n        <div class=\"email\">\n            <div class=\"field\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Bot email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":49}}}))
    + "</div>\n            <div class=\"value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":34,"column":31},"end":{"line":34,"column":40}}}) : helper)))
    + "</div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"bot_error alert alert-error hide\"></div>\n    </div>\n</li>\n";
},"useData":true});