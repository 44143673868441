var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"respond_button\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":65}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-reply\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote and reply or forward",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":94}}}))
    + "\n            <span class=\"hotkey-hint\">(>)</span>\n        </a>\n    </li>\n    <hr />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_edit_message\" data-message-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":71}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"editability_menu_item") || (depth0 != null ? lookupProperty(depth0,"editability_menu_item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"editability_menu_item","hash":{},"data":data,"loc":{"start":{"line":16,"column":60},"end":{"line":16,"column":85}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(e)</span>\n        </a>\n    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_move_message\" data-message-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":71}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-arrows\" aria-hidden=\"true\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"move_message_menu_item") || (depth0 != null ? lookupProperty(depth0,"move_message_menu_item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"move_message_menu_item","hash":{},"data":data,"loc":{"start":{"line":25,"column":60},"end":{"line":25,"column":86}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(m)</span>\n        </a>\n    </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"delete_message\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":33,"column":51},"end":{"line":33,"column":65}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":35,"column":35}}}))
    + "\n        </a>\n    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <hr />\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"reaction_button\" data-message-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":66}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-smile-o\" aria-hidden=\"true\"></i>\n            "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":48,"column":44}}})) != null ? stack1 : "")
    + "\n        </a>\n    </li>\n    <hr />\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "Add emoji reaction";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a class=\"mark_as_unread\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":56,"column":55},"end":{"line":56,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n                <span class=\"unread_count\">1</span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unread from here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":49}}}))
    + "\n                <span class=\"hotkey-hint\">(U)</span>\n            </a>\n        </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class='reminder_button' data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":66,"column":52},"end":{"line":66,"column":66}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-bell\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Remind me about this",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":58},"end":{"line":67,"column":87}}}))
    + "\n        </a>\n    </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"rehide_muted_user_message\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":74,"column":62},"end":{"line":74,"column":76}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-eye-slash\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide muted message again",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":45}}}))
    + "\n        </a>\n    </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_toggle_collapse\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":83,"column":60},"end":{"line":83,"column":74}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-minus\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":85,"column":37}}}))
    + " <span class=\"hotkey-hint\">(–)</span>\n        </a>\n    </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_toggle_collapse\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":92,"column":60},"end":{"line":92,"column":74}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-plus\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":94,"column":35}}}))
    + " <span class=\"hotkey-hint\">(–)</span>\n        </a>\n    </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"popover_view_source\" data-message-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":105,"column":56},"end":{"line":105,"column":70}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-file-code-o\" aria-hidden=\"true\"></i> "
    + alias4(((helper = (helper = lookupProperty(helpers,"view_source_menu_item") || (depth0 != null ? lookupProperty(depth0,"view_source_menu_item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"view_source_menu_item","hash":{},"data":data,"loc":{"start":{"line":106,"column":65},"end":{"line":106,"column":90}}}) : helper)))
    + "\n            <span class=\"hotkey-hint\">(e)</span>\n        </a>\n    </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"view_edit_history\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":114,"column":54},"end":{"line":114,"column":68}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-clock-o\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View edit history",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":12},"end":{"line":116,"column":38}}}))
    + "\n        </a>\n    </li>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"view_read_receipts\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":123,"column":55},"end":{"line":123,"column":69}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"zulip-icon zulip-icon-readreceipts\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":70},"end":{"line":124,"column":102}}})) != null ? stack1 : "")
    + "\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":109},"end":{"line":124,"column":136}}}))
    + "\n        </a>\n    </li>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "View read receipts";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list actions_popover\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_quote_and_reply") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editability_menu_item") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"move_message_menu_item") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_delete_option") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,(depth0 != null ? lookupProperty(depth0,"editability_menu_item") : depth0),(depth0 != null ? lookupProperty(depth0,"move_message_menu_item") : depth0),(depth0 != null ? lookupProperty(depth0,"should_display_delete_option") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":40,"column":88}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":42,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_add_reaction_option") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_mark_as_unread") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":62,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_reminder_option") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":4},"end":{"line":70,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_hide_option") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":4},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_collapse") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":88,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_uncollapse") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":4},"end":{"line":97,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_mark_as_unread") : depth0),(depth0 != null ? lookupProperty(depth0,"should_display_reminder_option") : depth0),(depth0 != null ? lookupProperty(depth0,"should_display_hide_option") : depth0),(depth0 != null ? lookupProperty(depth0,"should_display_collapse") : depth0),(depth0 != null ? lookupProperty(depth0,"should_display_uncollapse") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":99,"column":10},"end":{"line":99,"column":152}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":4},"end":{"line":101,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"view_source_menu_item") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":4},"end":{"line":110,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_edit_history_option") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":4},"end":{"line":119,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_read_receipts_option") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":4},"end":{"line":127,"column":11}}})) != null ? stack1 : "")
    + "\n    <li>\n        <a class=\"copy_link\" data-message-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"message_id") || (depth0 != null ? lookupProperty(depth0,"message_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":130,"column":46},"end":{"line":130,"column":60}}}) : helper)))
    + "\" data-clipboard-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"conversation_time_uri") || (depth0 != null ? lookupProperty(depth0,"conversation_time_uri") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"conversation_time_uri","hash":{},"data":data,"loc":{"start":{"line":130,"column":83},"end":{"line":130,"column":110}}}) : helper)))
    + "\" tabindex=\"0\">\n            <i class=\"fa fa-link\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":58},"end":{"line":131,"column":87}}}))
    + "\n        </a>\n    </li>\n</ul>\n";
},"useData":true});