var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"message_sender sender_info_hover no-select\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"include_sender") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":44}}}))
    + " (u)\">\n            "
    + ((stack1 = container.invokePartial(require("./message_avatar.hbs"),depth0,{"name":"message_avatar","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<span class=\"sender_name auto-select\" role=\"button\" tabindex=\"0\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"sender_full_name") : stack1), depth0))
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"status_emoji_info") : stack1),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sender_is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"zulip-icon zulip-icon-bot\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":73}}}))
    + "\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./me_message.hbs"),depth0,{"name":"me_message","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return " notvisible";
},"9":function(container,depth0,helpers,partials,data) {
    return " status-time";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"copy-paste-text\">&nbsp;</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_hidden") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":48,"column":15}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"message_content rendered_markdown\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"use_match_properties") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"match_content") : stack1),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":41,"column":51}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"content") : stack1),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":43,"column":45}}}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./message_hidden_dialog.hbs"),depth0,{"name":"message_hidden_dialog","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"message_reactions\">"
    + ((stack1 = container.invokePartial(require("./message_reactions.hbs"),depth0,{"name":"message_reactions","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_top_line\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_message") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_message") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "\n    <span class=\"alert-msg pull-right\"></span>\n\n    <a href=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"message_time"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"locally_echoed") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":91}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_message") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":91},"end":{"line":22,"column":132}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"include_sender") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"timestr") : depth0), depth0))
    + "\n    </a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edited_alongside_sender") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./message_controls.hbs"),depth0,{"name":"message_controls","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_message") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":0},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edited_in_left_col") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":0},"end":{"line":53,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"message_edit\">\n    <div class=\"message_edit_form\"></div>\n</div>\n<div class=\"message_expander message_length_controller\" title=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Expand message (-)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":63},"end":{"line":58,"column":90}}}))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias1,"[More…]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":92},"end":{"line":58,"column":108}}}))
    + "</div>\n<div class=\"message_condenser message_length_controller\" title=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Show less",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":64},"end":{"line":59,"column":82}}}))
    + " (-)\">["
    + alias3(lookupProperty(helpers,"t").call(alias1,"Show less",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":89},"end":{"line":59,"column":107}}}))
    + "]</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_hidden") : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":0},"end":{"line":63,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});