var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data) {
    return "Add extra emoji for members of the {realm_name} organization.";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"emoji-settings\" data-name=\"emoji-settings\" class=\"settings-section\">\n    <div class=\"emoji-settings-tip-container\">\n        "
    + ((stack1 = container.invokePartial(require("./emoji_settings_tip.hbs"),depth0,{"name":"emoji_settings_tip","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <p class=\"add-emoji-text "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_add_emojis") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":70}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":83}}})) != null ? stack1 : "")
    + "\n    </p>\n    <form class=\"form-horizontal admin-emoji-form "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_add_emojis") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":50},"end":{"line":8,"column":91}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"add-new-emoji-box grey-box\">\n            <div class=\"new-emoji-form\">\n                <div class=\"settings-section-title new-emoji-section-title no-padding\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":87},"end":{"line":11,"column":111}}}))
    + "</div>\n                <div class=\"alert\" id=\"admin-emoji-status\"></div>\n                <div class=\"inline-block\">\n                    <label for=\"emoji_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":63}}}))
    + "</label>\n                    <input type=\"text\" name=\"name\" id=\"emoji_name\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"leafy green vegetable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":80},"end":{"line":15,"column":110}}}))
    + "\" />\n                </div>\n                <div class=\"inline-block\">\n                    <span id=\"emoji-file-name\"></span>\n                    <input type=\"file\" name=\"emoji_file_input\" class=\"notvisible\"\n                      id=\"emoji_file_input\" value=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload image or GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":79}}}))
    + "\"/>\n                    <button class=\"button white rounded\" style=\"display: none;\" id=\"emoji_image_clear_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear emoji image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":110},"end":{"line":21,"column":136}}}))
    + "</button>\n                    <button class=\"button rounded\" id=\"emoji_upload_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload image or GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":76},"end":{"line":22,"column":104}}}))
    + "</button>\n                </div>\n                <button id=\"admin_emoji_submit\" type=\"submit\" class=\"button rounded sea-green\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":38}}}))
    + "\n                </button>\n            </div>\n            <span id=\"emoji_file_input_error\" class=\"text-error\"></span>\n            <span style=\"display: none;\" id=\"emoji_preview_text\">\n                Preview: <img class=\"emoji\" id=\"emoji_preview_image\" />\n            </span>\n        </div>\n    </form>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":36,"column":32}}}))
    + "</h3>\n        <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":55},"end":{"line":37,"column":76}}}))
    + "\"\n          aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":22},"end":{"line":38,"column":43}}}))
    + "\"/>\n    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-condensed table-striped wrapped-table admin_emoji_table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":80},"end":{"line":43,"column":93}}}))
    + "</th>\n                <th class=\"image\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":34},"end":{"line":44,"column":48}}}))
    + "</th>\n                <th class=\"image\" data-sort=\"author_full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Author",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":63},"end":{"line":45,"column":78}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_emoji_table\" class=\"required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":76},"end":{"line":48,"column":101}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});