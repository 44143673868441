var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a role=\"button\" data-format-type=\"bold\" class=\"compose_control_button fa fa-bold formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":113},"end":{"line":1,"column":126}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bold",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":159},"end":{"line":1,"column":172}}}))
    + "\"></a>\n<a role=\"button\" data-format-type=\"italic\" class=\"compose_control_button fa fa-italic formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Italic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":117},"end":{"line":2,"column":132}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Italic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":165},"end":{"line":2,"column":180}}}))
    + "\"></a>\n<a role=\"button\" data-format-type=\"link\" class=\"compose_control_button fa fa-link formatting_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":113},"end":{"line":3,"column":126}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":159},"end":{"line":3,"column":172}}}))
    + "\"></a>\n<div class=\"divider hide-sm\">|</div>\n<a role=\"button\" class=\"compose_control_button compose_help_button fa fa-question\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":114},"end":{"line":5,"column":141}}}))
    + "\" data-overlay-trigger=\"message-formatting\"></a>\n";
},"useData":true});