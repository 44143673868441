var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"emoji-popover-tab-item "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":77}}})) != null ? stack1 : "")
    + "\" data-tab-name='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "' title='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "'><i class=\"fa "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></i></span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active ";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"emoji-popover-subheading\" data-section=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n            <div class=\"emoji-collection\" data-section=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"emojis") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = container.invokePartial(require("./emoji_popover_emoji.hbs"),depth0,{"name":"emoji_popover_emoji","hash":{"emoji_dict":depth0,"is_status_emoji_popover":(depths[2] != null ? lookupProperty(depths[2],"is_status_emoji_popover") : depths[2]),"message_id":(depths[2] != null ? lookupProperty(depths[2],"message_id") : depths[2]),"index":(data && lookupProperty(data,"index")),"section":(container.data(data, 1) && lookupProperty(container.data(data, 1),"index")),"type":"emoji_picker_emoji"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emoji-popover\">\n    <div class=\"emoji-popover-top\">\n        <input class=\"emoji-popover-filter\" type=\"text\" autofocus placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":79},"end":{"line":3,"column":94}}}))
    + "\" />\n        <i class=\"fa fa-search\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"emoji-popover-category-tabs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"emoji_categories") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"emoji-popover-emoji-map\" data-simplebar data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"emoji_categories") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":19,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"emoji-search-results-container\" data-simplebar data-simplebar-auto-hide=\"false\" data-message-id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\">\n        <div class=\"emoji-popover-results-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":51},"end":{"line":22,"column":74}}}))
    + "</div>\n        <div class=\"emoji-search-results\"></div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});