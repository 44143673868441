var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group\">\n    <label for=\"edit_service_base_url\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Endpoint URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":60}}}))
    + "</label>\n    <input id=\"edit_service_base_url\" type=\"text\" name=\"service_payload_url\" class=\"edit_service_base_url required\" value=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"service") : depth0)) != null ? lookupProperty(stack1,"base_url") : stack1), depth0))
    + "\" maxlength=2083 />\n    <div><label for=\"edit_service_base_url\" generated=\"true\" class=\"text-error\"></label></div>\n</div>\n<div class=\"input-group\">\n    <label for=\"edit_service_interface\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Interface",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":58}}}))
    + "</label>\n    <select id=\"edit_service_interface\" name=\"service_interface\">\n        <option value=\"1\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":42}}}))
    + "</option>\n        <option value=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Slack's outgoing webhooks",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":60}}}))
    + "</option>\n    </select>\n</div>\n";
},"useData":true});