var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unpin stream from top",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":41}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Pin stream to top",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":37}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"zulip-icon zulip-icon-mute\" aria-hidden=\"true\"></i>\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unmute stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":43,"column":33}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"zulip-icon zulip-icon-mute\" aria-hidden=\"true\"></i>\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mute stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":46,"column":31}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list streams_popover\" data-stream-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n    <li>\n        <p class=\"topic-name\">\n            <span id=\"stream_sidebar_privacy_swatch_"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" class=\"stream-privacy filter-icon\" style=\"color: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","hash":{"is_web_public":((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"is_web_public") : stack1),"invite_only":((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"invite_only") : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n            <b>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</b>\n        </p>\n    </li>\n\n    <hr />\n\n    <li>\n        <a tabindex=\"0\" class=\"open_stream_settings\">\n            <i class=\"fa fa-cog\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Stream settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":36}}}))
    + "\n        </a>\n    </li>\n    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"pin_to_top\">\n            <i class=\"fa fa-thumb-tack stream-pin-icon\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"pin_to_top") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </li>\n    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"mark_stream_as_read\">\n            <i class=\"fa fa-book\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":36,"column":45}}}))
    + "\n        </a>\n    </li>\n    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"toggle_stream_muted\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"is_muted") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </li>\n    <li>\n        <a tabindex=\"0\" class=\"popover_new_topic_button\">\n            <i class=\"fa fa-plus\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":30}}}))
    + "\n        </a>\n    </li>\n    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"popover_sub_unsub_button\" data-name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n            <i class='fa fa-envelope' aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":59,"column":32}}}))
    + "\n        </a>\n    </li>\n    <li class=\"hidden-for-spectators\">\n        <span class=\"colorpicker-container\"><input stream_id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" class=\"colorpicker\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" /></span>\n        <a tabindex=\"0\" class=\"choose_stream_color\">\n            <i class=\"fa fa-eyedropper\" aria-hidden=\"true\"></i>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Change color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":66,"column":33}}}))
    + "\n        </a>\n    </li>\n\n</ul>\n";
},"usePartial":true,"useData":true});