var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"note_html") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":21,"column":35}}})) != null ? stack1 : "")
    + "                        </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <td colspan=\"2\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"note_html") || (depth0 != null ? lookupProperty(depth0,"note_html") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"note_html","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":59}}}) : helper))) != null ? stack1 : "")
    + "</td>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <td><div class=\"preserve_spaces\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"markdown") || (depth0 != null ? lookupProperty(depth0,"markdown") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"markdown","hash":{},"data":data,"loc":{"start":{"line":19,"column":61},"end":{"line":19,"column":73}}}) : helper)))
    + "</div> "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"usage_html") || (depth0 != null ? lookupProperty(depth0,"usage_html") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"usage_html","hash":{},"data":data,"loc":{"start":{"line":19,"column":80},"end":{"line":19,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</td>\n                            <td class=\"rendered_markdown\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"output_html") || (depth0 != null ? lookupProperty(depth0,"output_html") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"output_html","hash":{},"data":data,"loc":{"start":{"line":20,"column":58},"end":{"line":20,"column":75}}}) : helper))) != null ? stack1 : "")
    + " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"effect_html") || (depth0 != null ? lookupProperty(depth0,"effect_html") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effect_html","hash":{},"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":93}}}) : helper))) != null ? stack1 : "")
    + "</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-modal hide\" id=\"message-formatting\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":41}}}))
    + "\">\n    <div class=\"modal-body\" data-simplebar data-simplebar-auto-hide=\"false\">\n        <div id=\"markdown-instructions\">\n            <table class=\"table table-striped table-condensed table-rounded table-bordered help-table\">\n                <thead>\n                    <tr>\n                        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":45}}}))
    + "</th>\n                        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You get",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":44}}}))
    + "</th>\n                    </tr>\n                </thead>\n\n                <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"markdown_help_rows") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":23,"column":29}}})) != null ? stack1 : "")
    + "                </tbody>\n            </table>\n        </div>\n        <hr />\n        <a href=\"/help/format-your-message-using-markdown\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Detailed message formatting documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":101},"end":{"line":28,"column":151}}}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});