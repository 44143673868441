var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":29}}}) : helper)))
    + "\" data-user-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_name") || (depth0 != null ? lookupProperty(depth0,"user_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":47},"end":{"line":2,"column":60}}}) : helper)))
    + "\" data-date-muted=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"date_muted_str") || (depth0 != null ? lookupProperty(depth0,"date_muted_str") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date_muted_str","hash":{},"data":data,"loc":{"start":{"line":2,"column":79},"end":{"line":2,"column":97}}}) : helper)))
    + "\">\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_name") || (depth0 != null ? lookupProperty(depth0,"user_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":21}}}) : helper)))
    + "</td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"date_muted_str") || (depth0 != null ? lookupProperty(depth0,"date_muted_str") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date_muted_str","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":26}}}) : helper)))
    + "</td>\n    <td class=\"actions\">\n        <span><a class=\"settings-unmute-user\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":61}}}))
    + "</a></span>\n    </td>\n</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"muted_user") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"useData":true});