var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    Join {realm_name}\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        This is not a <z-link>publicly accessible</z-link> conversation.\n                        \n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a target=\"_blank\" rel=\"noopener noreferrer\" href=\"/help/public-access-option\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        You can fully access this community and participate in conversations\n                        by creating a Zulip account in this organization.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"login_to_access_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"login_to_access_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"login_to_access_modal_label\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":8,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":57},"end":{"line":10,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"empty_narrow") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "                <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n            </main>\n            <footer class=\"modal__footer\">\n                <a class=\"modal__btn dialog_submit_button\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"signup_link") || (depth0 != null ? lookupProperty(depth0,"signup_link") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"signup_link","hash":{},"data":data,"loc":{"start":{"line":29,"column":65},"end":{"line":29,"column":80}}}) : helper)))
    + "\">\n                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sign up",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":42}}}))
    + "</span>\n                </a>\n                <a class=\"modal__btn dialog_submit_button\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"login_link") || (depth0 != null ? lookupProperty(depth0,"login_link") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"login_link","hash":{},"data":data,"loc":{"start":{"line":32,"column":65},"end":{"line":32,"column":79}}}) : helper)))
    + "\">\n                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":41}}}))
    + "</span>\n                </a>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"4_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":159}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});