var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bot-edit-form\" data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\" data-email=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\">\n    <form class=\"new-style edit_bot_form form-horizontal name-setting\">\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit_bot_full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Full name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":62}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"full_name\" id=\"edit_bot_full_name\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"full_name") : depth0), depth0))
    + "\" />\n        </div>\n        <input type=\"hidden\" name=\"is_full_name\" value=\"true\" />\n        <div class=\"input-group email_change_container\">\n            <label for=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":45}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"email\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group user_id_container\">\n            <label for=\"user_id\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":33},"end":{"line":13,"column":49}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"user_id\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"bot-role-select\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":61},"end":{"line":17,"column":74}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"bot-role-select\" id=\"bot-role-select\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disable_role_dropdown") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":98},"end":{"line":20,"column":142}}})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"user_role_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group edit_bot_owner_container\">\n            <label for=\"bot_owner_select\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":56}}}))
    + "</label>\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_list_widget.hbs"),depth0,{"name":"dropdown_list_widget","hash":{"list_placeholder":lookupProperty(helpers,"t").call(alias3,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":31},"end":{"line":28,"column":49}}}),"widget_name":"edit_bot_owner"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div id=\"service_data\">\n        </div>\n        <div class=\"input-group edit-avatar-section\">\n            <label>Avatar</label>\n            <input type=\"file\" name=\"bot_avatar_file_input\" class=\"notvisible edit_bot_avatar_file_input\" value=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Upload profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":113},"end":{"line":34,"column":144}}}))
    + "\" />\n            <div class=\"edit_bot_avatar_file\"></div>\n            <button type=\"button\" class=\"button white rounded edit_bot_avatar_upload_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Choose avatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":93},"end":{"line":36,"column":115}}}))
    + "</button>\n            <button type=\"button\" class=\"button white rounded edit_bot_avatar_clear_button\" style=\"display: none;\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Clear profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":115},"end":{"line":37,"column":145}}}))
    + "</button>\n            <div><label for=\"edit_bot_avatar_file\" generated=\"true\" class=\"edit_bot_avatar_error text-error\"></label></div>\n        </div>\n    </form>\n    <div class=\"input-group new-style\">\n        <button class=\"button rounded btn-danger deactivate_bot_button\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Deactivate bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":43,"column":35}}}))
    + "\n        </button>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});