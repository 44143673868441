var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <label for=\"change_stream_name\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":28}}}))
    + "\n    </label>\n    <input type=\"text\" id=\"change_stream_name\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"stream_name") : depth0), depth0))
    + "\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_stream_name_length") : depth0), depth0))
    + "\" />\n</div>\n<div>\n    <label for=\"change_stream_description\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":28}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/change-the-stream-description"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </label>\n    <textarea id=\"change_stream_description\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_stream_description_length") : depth0), depth0))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"stream_description") : depth0), depth0))
    + "</textarea>\n</div>\n";
},"usePartial":true,"useData":true});