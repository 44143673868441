var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":36},"end":{"line":22,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-bot-list\" class=\"settings-section\" data-name=\"bot-list-admin\">\n    <div class=\"tip bot-settings-tip\"></div>\n    <div class=\"clear-float\"></div>\n    <div>\n        <button class=\"button rounded sea-green add-a-new-bot "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_create_new_bots") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":108}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":110},"end":{"line":5,"column":132}}}))
    + "</button>\n    </div>\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":24}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"bot-field-status\"></div>\n        <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":75}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":89},"end":{"line":10,"column":109}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-condensed table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":85},"end":{"line":16,"column":98}}}))
    + "</th>\n                <th data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":52}}}))
    + "</th>\n                <th class=\"user_role\" data-sort=\"role\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":68}}}))
    + "</th>\n                <th data-sort=\"bot_owner\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Owner",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":56}}}))
    + "</th>\n                <th data-sort=\"alphabetic\" data-sort-prop=\"bot_type\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bot type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":69},"end":{"line":20,"column":86}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_bots_table\" class=\"admin_bot_table required-text thick\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No bots match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":69}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_bots_loading_indicator\"></div>\n</div>\n";
},"useData":true});