var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"radio-input-parent\">\n            <label class=\"radio\">\n                <input type=\"radio\" name=\"privacy\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"code") : depth0),(depths[1] != null ? lookupProperty(depths[1],"stream_privacy_policy") : depths[1]),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":9,"column":81},"end":{"line":9,"column":120}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":75},"end":{"line":9,"column":137}}})) != null ? stack1 : "")
    + " />\n                <b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ":</b> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n            </label>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"announce-new-stream\">\n        <label class=\"checkbox\">\n            <input type=\"checkbox\" name=\"announce\" value=\"announce\" checked />\n            <span></span>\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Announce stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":36}}}))
    + "\n        </label>\n        <span class=\"fa fa-info-circle settings-info-icon\" aria-hidden=\"true\" id=\"announce-stream-docs\"></span>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"code") : depth0),(depths[1] != null ? lookupProperty(depths[1],"stream_post_policy") : depths[1]),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":33,"column":48},"end":{"line":33,"column":84}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":42},"end":{"line":33,"column":102}}})) != null ? stack1 : "")
    + ">\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n            </option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "selected";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    "
    + ((stack1 = container.invokePartial(require("../settings/upgrade_tip_widget.hbs"),depth0,{"name":"../settings/upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"input-group inline-block message-retention-setting-group\">\n        <label class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":38},"end":{"line":45,"column":71}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/message-retention-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select name=\"stream_message_retention_setting\"\n          class=\"stream_message_retention_setting prop-element\"\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disable_message_retention_setting") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":50,"column":66}}})) != null ? stack1 : "")
    + ">\n            <option value=\"realm_default\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":42},"end":{"line":51,"column":125}}})) != null ? stack1 : "")
    + "</option>\n            <option value=\"unlimited\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain forever",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":38},"end":{"line":52,"column":61}}}))
    + "</option>\n            <option value=\"retain_for_period\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain for N days after posting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":46},"end":{"line":53,"column":86}}}))
    + "</option>\n        </select>\n\n        <div class=\"dependent-inline-block stream-message-retention-days-input\">\n            <label class=\"inline-block\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"N",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":26}}}))
    + ":\n            </label>\n            <input type=\"text\" autocomplete=\"off\"\n              name=\"stream-message-retention-days\"\n              class=\"stream-message-retention-days\"\n              value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"stream_message_retention_days") : depth0), depth0))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disable_message_retention_setting") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":14},"end":{"line":64,"column":70}}})) != null ? stack1 : "")
    + "/>\n        </div>\n    </div>\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"12":function(container,depth0,helpers,partials,data) {
    return "Use organization level settings {org_level_message_retention_setting}";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group stream-privacy-values\">\n    <div class=\"alert stream-privacy-status\"></div>\n    <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access the stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":45}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"stream_privacy_policy_values") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ask_to_announce_stream") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"input-group\">\n    <label class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can post to the stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":34},"end":{"line":28,"column":69}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/stream-sending-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </label>\n    <select name=\"stream-post-policy\" class=\"stream_post_policy_setting prop-element\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"stream_post_policy_values") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":36,"column":17}}})) != null ? stack1 : "")
    + "    </select>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),(depth0 != null ? lookupProperty(depth0,"is_stream_edit") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":40,"column":6},"end":{"line":40,"column":34}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":0},"end":{"line":68,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});