var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "font-size=\"9\" textLength=\"14\" lengthAdjust=\"spacingAndGlyphs\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "font-size=\"11\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <circle cx=\"14\" cy=\"4\" r=\"2\" fill=\"#f00\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 16 16\">\n    <style>\n        @font-face {\n        font-family: 'Source Sans 3';\n        font-style: normal;\n        font-weight: 700;\n        src: url("
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"favicon_font_url") || (depth0 != null ? lookupProperty(depth0,"favicon_font_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"favicon_font_url","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":39}}}) : helper))) != null ? stack1 : "")
    + ") format('truetype');\n        }\n    </style>\n    <linearGradient id=\"a\" x1=\"0\" y1=\"0\" x2=\"0\" y2=\"1\">\n        <stop offset=\"0\" stop-color=\"#50adff\" />\n        <stop offset=\"1\" stop-color=\"#7877fc\" />\n    </linearGradient>\n    <path d=\"M688.52 150.67c0 33.91-15.23 64.04-38.44 82.31L424.79 434.17c-4.18 3.59-9.62-2.19-6.61-7.03l82.64-165.46c2.31-4.63-.69-10.33-5.44-10.33H174.86c-49.64 0-90.26-45.31-90.26-100.68 0-55.37 40.62-100.68 90.26-100.68h423.39c49.65 0 90.27 45.31 90.27 100.68zM174.86 723.13h423.39c49.64 0 90.26-45.31 90.26-100.68 0-55.37-40.62-100.68-90.26-100.68H277.73c-4.75 0-7.76-5.7-5.44-10.33l82.64-165.46c3.01-4.83-2.42-10.62-6.61-7.03L123.04 540.14c-23.21 18.27-38.44 48.4-38.44 82.31 0 55.37 40.62 100.68 90.26 100.68z\" fill=\"url(#a)\" transform=\"translate(8 8) scale(0.023769201057729446) translate(-386.56 -386.56)\" />\n    <text x=\"15\" y=\"15\" text-anchor=\"end\"\n      font-family=\"'Source Sans 3'\" font-weight=\"bold\" letter-spacing=\"-0.5\"\n      fill=\"white\" stroke=\"white\" stroke-width=\"2\" stroke-linejoin=\"round\" opacity=\".5\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"count_long") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":18,"column":114}}})) != null ? stack1 : "")
    + ">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":17}}}) : helper)))
    + "\n    </text>\n    <text x=\"15\" y=\"15\" text-anchor=\"end\"\n      font-family=\"'Source Sans 3'\" font-weight=\"bold\" letter-spacing=\"-0.5\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"count_long") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":23,"column":114}}})) != null ? stack1 : "")
    + ">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"count") || (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":17}}}) : helper)))
    + "\n    </text>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"have_pm") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "</svg>\n";
},"useData":true});