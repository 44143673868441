var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "zero-topic-unreads";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"unread_mention_info\">\n                @\n            </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"topic-list-item show-more-topics bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"more_topics_unreads") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":120}}})) != null ? stack1 : "")
    + "\">\n    <span class='topic-box'>\n        <a class=\"topic-name\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"more topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":63}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"more_topics_have_unread_mention_messages") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"more_topics_unreads") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":86}}})) != null ? stack1 : "")
    + "\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"more_topics_unreads") || (depth0 != null ? lookupProperty(depth0,"more_topics_unreads") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"more_topics_unreads","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":35}}}) : helper)))
    + "\n        </span>\n    </span>\n</li>\n";
},"useData":true});