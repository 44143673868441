var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fa fa-pencil edit_content_button edit_message_button\" role=\"button\" tabindex=\"0\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Edit message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":103},"end":{"line":2,"column":124}}}))
    + " (e)\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":150},"end":{"line":2,"column":176}}})) != null ? stack1 : "")
    + " (e)\"></i>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Edit message";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"can_move_message") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":7,"column":0}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fa fa-arrows move_message_button edit_message_button\" role=\"button\" tabindex=\"0\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Move message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":124}}}))
    + " (m)\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":150},"end":{"line":4,"column":176}}})) != null ? stack1 : "")
    + " (m)\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Move message";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fa fa-file-code-o view_source_button edit_message_button\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View message source",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":107},"end":{"line":6,"column":135}}}))
    + " (e)\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":161},"end":{"line":6,"column":194}}})) != null ? stack1 : "")
    + " (e)\" data-message-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"msg_id") || (depth0 != null ? lookupProperty(depth0,"msg_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"msg_id","hash":{},"data":data,"loc":{"start":{"line":6,"column":217},"end":{"line":6,"column":227}}}) : helper)))
    + "\"></i>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "View message source";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_content_editable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "");
},"useData":true});