var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":(depth0 != null ? lookupProperty(depth0,"help_link_widget_link") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"language_selection_widget input-group prop-element\" id=\"id_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_name") : depth0), depth0))
    + "\">\n    <label class=\"title\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_title") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"help_link_widget_link") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n    <button type=\"button\" class=\"language_selection_button button rounded small\" data-section=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_name") : depth0), depth0))
    + "\">\n        <span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"section_name") : depth0), depth0))
    + "\" data-language-code=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"language_code") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"setting_value") : depth0), depth0))
    + "</span>\n        <i class=\"fa fa-pencil\"></i>\n    </button>\n</div>\n";
},"usePartial":true,"useData":true});