var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list remind_me_popover\">\n    <li>\n        <a class=\"remind in_20m\" data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"in 20 minutes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":34}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a class=\"remind in_1h\" data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"in 1 hour",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":30}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a class=\"remind in_3h\" data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"in 3 hours",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":31}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a class=\"remind tomo\" data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Tomorrow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":29}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a class=\"remind nxtw\" data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Next week",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":30}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a class='remind custom' data-message-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Select date and time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":35,"column":41}}}))
    + "\n        </a>\n    </li>\n\n</ul>\n";
},"useData":true});