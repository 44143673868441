var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " deactivated_user";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"my_user_status\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(you)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":99},"end":{"line":3,"column":113}}}))
    + "</span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"email\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"display_email") || (depth0 != null ? lookupProperty(depth0,"display_email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"display_email","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":45}}}) : helper)))
    + "</span>\n    </td>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"hidden-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":35},"end":{"line":12,"column":51}}}))
    + "</span>\n    </td>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"user_id\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":17,"column":30},"end":{"line":17,"column":41}}}) : helper)))
    + "</span>\n    </td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"user_role\">\n        <span class=\"owner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"no_owner") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n    <td>\n        <span class=\"bot type\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bot_type") || (depth0 != null ? lookupProperty(depth0,"bot_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"bot_type","hash":{},"data":data,"loc":{"start":{"line":34,"column":31},"end":{"line":34,"column":43}}}) : helper)))
    + "</span>\n    </td>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bot_owner_full_name") || (depth0 != null ? lookupProperty(depth0,"bot_owner_full_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bot_owner_full_name","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":35}}}) : helper)))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"bot_owner_id") || (depth0 != null ? lookupProperty(depth0,"bot_owner_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bot_owner_id","hash":{},"data":data,"loc":{"start":{"line":29,"column":29},"end":{"line":29,"column":45}}}) : helper)))
    + "\" class=\"view_user_profile\" tabindex=\"0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"bot_owner_full_name") || (depth0 != null ? lookupProperty(depth0,"bot_owner_full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bot_owner_full_name","hash":{},"data":data,"loc":{"start":{"line":29,"column":86},"end":{"line":29,"column":109}}}) : helper)))
    + "</a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":40,"column":4}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"last_active\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"last_active_date") || (depth0 != null ? lookupProperty(depth0,"last_active_date") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"last_active_date","hash":{},"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":38,"column":30}}}) : helper)))
    + "\n    </td>\n    ";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <span class=\"user-status-settings\">\n            <button class=\"button rounded small btn-warning open-user-form\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":76},"end":{"line":44,"column":130}}})) != null ? stack1 : "")
    + " title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":44,"column":138},"end":{"line":44,"column":202}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":44,"column":218},"end":{"line":44,"column":229}}}) : helper)))
    + "\">\n                <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n            </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":55,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":152},"end":{"line":44,"column":169}}}));
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":177},"end":{"line":44,"column":195}}}));
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Deactivate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":27},"end":{"line":48,"column":46}}}))
    + "\" class=\"button rounded small deactivate btn-danger\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_deactivate") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":99},"end":{"line":48,"column":150}}})) != null ? stack1 : "")
    + ">\n                <i class=\"fa fa-user-times\" aria-hidden=\"true\"></i>\n            </button>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":27},"end":{"line":52,"column":46}}}))
    + "\" class=\"button rounded small reactivate btn-warning\">\n                <i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"user_row"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":68}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":84},"end":{"line":1,"column":95}}}) : helper)))
    + "\">\n    <td>\n        <span class=\"user_name\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"full_name") || (depth0 != null ? lookupProperty(depth0,"full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":46}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_current_user") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":47},"end":{"line":3,"column":127}}})) != null ? stack1 : "")
    + "</span>\n        <i class=\"fa fa-ban deactivated-user-icon\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"User is deactivated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":86}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":88},"end":{"line":4,"column":134}}})) != null ? stack1 : "")
    + "></i>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"display_email") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"user_role\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_role_text") || (depth0 != null ? lookupProperty(depth0,"user_role_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_role_text","hash":{},"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":50}}}) : helper)))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_modify") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":58,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"useData":true});