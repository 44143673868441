var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"add-alert-word-form\" class=\"form-horizontal\">\n    <label for=\"add-alert-word-name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":56}}}))
    + "</label>\n    <input type=\"text\" name=\"alert-word-name\" id=\"add-alert-word-name\" class=\"required\" maxlength=100 placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":115},"end":{"line":3,"column":134}}}))
    + "\" value=\"\" />\n</form>\n";
},"useData":true});