var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"modal__btn dialog_cancel_button\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":76},"end":{"line":19,"column":109}}}))
    + "\" data-micromodal-close>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":133},"end":{"line":19,"column":148}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":104},"end":{"line":21,"column":137}}}))
    + "\" data-micromodal-close";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"dialog_widget_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":42}}})) != null ? stack1 : "")
    + "class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"dialog_title\" data-simplebar>\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title dialog_heading\">\n                    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"heading_text") : depth0), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"link") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n                <div class=\"alert\" id=\"dialog_error\"></div>\n                "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"html_body") : depth0), depth0)) != null ? stack1 : "")
    + "\n            </main>\n            <footer class=\"modal__footer\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"single_footer_button") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + "                <button class=\"modal__btn dialog_submit_button\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"single_footer_button") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":63},"end":{"line":21,"column":167}}})) != null ? stack1 : "")
    + ">\n                    <span>"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"html_submit_button") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n                    <div class=\"modal__spinner\"></div>\n                </button>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});