var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"button no-style user-status-value\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"emoji") : depth0)) != null ? lookupProperty(stack1,"emoji_alt_code") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"status_text") : depth0), depth0))
    + "\n        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"emoji_alt_code\">&nbsp:"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"emoji") : depth0)) != null ? lookupProperty(stack1,"emoji_name") : stack1), depth0))
    + ":</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"emoji") : depth0)) != null ? lookupProperty(stack1,"url") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":12}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"emoji") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"emoji status_emoji\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"emoji status_emoji emoji-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"emoji") : depth0)) != null ? lookupProperty(stack1,"emoji_code") : stack1), depth0))
    + "\"></div>\n            ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"new-style user_status_content_wrapper\">\n    <div class=\"status_emoji_wrapper tippy-zulip-tooltip\"  data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":79},"end":{"line":2,"column":100}}}))
    + "\" data-tippy-placement=\"top\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":141},"end":{"line":2,"column":162}}}))
    + "\"  tabindex=\"0\" id=\"selected_emoji\">\n        "
    + ((stack1 = container.invokePartial(require("./status_emoji_selector.hbs"),depth0,{"name":"status_emoji_selector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <input type=\"text\" class=\"user_status\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":76}}}))
    + "\" maxlength=\"60\"/>\n    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_status_message_button\" disabled=\"disabled\">\n        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n<div class=\"new-style user-status-options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"default_status_messages_and_emoji_info") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});