var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"change_password_container\">\n    <div class=\"field password-div\">\n        <label for=\"old_password\" class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Old password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":69}}}))
    + "</label>\n        <input type=\"password\" autocomplete=\"off\" name=\"old_password\" id=\"old_password\" class=\"w-200 inline-block\" value=\"\" />\n        <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n        <div class=\"settings-forgot-password\">\n            <a href=\"/accounts/password/reset/\" class=\"sea-green\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Forgotten it?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":108},"end":{"line":7,"column":130}}}))
    + "</a>\n        </div>\n    </div>\n    <div class=\"field password-div\">\n        <label for=\"new_password\" class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":69}}}))
    + "</label>\n        <input type=\"password\" autocomplete=\"new-password\" name=\"new_password\" id=\"new_password\" class=\"w-200 inline-block\" value=\"\"\n          data-min-length=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"password_min_length") : stack1), depth0))
    + "\" data-min-guesses=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"password_min_guesses") : stack1), depth0))
    + "\" />\n        <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n        <div class=\"progress inline-block\" id=\"pw_strength\">\n            <div class=\"bar bar-danger fade\" style=\"width: 10%;\"></div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});