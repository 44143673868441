var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":34}}}) : helper)))
    + "_label\" class=\"dropdown-title\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":23}}}) : helper)))
    + "\n        </label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"button rounded small sea-green multiselect_btn\" tabindex=\"0\">\n                    <i class=\"fa fa-filter\" aria-hidden=\"true\"></i>\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"filter_button_text") || (depth0 != null ? lookupProperty(depth0,"filter_button_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filter_button_text","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":42}}}) : helper)))
    + "\n                </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a class=\"dropdown_list_reset_button\" role=\"button\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reset_button_text") || (depth0 != null ? lookupProperty(depth0,"reset_button_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reset_button_text","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":29}}}) : helper)))
    + "\n    </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group dropdown-list-widget\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":65}}}) : helper)))
    + "_widget\">\n    <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":32}}}) : helper)))
    + "_setting dropup actual-dropdown-menu prop-element\" id=\"id_"
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":90},"end":{"line":2,"column":105}}}) : helper)))
    + "\" aria-labelledby=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":124},"end":{"line":2,"column":139}}}) : helper)))
    + "_label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "        <button class=\"button rounded dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">\n            <span id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget_name") || (depth0 != null ? lookupProperty(depth0,"widget_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":37}}}) : helper)))
    + "_name\"></span>\n            <i class=\"fa fa-chevron-down\"></i>\n        </button>\n        <ul class=\"dropdown-menu modal-bg\" role=\"menu\">\n            <li class=\"dropdown-search\" role=\"presentation\">\n                <input class=\"no-input-change-detection\" type=\"text\" role=\"menuitem\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"list_placeholder") || (depth0 != null ? lookupProperty(depth0,"list_placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"list_placeholder","hash":{},"data":data,"loc":{"start":{"line":14,"column":98},"end":{"line":14,"column":118}}}) : helper)))
    + "\" autofocus/>\n            </li>\n            <div class=\"dropdown-list-wrapper\" data-simplebar>\n                <span class=\"dropdown-list-body\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"filter_button_text") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </ul>\n    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reset_button_text") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});