var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " out_of_home_view";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<li class=\"narrow-filter"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":64}}})) != null ? stack1 : "")
    + "\" data-stream-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n    <div class=\"bottom_left_row\">\n        <div class=\"subscription_block selectable_sidebar_block\">\n\n            <span id=\"stream_sidebar_privacy_swatch_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"stream-privacy filter-icon\" style=\"color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n\n            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uri") : depth0), depth0))
    + "\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" class=\"stream-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n\n            <span class=\"unread_mention_info\"></span>\n            <span class=\"unread_count\"></span>\n        </div>\n        <span class=\"stream-sidebar-menu-icon hidden-for-spectators\"><i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i></span>\n    </div>\n</li>\n";
},"usePartial":true,"useData":true});