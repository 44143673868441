var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"edit-linkifier-form\">\n    <form class=\"form-horizontal linkifier-edit-form\">\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit-linkifier-pattern\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":65}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" id=\"edit-linkifier-pattern\" name=\"pattern\" placeholder=\"#(?P<id>[0-9]+)\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"pattern") || (depth0 != null ? lookupProperty(depth0,"pattern") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"pattern","hash":{},"data":data,"loc":{"start":{"line":5,"column":130},"end":{"line":5,"column":143}}}) : helper)))
    + "\" />\n            <div class=\"alert\" id=\"edit-linkifier-pattern-status\"></div>\n        </div>\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit-linkifier-url-format-string\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL format string",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":59},"end":{"line":9,"column":85}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" id=\"edit-linkifier-url-format-string\" name=\"url_format_string\" placeholder=\"https://github.com/zulip/zulip/issues/%(id)s\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"url_format_string") || (depth0 != null ? lookupProperty(depth0,"url_format_string") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"url_format_string","hash":{},"data":data,"loc":{"start":{"line":10,"column":179},"end":{"line":10,"column":202}}}) : helper)))
    + "\" />\n            <div class=\"alert\" id=\"edit-linkifier-format-status\"></div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});