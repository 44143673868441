var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./message_reaction.hbs"),depth0,{"name":"message_reaction","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"message_reactions") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + "<div class=\"reaction_button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":76}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":90},"end":{"line":4,"column":117}}}))
    + " (:)\">\n    <i class=\"fa fa-smile-o\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":89},"end":{"line":5,"column":116}}}))
    + " (:)\"></i>\n    <div class=\"message_reaction_count\">+</div>\n</div>\n";
},"usePartial":true,"useData":true});