var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"zulip-icon zulip-icon-bot\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":69}}}))
    + "\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"message_sender no-select\">\n    <span class=\"sender_info_hover\">\n        <span title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":44}}}))
    + " (u)\">\n            "
    + ((stack1 = container.invokePartial(require("./message_avatar.hbs"),depth0,{"name":"message_avatar","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n    </span>\n\n    <span class=\"sender-status\">\n        <span class=\"sender_info_hover sender_name-in-status auto-select\" role=\"button\" tabindex=\"0\">\n            <span title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":25},"end":{"line":10,"column":48}}}))
    + " (u)\" >\n                "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"sender_full_name") : stack1), depth0))
    + "\n            </span>\n        </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sender_is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\n        <span class=\"rendered_markdown status-message auto-select\">"
    + alias2(lookupProperty(helpers,"rendered_markdown").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_message") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":18,"column":67},"end":{"line":18,"column":103}}}))
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edited_status_msg") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n</span>\n";
},"usePartial":true,"useData":true});