var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p for=\"two_factor_auth\" class=\"inline-block title\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":54}}}))
    + ": "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"two_fa_enabled_user") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":22,"column":56},"end":{"line":22,"column":144}}})) != null ? stack1 : "")
    + "\n                    <a target=\"_blank\" rel=\"noopener noreferrer\" id=\"two_factor_auth\" href=\"/account/two_factor/\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Set up two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":121},"end":{"line":23,"column":162}}}))
    + "\">["
    + alias2(lookupProperty(helpers,"t").call(alias1,"Setup",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":165},"end":{"line":23,"column":179}}}))
    + "]</a>\n                </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":96},"end":{"line":22,"column":112}}}));
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":120},"end":{"line":22,"column":137}}}));
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n                        <label class=\"inline-block title\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":58},"end":{"line":30,"column":75}}}))
    + "</label>\n                        <div class=\"input-group inline-block\" id=\"pw_change_link\">\n                            <button id=\"change_password\" type=\"button\" class=\"change_password_button btn-link small button rounded inline-block\" data-dismiss=\"modal\">********<i class=\"fa fa-pencil\"></i></button>\n                        </div>\n                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"send_stream_typing_notifications") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"send_stream_typing_notifications") : stack1),"setting_name":"send_stream_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                    An API key can be used to programmatically access a Zulip account.\n                    Anyone with access to your API key has the ability to read your messages, send\n                    messages on your behalf, and otherwise impersonate you on Zulip, so you should\n                    guard your API key as carefully as you guard your password. <br />\n                    We recommend creating bots and using the bots' accounts and API keys to access\n                    the Zulip API, unless the task requires access to your account.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"account-settings\" class=\"settings-section show\" data-name=\"account-and-privacy\">\n    <div class=\"alert\" id=\"dev-account-settings-status\"></div>\n    <div class=\"account-settings-form\">\n        <div class=\"inline-block\">\n            <div id=\"user_details_section\">\n                <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":57}}}))
    + "</h3>\n                <div class=\"alert-notification\" id=\"account-settings-status\"></div>\n                <form class=\"email-change-form grid\">\n                    <div class=\"input-group\">\n                        <label class=\"inline-block title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":72}}}))
    + "</label>\n                        <button id='change_email' type=\"button\" class=\"button btn-link small rounded inline-block\"\n                          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"realm_email_changes_disabled") : stack1),lookupProperty(helpers,"not").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"is_admin") : stack1),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":12,"column":78},"end":{"line":12,"column":104}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":105}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":133}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"delivery_email") : stack1), depth0))
    + "\n                            <i class=\"fa fa-pencil\"></i>\n                        </button>\n                        <i class=\"tippy-zulip-tooltip fa fa-question-circle change_email_tooltip settings-info-icon\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"not").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"realm_email_changes_disabled") : stack1),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":16,"column":127},"end":{"line":16,"column":173}}}),((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"is_admin") : stack1),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":16,"column":123},"end":{"line":16,"column":195}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":117},"end":{"line":16,"column":226}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email address changes are disabled in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":247},"end":{"line":16,"column":312}}}))
    + "\"></i>\n                    </div>\n                </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"two_fa_enabled") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "\n                <form class=\"password-change-form grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"realm_email_auth_enabled") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "                </form>\n\n                <div class=\"input-group\">\n                    <button type=\"submit\" class=\"button rounded btn-danger\" id=\"user_deactivate_account_button\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Deactivate account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":51}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-horizontal\" id=\"privacy_settings_box\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":37},"end":{"line":47,"column":53}}}))
    + "</h3>\n            <div class=\"alert-notification privacy-setting-status\"></div>\n            <div class=\"input-group\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"send_private_typing_notifications") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"send_private_typing_notifications") : stack1),"setting_name":"send_private_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"development_environment") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":61,"column":23}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/read-receipts","hide_tooltip":((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"realm_enable_read_receipts") : stack1),"tooltip_text":(depth0 != null ? lookupProperty(depth0,"send_read_receipts_tooltip") : depth0),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"send_read_receipts") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"send_read_receipts") : stack1),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"user_","help_link":"/help/status-and-availability","label_parens_text":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"presence_enabled_parens_text") : stack1),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"presence_enabled") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"presence_enabled") : stack1),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <hr class=\"settings_separator\" />\n\n        <div class=\"form-horizontal\" id=\"api_key_button_box\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":83,"column":32}}}))
    + "</h3>\n\n            <div class=\"input-group\">\n                <p class=\"api-key-note\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":94,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n                <button class=\"button rounded\" id=\"api_key_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show/change your API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":67},"end":{"line":96,"column":100}}}))
    + "</button>\n            </div>\n        </div>\n        <!-- Render /settings/api_key_modal.hbs after #api_key_button is clicked\n        to avoid password being inserted by password manager too aggressively. -->\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});