var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_digest_emails_enabled") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_digest_emails_enabled") : depth0),"prefix":"id_","setting_name":"realm_digest_emails_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/allow-image-link-previews","label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_inline_image_preview") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_inline_image_preview") : depth0),"prefix":"id_","setting_name":"realm_inline_image_preview"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/allow-image-link-previews","label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_inline_url_embed_preview") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_inline_url_embed_preview") : depth0),"prefix":"id_","setting_name":"realm_inline_url_embed_preview"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-settings\" data-name=\"organization-settings\" class=\"settings-section\">\n    <form class=\"form-horizontal admin-realm-form org-settings-form\">\n\n        <div id=\"org-notifications\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automated messages and emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":58}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_send_welcome_emails") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_send_welcome_emails") : depth0),"prefix":"id_","setting_name":"realm_send_welcome_emails"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"settings_send_digest_emails") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"input-group\">\n                    <label for=\"realm_digest_weekday\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Day of the week to send digests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":77},"end":{"line":23,"column":117}}}))
    + "</label>\n                    <select name=\"realm_digest_weekday\"\n                      id=\"id_realm_digest_weekday\"\n                      class=\"setting-widget prop-element\"\n                      data-setting-widget-type=\"number\">\n                        <option value=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Monday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":42},"end":{"line":28,"column":57}}}))
    + "</option>\n                        <option value=\"1\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Tuesday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":58}}}))
    + "</option>\n                        <option value=\"2\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Wednesday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":42},"end":{"line":30,"column":60}}}))
    + "</option>\n                        <option value=\"3\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Thursday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":42},"end":{"line":31,"column":59}}}))
    + "</option>\n                        <option value=\"4\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Friday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":42},"end":{"line":32,"column":57}}}))
    + "</option>\n                        <option value=\"5\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Saturday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":42},"end":{"line":33,"column":59}}}))
    + "</option>\n                        <option value=\"6\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sunday",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":42},"end":{"line":34,"column":57}}}))
    + "</option>\n                    </select>\n                </div>\n            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_list_widget.hbs"),depth0,{"name":"dropdown_list_widget","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_notifications_stream") : stack1),"reset_button_text":lookupProperty(helpers,"t").call(alias1,"[Disable]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":32},"end":{"line":42,"column":47}}}),"list_placeholder":lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":31},"end":{"line":41,"column":51}}}),"widget_name":"realm_notifications_stream_id"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = container.invokePartial(require("./dropdown_list_widget.hbs"),depth0,{"name":"dropdown_list_widget","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_signup_notifications_stream") : stack1),"reset_button_text":lookupProperty(helpers,"t").call(alias1,"[Disable]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":32},"end":{"line":48,"column":47}}}),"list_placeholder":lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":31},"end":{"line":47,"column":51}}}),"widget_name":"realm_signup_notifications_stream_id"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"realm_default_language\">\n                "
    + ((stack1 = container.invokePartial(require("./language_selection_widget.hbs"),depth0,{"name":"language_selection_widget","hash":{"help_link_widget_link":"/help/configure-organization-language","section_title":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_default_language") : stack1),"language_code":(depth0 != null ? lookupProperty(depth0,"realm_default_language_code") : depth0),"setting_value":(depth0 != null ? lookupProperty(depth0,"realm_default_language_name") : depth0),"section_name":"realm_default_language"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div id=\"org-message-retention\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":46}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/message-retention-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"message-retention"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"inline-block organization-settings-parent\">\n                <div class=\"input-group\">\n                    <label for=\"id_realm_message_retention_days\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":88},"end":{"line":72,"column":121}}}))
    + "\n                    </label>\n                    <select name=\"realm_message_retention_days\"\n                      id=\"id_realm_message_retention_days\" class=\"prop-element\"\n                      data-setting-widget-type=\"message-retention-setting\"\n                      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"zulip_plan_is_not_limited") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":22},"end":{"line":77,"column":78}}})) != null ? stack1 : "")
    + ">\n                        <option value=\"retain_forever\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain forever",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":55},"end":{"line":78,"column":78}}}))
    + "</option>\n                        <option value=\"retain_for_period\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain for N days after posting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":58},"end":{"line":79,"column":98}}}))
    + "</option>\n                    </select>\n\n                    <div class=\"dependent-inline-block\">\n                        <label for=\"id_realm_message_retention_custom_input\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"N",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":28},"end":{"line":84,"column":38}}}))
    + ":\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_retention_custom_input\" autocomplete=\"off\"\n                          name=\"realm_message_retention_custom_input\"\n                          class=\"admin-realm-message-retention-days message-retention-setting-custom-input\"\n                          data-setting-widget-type=\"number\"\n                          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"zulip_plan_is_not_limited") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":26},"end":{"line":90,"column":82}}})) != null ? stack1 : "")
    + "/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-other-settings\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":20},"end":{"line":98,"column":43}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"other-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                <div class=\"input-group\">\n                    <label for=\"realm_video_chat_provider\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Video call provider",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":24},"end":{"line":104,"column":52}}}))
    + "\n                    </label>\n                    <select name=\"realm_video_chat_provider\" class =\"setting-widget prop-element\" id=\"id_realm_video_chat_provider\" data-setting-widget-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_available_video_chat_providers") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":24},"end":{"line":109,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_giphy_rating\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"GIPHY integration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":24},"end":{"line":114,"column":50}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":(depth0 != null ? lookupProperty(depth0,"giphy_help_link") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_giphy_rating\" class =\"setting-widget prop-element\" id=\"id_realm_giphy_rating\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"giphy_api_key_empty") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":152},"end":{"line":117,"column":194}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"giphy_rating_options") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":24},"end":{"line":120,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_list_widget.hbs"),depth0,{"name":"dropdown_list_widget","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_default_code_block_language") : stack1),"reset_button_text":lookupProperty(helpers,"t").call(alias1,"[Unset]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":36},"end":{"line":128,"column":49}}}),"list_placeholder":lookupProperty(helpers,"t").call(alias1,"Filter languages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":35},"end":{"line":127,"column":57}}}),"widget_name":"realm_default_code_block_language"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_message_content_allowed_in_email_notifications") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_message_content_allowed_in_email_notifications") : depth0),"prefix":"id_","setting_name":"realm_message_content_allowed_in_email_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_mandatory_topics") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_mandatory_topics") : depth0),"prefix":"id_","setting_name":"realm_mandatory_topics"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label_parens_text":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_enable_read_receipts_parens_text") : stack1),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_enable_read_receipts") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_enable_read_receipts") : depth0),"prefix":"id_","setting_name":"realm_enable_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"server_inline_image_preview") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":16},"end":{"line":157,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"server_inline_url_embed_preview") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":16},"end":{"line":166,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});