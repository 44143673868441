var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":27,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"member_list_settings_container\">\n    <h4 class=\"user_group_setting_subsection_title\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":28}}}))
    + "\n    </h4>\n    <div class=\"member_list_settings\">\n        <div class=\"member_list_add float-left\">\n            "
    + ((stack1 = container.invokePartial(require("../stream_settings/add_subscribers_form.hbs"),depth0,{"name":"../stream_settings/add_subscribers_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"user_group_subscription_request_result\"></div>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div>\n        <h4 class=\"inline-block user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":69},"end":{"line":13,"column":84}}}))
    + "</h4>\n        <span class=\"member-search float-right\">\n            <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":59},"end":{"line":15,"column":86}}}))
    + "\" />\n        </span>\n    </div>\n    <div class=\"member-list-box\">\n        <div class=\"member_list_container\" data-simplebar>\n            <div class=\"member_list_loading_indicator\"></div>\n            <table class=\"member-list table table-striped\">\n                <thead class=\"table-sticky-headers\">\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":37}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":38}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":40}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":28,"column":27}}})) != null ? stack1 : "")
    + "                </thead>\n                <tbody class=\"member_table\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});