var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <label class=\"checkbox display-block\">\n            <input type=\"checkbox\" name=\"stream\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":69}}}) : helper)))
    + "\"\n              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default_stream") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":60}}})) != null ? stack1 : "")
    + " />\n            <span></span>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invite_only") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":83}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),(depths[1] != null ? lookupProperty(depths[1],"notifications_stream") : depths[1]),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":51}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-lock\" aria-hidden=\"true\"></i>";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            #"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":13},"end":{"line":15,"column":21}}}) : helper)))
    + " <i>("
    + alias2(lookupProperty(helpers,"t").call(alias1,"Receives new stream announcements",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":68}}}))
    + ")</i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            #"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":17,"column":13},"end":{"line":17,"column":21}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"invite-stream-controls\">\n    <button class=\"btn btn-link\" type=\"button\" id=\"invite_check_all_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Check all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":76},"end":{"line":3,"column":94}}}))
    + "</button> |\n    <button class=\"btn btn-link\" type=\"button\" id=\"invite_uncheck_all_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Uncheck all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":78},"end":{"line":4,"column":98}}}))
    + "</button>\n</div>\n<div id=\"invite-stream-checkboxes\" class=\"new-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"streams") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});