var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"narrow_to_compose_recipient_current_view_help\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"display_current_view") || (depth0 != null ? lookupProperty(depth0,"display_current_view") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"display_current_view","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":81}}}) : helper)))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Go to conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":6},"end":{"line":1,"column":33}}}))
    + "</span> <span class=\"hotkey-hint\">("
    + alias2(((helper = (helper = lookupProperty(helpers,"shortcut_html") || (depth0 != null ? lookupProperty(depth0,"shortcut_html") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"shortcut_html","hash":{},"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":85}}}) : helper)))
    + ")</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"display_current_view") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "");
},"useData":true});