var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":67}}})) != null ? stack1 : "")
    + " to:</p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Move all messages in <strong>{topic_name}</strong>";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"message_edit_topic_propagate\">\n            <option value=\"change_one\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move only this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":71}}}))
    + "</option>\n            <option selected=\"selected\" value=\"change_later\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move this and all following messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":121}}}))
    + "</option>\n            <option value=\"change_all\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move all messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":80}}}))
    + "</option>\n        </select>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"from_message_actions_popover") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "")
    + "<form class=\"new-style\" id=\"move_topic_form\">\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select a stream below or change topic name.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":5,"column":59}}}))
    + "</p>\n    <div class=\"topic_stream_edit_header\">\n        <div class=\"stream_header_colorblock\"></div>\n        <div class=\"move-topic-dropdown\">\n            "
    + ((stack1 = container.invokePartial(require("./settings/dropdown_list_widget.hbs"),depth0,{"name":"settings/dropdown_list_widget","hash":{"list_placeholder":lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":51}}}),"widget_name":"select_stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>\n        <input name=\"new_topic_name\" type=\"text\" class=\"inline_topic_edit\" autocomplete=\"off\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"topic_name") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disable_topic_input") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":117},"end":{"line":14,"column":159}}})) != null ? stack1 : "")
    + " />\n        <input name=\"old_topic_name\" type=\"hidden\" class=\"inline_topic_edit\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"topic_name") : depth0), depth0))
    + "\" />\n        <input name=\"current_stream_id\" type=\"hidden\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"current_stream_id") : depth0), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"from_message_actions_popover") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"topic_move_breadcrumb_messages\">\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_new_thread\" name=\"send_notification_to_new_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notify_new_thread") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":118},"end":{"line":26,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span></span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send automated notice to new topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":59}}}))
    + "\n            </label>\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_old_thread\" name=\"send_notification_to_old_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notify_old_thread") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":118},"end":{"line":31,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span></span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send automated notice to old topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":59}}}))
    + "\n            </label>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});