var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <a href=\"/#settings/profile\">\n                        <i class=\"fa fa-edit\" id=\"edit-button\" aria-hidden=\"true\"></i>\n                    </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div id=\"email\" class=\"default-field\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":58},"end":{"line":30,"column":77}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":31,"column":59},"end":{"line":31,"column":68}}}) : helper)))
    + "</div>\n                                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Email";
},"8":function(container,depth0,helpers,partials,data) {
    return "User ID";
},"10":function(container,depth0,helpers,partials,data) {
    return "Role";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_system_bot") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":41,"column":44},"end":{"line":45,"column":51}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"value\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":63},"end":{"line":42,"column":87}}})) != null ? stack1 : "")
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "System bot";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"value\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":63},"end":{"line":44,"column":80}}})) != null ? stack1 : "")
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "Bot";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <div class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_type") || (depth0 != null ? lookupProperty(depth0,"user_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_type","hash":{},"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":76}}}) : helper)))
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "Joined";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"default-field\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":58},"end":{"line":56,"column":82}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_time") || (depth0 != null ? lookupProperty(depth0,"user_time") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_time","hash":{},"data":data,"loc":{"start":{"line":57,"column":59},"end":{"line":57,"column":72}}}) : helper)))
    + "</div>\n                                    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "Local time";
},"26":function(container,depth0,helpers,partials,data) {
    return " class=\"guest-avatar\" ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"field-section\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":58},"end":{"line":72,"column":80}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"bot_info_value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bot_type") || (depth0 != null ? lookupProperty(depth0,"bot_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"bot_type","hash":{},"data":data,"loc":{"start":{"line":73,"column":68},"end":{"line":73,"column":80}}}) : helper)))
    + "</div>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":36},"end":{"line":82,"column":43}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    return "Bot type";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"field-section bot_owner_user_field\" data-field-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0)) != null ? lookupProperty(stack1,"user_id") : stack1), depth0))
    + "\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":58},"end":{"line":77,"column":77}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"pill-container not-editable\">\n                                            <div class=\"input\" contenteditable=\"false\" style=\"display: none;\"></div>\n                                        </div>\n                                    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "Owner";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"profile_data") : depth0),{"name":"each","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":36},"end":{"line":103,"column":45}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div data-type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" class=\"field-section custom_user_field\" data-field-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                                            <div class=\"name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_user_field") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":87,"column":44},"end":{"line":101,"column":51}}})) != null ? stack1 : "")
    + "                                        </div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"pill-container not-editable\" data-field-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                                                    <div class=\"input\" contenteditable=\"false\" style=\"display: none;\"></div>\n                                                </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_link") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":91,"column":44},"end":{"line":101,"column":44}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</a>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_external_account") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":93,"column":44},"end":{"line":101,"column":44}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</a>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_value") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":96,"column":48},"end":{"line":100,"column":55}}})) != null ? stack1 : "")
    + "                                            ";
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"value rendered_markdown\">"
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_value") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":97,"column":85},"end":{"line":97,"column":126}}}))
    + "</div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <div class=\"value\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"user-profile-modal\" data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":73}}}) : helper)))
    + "\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container new-style\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"dialog_title\">\n            <div class=\"modal__header\">\n                <div class=\"tippy-zulip-tooltip\" data-tippy-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"last_seen") || (depth0 != null ? lookupProperty(depth0,"last_seen") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"last_seen","hash":{},"data":data,"loc":{"start":{"line":5,"column":69},"end":{"line":5,"column":82}}}) : helper)))
    + "\">\n                    <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_circle_class") || (depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":54}}}) : helper)))
    + " user_circle user_profile_presence\"></span>\n                </div>\n                <h1 class=\"modal__title\" id=\"name\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"full_name") || (depth0 != null ? lookupProperty(depth0,"full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":33}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":57},"end":{"line":19,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </div>\n            <div id=\"tab-toggle\" class=\"center\"></div>\n            <main class=\"modal__body\" id=\"body\" data-simplebar data-simplebar-auto-hide=\"false\">\n                <div class=\"tab-data\">\n                    <div class=\"tabcontent active\" id=\"profile-tab\">\n                        <div class=\"top\">\n                            <div class=\"col-wrap col-left\">\n                                <div id=\"default-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_email") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":33,"column":43}}})) != null ? stack1 : "")
    + "                                    <div id=\"user-id\" class=\"default-field\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":58},"end":{"line":35,"column":79}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":36,"column":59},"end":{"line":36,"column":70}}}) : helper)))
    + "</div>\n                                    </div>\n                                    <div id=\"user-type\" class=\"default-field\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":58},"end":{"line":39,"column":76}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":48,"column":47}}})) != null ? stack1 : "")
    + "                                    </div>\n                                    <div id=\"date-joined\" class=\"default-field\">\n                                        <div class=\"name\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":58},"end":{"line":51,"column":78}}})) != null ? stack1 : "")
    + "</div>\n                                        <div class=\"value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"date_joined") || (depth0 != null ? lookupProperty(depth0,"date_joined") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date_joined","hash":{},"data":data,"loc":{"start":{"line":52,"column":59},"end":{"line":52,"column":74}}}) : helper)))
    + "</div>\n                                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_time") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":36},"end":{"line":59,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                            <div class=\"col-wrap col-right\">\n                                <div id=\"avatar\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_is_guest") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":49},"end":{"line":63,"column":99}}})) != null ? stack1 : "")
    + "\n                                  style=\"background-image: url('"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_avatar") || (depth0 != null ? lookupProperty(depth0,"user_avatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":64,"column":64},"end":{"line":64,"column":79}}}) : helper)))
    + "');\">\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"bottom\">\n                            <div id=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":104,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"tabcontent\" id=\"user-profile-streams-tab\">\n                        <div class=\"stream-list-top-section\">\n                            <div class=\"header-section\">\n                                <h3 class=\"stream-list-header\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Subscribed streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":63},"end":{"line":112,"column":90}}}))
    + "</h3>\n                            </div>\n                            <input type=\"text\" class=\"stream-search\" placeholder=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":82},"end":{"line":114,"column":105}}}))
    + "\" />\n                            <button type=\"button\" class=\"clear_search_button\" id=\"clear_stream_search\">\n                                <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                            </button>\n                        </div>\n                        <div class=\"alert stream_list_info\"></div>\n                        <div class=\"subscription-stream-list\">\n                            <table class=\"user-stream-list\" data-empty=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"No stream subscriptions.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":72},"end":{"line":121,"column":104}}}))
    + "\"></table>\n                        </div>\n                    </div>\n\n                    <div class=\"tabcontent\" id=\"user-profile-groups-tab\">\n                        <div class=\"subscription-group-list\">\n                            <table class=\"user-group-list\" data-empty=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"No user group subscriptions.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":71},"end":{"line":127,"column":107}}}))
    + "\"></table>\n                        </div>\n                    </div>\n                </div>\n            </main>\n        </div>\n    </div>\n</div>\n";
},"useData":true});