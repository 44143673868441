var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"user-group-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":42}}}))
    + "\">\n    <form id=\"user_group_creation_form\">\n        <div class=\"alert user_group_create_info\"></div>\n        <div id=\"user_group_creating_indicator\"></div>\n        <div class=\"user-group-creation-body\">\n            <section class=\"block\">\n                <label for=\"create_user_group_name\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":44}}}))
    + "\n                </label>\n                <input type=\"text\" name=\"user_group_name\" id=\"create_user_group_name\"\n                  placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":55}}}))
    + "\" value=\"\" autocomplete=\"off\" />\n                <div id=\"user_group_name_error\" class=\"user_group_creation_error\"></div>\n            </section>\n            <section class=\"block\">\n                <label for=\"create_user_group_description\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":51}}}))
    + "\n                </label>\n                <input type=\"text\" name=\"user_group_description\" id=\"create_user_group_description\"\n                  placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":31},"end":{"line":20,"column":62}}}))
    + "\" value=\"\" autocomplete=\"off\" />\n            </section>\n            <section class=\"block\">\n                <label for=\"people_to_add_in_group\">\n                    <h4 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":68},"end":{"line":24,"column":91}}}))
    + "</h4>\n                </label>\n                <div id=\"user_group_membership_error\" class=\"user_group_creation_error\"></div>\n                <div class=\"controls\" id=\"people_to_add_in_group\"></div>\n            </section>\n        </div>\n        <div class=\"modal-footer\">\n            <button class=\"button small white rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":76},"end":{"line":31,"column":91}}}))
    + "</button>\n            <button class=\"finalize_create_user_group button small sea-green rounded\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":100},"end":{"line":32,"column":115}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});