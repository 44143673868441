var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='choice-row movable-profile-field-row' data-value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":1,"column":62},"end":{"line":1,"column":71}}}) : helper)))
    + "\">\n    <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n    <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n    <input type='text' placeholder='"
    + alias4(lookupProperty(helpers,"t").call(alias1,"New option",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":55}}}))
    + "' value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":4,"column":64},"end":{"line":4,"column":74}}}) : helper)))
    + "\" />\n\n    <button type='button' class=\"button rounded small delete-choice\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":91}}}))
    + "\">\n        <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n";
},"useData":true});