var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "org-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":29}}}))
    + "</h3>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Language and time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":42}}}))
    + "</h3>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./language_selection_widget.hbs"),depth0,{"name":"language_selection_widget","hash":{"section_title":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"default_language_settings_label") : stack1),"setting_value":(depth0 != null ? lookupProperty(depth0,"default_language_name") : depth0),"section_name":"default_language_name"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label>\n                        <input type=\"radio\" class=\"setting_emojiset_choice prop-element\" name=\"emojiset\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" data-setting-widget-type=\"radio-group\"/>\n                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</span>\n                        <span class=\"right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),"text",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":53,"column":34},"end":{"line":53,"column":54}}}),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":53,"column":28},"end":{"line":60,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),"google-blob",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":62,"column":30},"end":{"line":62,"column":57}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":24},"end":{"line":65,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"emoji_alt_code\">&nbsp;:relaxed:</div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "-64/1f642.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "-64/1f44d.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "-64/1f680.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "-64/1f389.png\" />\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span>(<em>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"deprecated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":35},"end":{"line":63,"column":54}}}))
    + "</em>)</span>\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/emoji-and-emoticons#change-your-emoji-set"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label>\n                        <input type=\"radio\" class=\"setting_user_list_style_choice prop-element\" name=\"user_list_style\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" data-setting-widget-type=\"radio-group\"/>\n                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</span>\n                        <span class=\"right preview\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"code") : depth0),1,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":91,"column":34},"end":{"line":91,"column":50}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":28},"end":{"line":94,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"code") : depth0),2,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":95,"column":34},"end":{"line":95,"column":50}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":28},"end":{"line":103,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </label>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"user-name\">"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"full_name") : depths[1]), depth0))
    + "</span>\n                            <div class=\"emoji status_emoji emoji-1f3e0\"></div>\n";
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"user-name-and-status-text\">\n                                <div class=\"user-name-and-status-emoji\">\n                                    <span class=\"user-name\">"
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"full_name") : depths[1]), depth0))
    + "</span>\n                                    "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),depth0,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                                <span class=\"status-text\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Working remotely",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":58},"end":{"line":101,"column":83}}}))
    + "</span>\n                            </div>\n";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depths[1] != null ? lookupProperty(depths[1],"prefix") : depths[1]),"render_only":lookupProperty(helpers,"lookup").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"display_settings") : depths[1])) != null ? lookupProperty(stack1,"render_only") : stack1),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":146,"column":26},"end":{"line":146,"column":71}}}),"label":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_label") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":145,"column":20},"end":{"line":145,"column":51}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_object") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":144,"column":25},"end":{"line":144,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"display-settings-form\">\n    <div class=\"lang-time-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":35},"end":{"line":2,"column":114}}})) != null ? stack1 : "")
    + "\">\n        <!-- this is inline block so that the alert notification can sit beside\n        it. If there's not an alert, don't make it inline-block.-->\n        <div class=\"subsection-header inline-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":11,"column":99},"end":{"line":11,"column":123}}}),"section_name":"lang-time-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label for=\"twenty_four_hour_time\" class=\"dropdown-title\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"twenty_four_hour_time") : stack1), depth0))
    + "</label>\n            <select name=\"twenty_four_hour_time\" class=\"setting_twenty_four_hour_time prop-element\" data-setting-widget-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"twenty_four_hour_time_values") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n    </div>\n\n\n    <div class=\"theme-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":32,"column":31},"end":{"line":32,"column":110}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header\">\n            <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":30},"end":{"line":34,"column":44}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":35,"column":95},"end":{"line":35,"column":119}}}),"section_name":"theme-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group\">\n            <label for=\"color_scheme\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Color scheme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":61},"end":{"line":39,"column":82}}}))
    + "</label>\n            <select name=\"color_scheme\" class=\"setting_color_scheme prop-element\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"color_scheme_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"input-group\">\n            <label class=\"emoji-theme title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":45},"end":{"line":46,"column":65}}}))
    + "</label>\n            <div class=\"emojiset_choices grey-box\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"emojiset_choices") : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":67,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depth0 != null ? lookupProperty(depth0,"prefix") : depth0),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"translate_emoticons") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"translate_emoticons") : stack1),"setting_name":"translate_emoticons"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depth0 != null ? lookupProperty(depth0,"prefix") : depth0),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"display_emoji_reaction_users") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"display_emoji_reaction_users") : stack1),"setting_name":"display_emoji_reaction_users"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User list style",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":33},"end":{"line":84,"column":57}}}))
    + "</label>\n            <div class=\"user_list_style_values grey-box\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_list_style_values") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":106,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n    <div class=\"advanced-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":111,"column":34},"end":{"line":111,"column":113}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header\">\n            <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Advanced",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":113,"column":30},"end":{"line":113,"column":47}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":114,"column":98},"end":{"line":114,"column":122}}}),"section_name":"advanced-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group thinner setting-next-is-related\">\n            <label for=\"default_view\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":61},"end":{"line":118,"column":82}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-default-view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"default_view\" class=\"setting_default_view prop-element\" data-setting-widget-type=\"string\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"default_view_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depth0 != null ? lookupProperty(depth0,"prefix") : depth0),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"escape_navigates_to_default_view") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"escape_navigates_to_default_view") : stack1),"setting_name":"escape_navigates_to_default_view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label for=\"demote_inactive_streams\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Demote inactive streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":72},"end":{"line":133,"column":104}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/manage-inactive-streams"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"demote_inactive_streams\" class=\"setting_demote_inactive_streams prop-element\"  data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"demote_inactive_streams_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"display_settings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1)) != null ? lookupProperty(stack1,"user_display_settings") : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":8},"end":{"line":148,"column":17}}})) != null ? stack1 : "")
    + "\n    </div>\n</form>\n";
},"usePartial":true,"useData":true,"useDepths":true});