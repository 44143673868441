var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        Are you sure you want to mention all <strong>{count}</strong> people in this stream?\n        <br />\n        This will send email and mobile push notifications to most of those <strong>{count}</strong> users.\n        <br />\n        If you don't want to do that, please edit your message to remove the <strong>@{mention}</strong> mention.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-all-everyone\">\n    <span class=\"compose-all-everyone-msg\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </span>\n    <span class=\"compose-all-everyone-controls\">\n        <button type=\"button\" class=\"btn btn-warning compose-all-everyone-confirm\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Yes, send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":83},"end":{"line":12,"column":101}}}))
    + "</button>\n    </span>\n</div>\n";
},"useData":true});