var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"todo-widget\">\n    <h4>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Task list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":26}}}))
    + "</h4>\n    <div class=\"add-task-bar\">\n        <input type=\"text\" class=\"add-task\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New task",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":57},"end":{"line":4,"column":73}}}))
    + "\" />\n        <input type=\"text\" class=\"add-desc\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":76}}}))
    + "\" />\n        <button class=\"add-task\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add task",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":50}}}))
    + "</button>\n        <div class=\"widget-error\"></div>\n    </div>\n    <ul class=\"todo-widget new-style\">\n    </ul>\n</div>\n";
},"useData":true});