var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"hide-streamlist-toggle-visibility\"";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"searchbox\">\n                        <form id=\"searchbox_form\" class=\"form-search navbar-search\">\n                            <div id=\"search_arrows\" class=\"pill-container input-append\">\n                                <span class=\"search_icon search_open\" ><i class=\"fa fa-search\" aria-hidden=\"true\"></i></span>\n                                <div contenteditable=\"true\" class=\"input search-query input-block-level\" id=\"search_query\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":148},"end":{"line":23,"column":163}}}))
    + "\"\n                                  autocomplete=\"off\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":80}}}))
    + "\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":89},"end":{"line":24,"column":104}}}))
    + " (/)\">\n                                </div>\n                                <button class=\"btn search_button\" type=\"button\" id=\"search_exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Exit search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":109},"end":{"line":26,"column":129}}}))
    + "\">\n                                    <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                                </button>\n                            </div>\n                        </form>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"searchbox_legacy\">\n                        <form id=\"searchbox_form\" class=\"form-search navbar-search\">\n                            <div id=\"search_arrows\" class=\"input-append\">\n                                <span class=\"search_icon search_open\" ><i class=\"fa fa-search\" aria-hidden=\"true\"></i></span>\n                                <input class=\"search-query input-block-level home-page-input\" id=\"search_query\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":137},"end":{"line":37,"column":152}}}))
    + "\"\n                                  autocomplete=\"off\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":65},"end":{"line":38,"column":80}}}))
    + "\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":89},"end":{"line":38,"column":104}}}))
    + " (/)\"/>\n                                <button class=\"btn search_button\" type=\"button\" id=\"search_exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Exit search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":109},"end":{"line":39,"column":129}}}))
    + "\"><i class=\"fa fa-remove\" aria-hidden=\"true\"></i></button>\n                            </div>\n                        </form>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"hide-navbar-buttons-visibility\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header\">\n    <nav class=\"header-main rightside-userlist\" id=\"top_navbar\">\n        <div class=\"column-left\">\n            <a class=\"brand no-style\" href=\"#\">\n                <img id=\"realm-logo\" src=\"\" alt=\"\" class=\"nav-logo no-drag\"/>\n            </a>\n        </div>\n        <div class=\"column-middle\" id=\"navbar-middle\">\n            <div class=\"column-middle-inner\">\n                <div id=\"streamlist-toggle\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"embedded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":108}}})) != null ? stack1 : "")
    + " title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":116},"end":{"line":10,"column":136}}}))
    + " (q)\">\n                    <a id=\"streamlist-toggle-button\" role=\"button\" tabindex=\"0\"><i class=\"fa fa-reorder\" aria-hidden=\"true\"></i>\n                        <span id=\"streamlist-toggle-unreadcount\">0</span>\n                    </a>\n                </div>\n                <div class=\"top-navbar-border top-navbar-container\">\n                    <div id=\"message_view_header\" class=\"notdisplayed\">\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"search_pills_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n        <div class=\"column-right\">\n            <div class=\"only-visible-for-spectators\">\n                <div class=\"spectator_login_buttons hide-xl\">\n                    <a href=\"/register\"  class=\"signup_button\">\n                        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sign up",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":30},"end":{"line":51,"column":46}}}))
    + "</span>\n                    </a>\n                    <div class=\"divider\">|</div>\n                    <a class=\"login_button\">\n                        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":30},"end":{"line":55,"column":45}}}))
    + "</span>\n                    </a>\n                </div>\n                <div class=\"spectator_narrow_login_button hide show-xl\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":92},"end":{"line":58,"column":107}}}))
    + "\" data-tippy-placement=\"bottom\">\n                    <a class=\"login_button\">\n                        <i class=\"fa fa-sign-in\"></i>\n                    </a>\n                </div>\n            </div>\n            <div id=\"userlist-toggle\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":45},"end":{"line":64,"column":63}}}))
    + " (w)\" class=\"hidden-for-spectators\">\n                <a id=\"userlist-toggle-button\" role=\"button\" tabindex=\"0\"><i class=\"fa fa-group\" aria-hidden=\"true\"></i>\n                    <span id=\"userlist-toggle-unreadcount\">0</span>\n                </a>\n            </div>\n            <div id=\"navbar-buttons\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"embedded") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":37},"end":{"line":69,"column":98}}})) != null ? stack1 : "")
    + ">\n            </div>\n        </div>\n    </nav>\n</div>\n";
},"useData":true});