var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Configure the default streams new users are subscribed to when joining your organization.";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form class=\"form-horizontal default-stream-form\">\n        <div class=\"add-new-default-stream-box grey-box\">\n            <div class=\"wrapper\">\n                <div class=\"alert\" id=\"admin-default-stream-status\"></div>\n                <div class=\"settings-section-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add new default stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":83}}}))
    + "</div>\n                <div class=\"inline-block\" id=\"default_stream_inputs\">\n                    <label for=\"default_stream_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":73}}}))
    + "</label>\n                    <input class=\"create_default_stream\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":82},"end":{"line":14,"column":102}}}))
    + "\" name=\"stream_name\" autocomplete=\"off\" />\n                </div>\n                <div class=\"inline-block\">\n                    <button type=\"submit\" id=\"do_submit_stream\" class=\"button rounded sea-green\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":97},"end":{"line":17,"column":116}}}))
    + "</button>\n                </div>\n            </div>\n        </div>\n    </form>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-default-streams-list\" class=\"settings-section\" data-name=\"default-streams-list\">\n    <div class=\"side-padded-container\">\n        <p>"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":114}}})) != null ? stack1 : "")
    + "</p>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":35}}}))
    + "</h3>\n        <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter default streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":55},"end":{"line":26,"column":86}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":100},"end":{"line":26,"column":123}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-condensed table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":80},"end":{"line":32,"column":93}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody class=\"required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No default streams match you current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":53},"end":{"line":37,"column":106}}}))
    + "\"\n              id=\"admin_default_streams_table\" class=\"admin_default_stream_table\"></tbody>\n        </table>\n    </div>\n\n    <div id=\"admin_page_default_streams_loading_indicator\"></div>\n</div>\n";
},"useData":true});