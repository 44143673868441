var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group email_change_container\">\n            <label for=\"email\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":45}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"email\" value=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\" readonly/>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"edit-user-form\" data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\">\n    <form class=\"form-horizontal name-setting\">\n        <input type=\"hidden\" name=\"is_full_name\" value=\"true\" />\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit_user_full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Full name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":63}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"full_name\" id=\"edit_user_full_name\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"full_name") : depth0), depth0))
    + "\" />\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"input-group user_id_container\">\n            <label for=\"user_id\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":33},"end":{"line":15,"column":49}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"user_id\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"user-role-select\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"User role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":62},"end":{"line":19,"column":80}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"user-role-select\" id=\"user-role-select\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"disable_role_dropdown") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":100},"end":{"line":22,"column":144}}})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"user_role_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"custom-profile-field-form\"></div>\n    </form>\n    <div class=\"input-group new-style\">\n        <button class=\"button rounded btn-danger deactivate_user_button\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Deactivate user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":36}}}))
    + "\n        </button>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});