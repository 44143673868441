var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a role=\"button\" class=\"compose_control_button compose_upload_file fa fa-paperclip notdisplayed\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":113},"end":{"line":4,"column":134}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":167},"end":{"line":4,"column":188}}}))
    + "\"></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " hide ";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide-lg";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide-sm";
},"9":function(container,depth0,helpers,partials,data) {
    return "show-lg";
},"11":function(container,depth0,helpers,partials,data) {
    return "show-sm";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose_control_buttons_container order-1\">\n    <input type=\"file\" class=\"file_input notvisible pull-left\" multiple />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"file_upload_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "    <a role=\"button\" class=\"markdown_preview compose_control_button fa fa-eye\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":91},"end":{"line":6,"column":107}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":140},"end":{"line":6,"column":156}}}))
    + "\"></a>\n    <a role=\"button\" class=\"undo_markdown_preview compose_control_button fa fa-edit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":97},"end":{"line":7,"column":111}}}))
    + "\" tabindex=0 style=\"display:none;\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":166},"end":{"line":7,"column":180}}}))
    + "\"></a>\n    <a role=\"button\" class=\"compose_control_button fa fa-video-camera video_link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":94},"end":{"line":8,"column":117}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":150},"end":{"line":8,"column":173}}}))
    + "\"></a>\n    <a role=\"button\" class=\"compose_control_button fa fa-smile-o emoji_map\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":88},"end":{"line":9,"column":106}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":139},"end":{"line":9,"column":157}}}))
    + "\"></a>\n    <a role=\"button\" class=\"compose_control_button fa fa-clock-o time_pick\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":88},"end":{"line":10,"column":112}}}))
    + "\" tabindex=0 data-tooltip-template-id=\"add-global-time-tooltip\" data-tippy-maxWidth=\"none\"></a>\n    <a role=\"button\" class=\"compose_control_button compose_gif_icon "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"giphy_enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":111}}})) != null ? stack1 : "")
    + " zulip-icon zulip-icon-gif\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":151},"end":{"line":11,"column":167}}}))
    + "\" tabindex=0 data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":200},"end":{"line":11,"column":216}}}))
    + "\"></a>\n    <div class=\"divider hide-sm\">|</div>\n    <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message_id") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":63}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons_in_popover.hbs"),depth0,{"name":"compose_control_buttons_in_popover","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <a role=\"button\" class=\"compose_control_button compose_draft_button hide-sm\" tabindex=0 href=\"#drafts\" data-tooltip-template-id=\"compose_draft_tooltip\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":23}}}))
    + "\n    </a>\n    <template id=\"compose_draft_tooltip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":41},"end":{"line":19,"column":56}}}))
    + " <span class=\"hotkey-hint\">(d)</span></template>\n    <div class=\"compose_control_menu_wrapper\" role=\"button\" tabindex=0>\n        <a class=\"compose_control_button zulip-icon zulip-icon-ellipsis-v-solid hide "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message_id") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":21,"column":85},"end":{"line":21,"column":132}}})) != null ? stack1 : "")
    + " compose_control_menu\" tabindex=\"-1\" data-tippy-content=\"Compose actions\"></a>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});