var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <div id=\"subscribe-to-more-streams\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"left-sidebar\" id=\"left-sidebar\" role=\"navigation\">\n    <div class=\"narrows_panel\">\n        <ul id=\"global_filters\" class=\"filters\">\n            <li class=\"top_left_all_messages top_left_row\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"All messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":87}}}))
    + " (a)\">\n                <a href=\"#all_messages\" class=\"home-link\">\n                    <span class=\"filter-icon\">\n                        <i class=\"fa fa-align-left\" aria-hidden=\"true\"></i>\n                    </span><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"All messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":47}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow all-messages-sidebar-menu-icon hidden-for-spectators\"><i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i></span>\n            </li>\n            <li class=\"top_left_recent_topics top_left_row\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":67},"end":{"line":16,"column":96}}}))
    + " (t)\">\n                <a href=\"#recent\">\n                    <span class=\"filter-icon\">\n                        <i class=\"fa fa-clock-o\" aria-hidden=\"true\"></i>\n                    </span><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":55}}}))
    + "</span>\n                </a>\n            </li>\n            <li class=\"top_left_mentions top_left_row hidden-for-spectators\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":84},"end":{"line":25,"column":101}}}))
    + "\">\n                <a href=\"#narrow/is/mentioned\">\n                    <span class=\"filter-icon\">\n                        <i class=\"fa fa-at\" aria-hidden=\"true\"></i>\n                    </span><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":26},"end":{"line":31,"column":43}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n            </li>\n            <li class=\"top_left_starred_messages top_left_row hidden-for-spectators\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":92},"end":{"line":35,"column":117}}}))
    + "\">\n                <a href=\"#narrow/is/starred\">\n                    <span class=\"filter-icon\">\n                        <i class=\"fa fa-star\" aria-hidden=\"true\"></i>\n                    </span><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":26},"end":{"line":41,"column":51}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow starred-messages-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i></span>\n            </li>\n            <li class=\"top_left_drafts top_left_row hidden-for-spectators\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":82},"end":{"line":46,"column":97}}}))
    + " (d)\">\n                <a href=\"#drafts\">\n                    <span class=\"filter-icon\">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </span><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":26},"end":{"line":52,"column":41}}}))
    + "</span>\n                    <span class=\"unread_count\"></span>\n                </a>\n                <span class=\"arrow drafts-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i></span>\n            </li>\n        </ul>\n    </div>\n\n    <div id=\"private_messages_sticky_header\" class=\"private_messages_container zoom-out hidden-for-spectators\">\n        <div id=\"private_messages_section\">\n            <div id=\"private_messages_section_header\" class=\"zoom-out zoom-in-sticky\">\n                <span id=\"pm_tooltip_container\">\n                    <i id=\"toggle_private_messages_section_icon\" class=\"fa fa-sm fa-caret-down toggle_private_messages_section zoom-in-hide\" aria-hidden=\"true\"></i>\n                    <h4 class=\"sidebar-title toggle_private_messages_section\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"PRIVATE MESSAGES",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":78},"end":{"line":65,"column":103}}}))
    + "</h4>\n                </span>\n                <span class=\"unread_count\"></span>\n                <a id=\"show_all_private_messages\" href=\"#narrow/is/private\">\n                    <i class=\"fa fa-align-right\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"All private messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":61},"end":{"line":69,"column":90}}}))
    + "\"></i>\n                </a>\n            </div>\n        </div>\n        <a class=\"zoom-out-hide\" id=\"hide_more_private_messages\">\n            <span> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"back to streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":19},"end":{"line":74,"column":43}}}))
    + "</span>\n        </a>\n    </div><div id=\"left_sidebar_scroll_container\" class=\"scrolling_list\" data-simplebar>\n        <div class=\"private_messages_container zoom-out hidden-for-spectators\">\n            <div id=\"private_messages_list\"></div>\n        </div>\n\n        <div id=\"streams_list\" class=\"zoom-out\">\n            <div id=\"streams_header\" class=\"zoom-in-hide\"><h4 class=\"sidebar-title\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":104},"end":{"line":84,"column":127}}}))
    + " (q)\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"STREAMS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":133},"end":{"line":84,"column":149}}}))
    + "</h4>\n                <span class=\"tippy-zulip-tooltip streams_inline_icon_wrapper hidden-for-spectators\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":120},"end":{"line":85,"column":140}}}))
    + "\">\n                    <i id=\"streams_inline_icon\" class='fa fa-plus' aria-hidden=\"true\" ></i>\n                </span>\n                <i class=\"streams_filter_icon fa fa-filter tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":119},"end":{"line":88,"column":142}}}))
    + " (q)\"></i>\n                <div class=\"input-append notdisplayed stream_search_section\">\n                    <input class=\"stream-list-filter home-page-input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":114},"end":{"line":90,"column":137}}}))
    + "\" />\n                    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_stream_button\">\n                        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div id=\"topics_header\">\n                <a class=\"show-all-streams\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Back to streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":57},"end":{"line":97,"column":81}}}))
    + "</a>\n            </div>\n            <div id=\"stream-filters-container\">\n                <ul id=\"stream_filters\" class=\"filters\"></ul>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":16},"end":{"line":103,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});