var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a id=\"show_realm_domains_modal\" role=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"[Configure]",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":71},"end":{"line":34,"column":91}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-permissions\" data-name=\"organization-permissions\" class=\"settings-section\">\n    <form class=\"form-horizontal admin-realm-form org-permissions-form\">\n\n        <div id=\"org-join\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Joining the organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":53}}}))
    + "</h3>\n                <i class=\"fa fa-info-circle settings-info-icon realm_message_retention_tooltip tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Only owners can change these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":155},"end":{"line":7,"column":202}}}))
    + "\"></i>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"org-join"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                <div class=\"input-group\">\n                    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_invite_required") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_invite_required") : depth0),"prefix":"id_","setting_name":"realm_invite_required"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    <label for=\"realm_invite_to_realm_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can invite users to this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":85},"end":{"line":17,"column":135}}}))
    + "\n                    </label>\n                    <select name=\"realm_invite_to_realm_policy\" id=\"id_realm_invite_to_realm_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"invite_to_realm_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"input-group\">\n                    <label for=\"realm_org_join_restrictions\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restrict email domains of new users?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":84},"end":{"line":25,"column":129}}}))
    + "</label>\n                    <select name=\"realm_org_join_restrictions\" id=\"id_realm_org_join_restrictions\" class=\"prop-element\">\n                        <option value=\"no_restriction\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"No restrictions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":55},"end":{"line":27,"column":79}}}))
    + "</option>\n                        <option value=\"no_disposable_email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Don’t allow disposable email addresses",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":60},"end":{"line":28,"column":107}}}))
    + "</option>\n                        <option value=\"only_selected_domain\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restrict to a list of domains",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":61},"end":{"line":29,"column":99}}}))
    + "</option>\n                    </select>\n                    <div class=\"dependent-block\">\n                        <p id=\"allowed_domains_label\" class=\"inline-block\"></p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":35,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_waiting_period_setting\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Waiting period before new members turn into full members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":89}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/restrict-permissions-of-new-members"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_waiting_period_setting\" id=\"id_realm_waiting_period_setting\" class=\"prop-element\">\n                        <option value=\"none\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"None",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":45},"end":{"line":44,"column":58}}}))
    + "</option>\n                        <option value=\"three_days\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"3 days",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":51},"end":{"line":45,"column":66}}}))
    + "</option>\n                        <option value=\"custom_days\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":67}}}))
    + "</option>\n                    </select>\n                    <div class=\"dependent-block\">\n                        <label for=\"id_realm_waiting_period_threshold\" class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Waiting period (days)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":92},"end":{"line":50,"column":122}}}))
    + ":</label>\n                        <input type=\"text\" id=\"id_realm_waiting_period_threshold\"\n                          name=\"realm_waiting_period_threshold\"\n                          class=\"admin-realm-time-limit-input prop-element\"\n                          value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"realm_waiting_period_threshold") : depth0), depth0))
    + "\"/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-user-identity\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":42}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"user-identity"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-permissions-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_name_changes_disabled") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_name_changes_disabled") : depth0),"prefix":"id_","setting_name":"realm_name_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_email_changes_disabled") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_email_changes_disabled") : depth0),"prefix":"id_","setting_name":"realm_email_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_avatar_changes_disabled") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_avatar_changes_disabled") : depth0),"prefix":"id_","setting_name":"realm_avatar_changes_disabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"input-group\">\n                <label for=\"realm_email_address_visibility\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access user email addresses",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":60},"end":{"line":85,"column":104}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/restrict-visibility-of-email-addresses"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </label>\n                <select name=\"realm_email_address_visibility\" class=\"setting-widget prop-element\" id=\"id_realm_email_address_visibility\" data-setting-widget-type=\"number\">\n                    "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"email_address_visibility_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n\n        <div id=\"org-stream-permissions\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":20},"end":{"line":96,"column":47}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                <div class=\"input-group\">\n                    <label for=\"realm_create_public_stream_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can create public streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":101,"column":90},"end":{"line":101,"column":128}}}))
    + "</label>\n                    <select name=\"realm_create_public_stream_policy\" id=\"id_realm_create_public_stream_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./upgrade_tip_widget.hbs"),depth0,{"name":"upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/public-access-option","is_disabled":(depth0 != null ? lookupProperty(depth0,"disable_enable_spectator_access_setting") : depth0),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_enable_spectator_access") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_enable_spectator_access") : depth0),"prefix":"id_","setting_name":"realm_enable_spectator_access"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"input-group realm_create_web_public_stream_policy\">\n                    <label for=\"realm_create_web_public_stream_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can create web-public streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":94},"end":{"line":115,"column":136}}}))
    + "</label>\n                    <select name=\"realm_create_web_public_stream_policy\" id=\"id_realm_create_web_public_stream_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"create_web_public_stream_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_create_private_stream_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can create private streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":91},"end":{"line":121,"column":130}}}))
    + "</label>\n                    <select name=\"realm_create_private_stream_policy\" id=\"id_realm_create_private_stream_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_invite_to_stream_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add users to streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":86},"end":{"line":127,"column":123}}}))
    + "</label>\n                    <select name=\"realm_invite_to_stream_policy\" id=\"id_realm_invite_to_stream_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"realm_wildcard_mention_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can use @all/@everyone mentions in large streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":86},"end":{"line":133,"column":147}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/restrict-wildcard-mentions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_wildcard_mention_policy\" id=\"id_realm_wildcard_mention_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"wildcard_mention_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-msg-editing\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message and topic editing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":145,"column":20},"end":{"line":145,"column":54}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-message-editing-and-deletion"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"msg-editing"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_allow_message_editing") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),"prefix":"id_","setting_name":"realm_allow_message_editing"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"realm_message_content_edit_limit_seconds\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for editing messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":158,"column":97},"end":{"line":158,"column":137}}}))
    + "</label>\n                    <select name=\"realm_message_content_edit_limit_seconds\" id=\"id_realm_message_content_edit_limit_seconds\" class=\"prop-element\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":146},"end":{"line":159,"column":204}}})) != null ? stack1 : "")
    + " data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"msg_edit_limit_dropdown_values") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":160,"column":24},"end":{"line":162,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <div class=\"dependent-block\">\n                        <label for=\"id_realm_message_content_edit_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration editing is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":28},"end":{"line":166,"column":87}}}))
    + ":&nbsp;\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_content_edit_limit_minutes\"\n                          name=\"realm_message_content_edit_limit_minutes\"\n                          class=\"admin-realm-time-limit-input\"\n                          autocomplete=\"off\"\n                          value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"realm_message_content_edit_limit_minutes") : depth0), depth0))
    + "\"\n                          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":26},"end":{"line":173,"column":84}}})) != null ? stack1 : "")
    + "/>\n                    </div>\n                </div>\n\n                <div class=\"input-group\">\n                    <label for=\"realm_edit_topic_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can edit the topic of any message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":178,"column":80},"end":{"line":178,"column":126}}}))
    + "</label>\n                    <select name=\"realm_edit_topic_policy\" id=\"id_realm_edit_topic_policy\" class=\"prop-element\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":146},"end":{"line":179,"column":204}}})) != null ? stack1 : "")
    + ">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_message_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"admin_settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_allow_edit_history") : stack1),"is_checked":(depth0 != null ? lookupProperty(depth0,"realm_allow_edit_history") : depth0),"prefix":"id_","setting_name":"realm_allow_edit_history"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"input-group\">\n                    <label for=\"realm_move_messages_between_streams_policy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can move messages between streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":191,"column":76},"end":{"line":191,"column":122}}}))
    + "\n                    </label>\n                    <select name=\"realm_move_messages_between_streams_policy\" class=\"setting-widget prop-element\" id=\"id_realm_move_messages_between_streams_policy\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-msg-deletion\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message deletion",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":202,"column":20},"end":{"line":202,"column":45}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/edit-or-delete-a-message#delete-a-message"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"msg-deletion"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"inline-block organization-settings-parent\">\n                <label for=\"org-msg-deletion\" class=\"inline-block\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Administrators can delete any message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":209,"column":20},"end":{"line":209,"column":67}}}))
    + "\n                </label>\n                <div class=\"input-group\">\n                    <label for=\"realm_delete_own_message_policy\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can delete their own messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":24},"end":{"line":213,"column":66}}}))
    + "\n                    </label>\n                    <select name=\"realm_delete_own_message_policy\" id=\"id_realm_delete_own_message_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_message_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"input-group time-limit-setting\">\n                    <label for=\"realm_message_content_delete_limit_seconds\" class=\"dropdown-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time limit for deleting messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":222,"column":24},"end":{"line":222,"column":65}}}))
    + "\n                    </label>\n                    <select name=\"realm_message_content_delete_limit_seconds\" id=\"id_realm_message_content_delete_limit_seconds\" class=\"prop-element\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"msg_delete_limit_dropdown_values") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":225,"column":24},"end":{"line":227,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <div class=\"dependent-block\">\n                        <label for=\"id_realm_message_content_delete_limit_minutes\" class=\"inline-block realm-time-limit-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Duration deletion is allowed after posting (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":231,"column":28},"end":{"line":231,"column":89}}}))
    + ":\n                        </label>\n                        <input type=\"text\" id=\"id_realm_message_content_delete_limit_minutes\"\n                          name=\"realm_message_content_delete_limit_minutes\"\n                          class=\"admin-realm-time-limit-input\"\n                          autocomplete=\"off\"\n                          value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"realm_message_content_delete_limit_minutes") : depth0), depth0))
    + "\"/>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"org-other-permissions\" class=\"org-subsection-parent\">\n            <div class=\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":245,"column":20},"end":{"line":245,"column":46}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"other-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"m-10 inline-block organization-permissions-parent\">\n                <div class=\"input-group\">\n                    <label for=\"realm_bot_creation_policy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":250,"column":59},"end":{"line":250,"column":84}}}))
    + "</label>\n                    <select name=\"realm_bot_creation_policy\" class=\"setting-widget prop-element\" id=\"id_realm_bot_creation_policy\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"bot_creation_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"input-group\">\n                    <label for=\"realm_user_group_edit_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can create and manage user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":257,"column":85},"end":{"line":257,"column":131}}}))
    + "</label>\n                    <select name=\"realm_user_group_edit_policy\" id=\"id_realm_user_group_edit_policy\" class=\"prop-element\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"input-group\">\n                    <label for=\"realm_add_custom_emoji_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can add custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":264,"column":86},"end":{"line":264,"column":119}}}))
    + "</label>\n                    <select name=\"realm_add_custom_emoji_policy\" class=\"setting-widget prop-element\" id=\"id_realm_add_custom_emoji_policy\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"common_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"input-group\">\n                    <label for=\"realm_private_message_policy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can use private messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":271,"column":62},"end":{"line":271,"column":99}}}))
    + " ("
    + alias2(lookupProperty(helpers,"t").call(alias1,"beta",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":271,"column":101},"end":{"line":271,"column":114}}}))
    + ")\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/restrict-private-messages"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <select name=\"realm_private_message_policy\" class=\"setting-widget prop-element\" id=\"id_realm_private_message_policy\" data-setting-widget-type=\"number\">\n                        "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"private_message_policy_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});