var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("./notification_settings_checkboxes.hbs"),depth0,{"name":"notification_settings_checkboxes","hash":{"is_muted":(depths[1] != null ? lookupProperty(depths[1],"muted") : depths[1]),"is_disabled":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"is_disabled") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":50}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"stream") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":16,"column":21},"end":{"line":16,"column":44}}}),"prefix":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"stream") : depths[1]),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":47}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"stream-notifications-row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"muted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\" data-stream-id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\">\n    <td>\n        <span id=\"stream_privacy_swatch_"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" class=\"stream-privacy filter-icon\">\n            "
    + ((stack1 = container.invokePartial(require("../stream_privacy.hbs"),depth0,{"name":"../stream_privacy","hash":{"is_web_public":((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"is_web_public") : stack1),"invite_only":((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"invite_only") : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n        "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"stream") : depth0)) != null ? lookupProperty(stack1,"stream_name") : stack1), depth0))
    + "\n        <i class=\"zulip-icon zulip-icon-mute unmute_stream\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":80},"end":{"line":9,"column":95}}}))
    + "\"\n          "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"muted") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":60}}})) != null ? stack1 : "")
    + "></i>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"stream_specific_notification_settings") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true,"useDepths":true});