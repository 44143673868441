var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"new_message_button private_button_container\">\n                <button type=\"button\" class=\"button small rounded compose_private_button\"\n                  id=\"left_bar_compose_private_button_big\"\n                  title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New private message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":25},"end":{"line":41,"column":53}}}))
    + " (x)\">\n                    <span class=\"compose_private_button_label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New private message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":63},"end":{"line":42,"column":91}}}))
    + "</span>\n                </button>\n            </span>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <z-shortcut></z-shortcut> to send\n                                                \n";
},"4":function(container,depth0,helpers,partials,data) {
    return "<kbd>Enter</kbd>";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <z-shortcut></z-shortcut> to send\n                                                \n";
},"7":function(container,depth0,helpers,partials,data) {
    return "<kbd>Ctrl</kbd>+<kbd>Enter</kbd>";
},"9":function(container,depth0,helpers,partials,data) {
    return "Add global time<br />Everyone sees global times in their own time zone.";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"compose-content\">\n    <div id=\"scroll-to-bottom-button-container\" aria-hidden=\"true\">\n        <div id=\"scroll-to-bottom-button-clickable-area\" data-tooltip-template-id=\"scroll-to-bottom-button-tooltip\">\n            <div id=\"scroll-to-bottom-button\">\n                <i class=\"fa fa-chevron-down\"></i>\n            </div>\n        </div>\n        <template id=\"scroll-to-bottom-button-tooltip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll to bottom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":55},"end":{"line":12,"column":80}}}))
    + " <span class=\"hotkey-hint\">("
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"scroll_to_bottom_key_html") : depth0), depth0)) != null ? stack1 : "")
    + ")</span></template>\n    </div>\n    <div id=\"compose_controls\" class=\"new-style\">\n        <div id=\"compose_buttons\">\n            <span class=\"new_message_button reply_button_container \">\n                <button type=\"button\" class=\"button small rounded compose_reply_button\"\n                  id=\"left_bar_compose_reply_button_big\"\n                  title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":25},"end":{"line":19,"column":59}}}))
    + " (r)\">\n                    <span class=\"compose_reply_button_label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":61},"end":{"line":20,"column":85}}}))
    + "</span>\n                </button>\n            </span>\n            <span class=\"new_message_button mobile_button_container\">\n                <button type=\"button\" class=\"button small rounded compose_mobile_button\"\n                  id=\"left_bar_compose_mobile_button_big\"\n                  title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":25},"end":{"line":26,"column":45}}}))
    + " (c)\">\n                    <span>+</span>\n                </button>\n            </span>\n            <span class=\"new_message_button stream_button_container\">\n                <button type=\"button\" class=\"button small rounded compose_stream_button\"\n                  id=\"left_bar_compose_stream_button_big\"\n                  title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":25},"end":{"line":33,"column":43}}}))
    + " (c)\">\n                    <span class=\"compose_stream_button_label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":62},"end":{"line":34,"column":80}}}))
    + "</span>\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"embedded") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":45,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"message_comp\">\n        <div class=\"alert\" id=\"compose-send-status\">\n            <span class=\"compose-send-status-close\">&times;</span>\n            <span id=\"compose-error-msg\"></span>\n        </div>\n        <div id=\"compose_resolved_topic\" class=\"alert home-error-bar\"></div>\n        <div id=\"compose_invite_users\" class=\"alert home-error-bar\"></div>\n        <div id=\"compose-all-everyone\" class=\"alert home-error-bar\"></div>\n        <div id=\"compose_not_subscribed\" class=\"alert home-error-bar\"></div>\n        <div id=\"compose_private_stream_alert\" class=\"alert home-error-bar\"></div>\n        <div id=\"out-of-view-notification\" class=\"notification-alert\"></div>\n        <div class=\"composition-area\">\n            <form id=\"send_message_form\" action=\"/json/messages\" method=\"post\">\n                "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"csrf_input") : depth0), depth0))
    + "\n                <div class=\"compose_table\">\n                    <div id=\"compose_top\">\n                        <div id=\"compose_top_right\" class=\"order-2\">\n                            <button type=\"button\" class=\"expand_composebox_button fa fa-chevron-up\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":112},"end":{"line":65,"column":135}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":157},"end":{"line":65,"column":180}}}))
    + "\"></button>\n                            <button type=\"button\" class=\"collapse_composebox_button fa fa-chevron-down\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":116},"end":{"line":66,"column":141}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":163},"end":{"line":66,"column":188}}}))
    + "\"></button>\n                            <button type=\"button\" class=\"close fa fa-times\" id='compose_close' data-tooltip-template-id=\"compose_close_tooltip\"></button>\n                            <template id=\"compose_close_tooltip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":65},"end":{"line":68,"column":88}}}))
    + " <span class=\"hotkey-hint\">(Esc)</span></template>\n                            <template id=\"compose_close_and_save_tooltip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":74},"end":{"line":69,"column":112}}}))
    + " <span class=\"hotkey-hint\">(Esc)</span></template>\n                        </div>\n                        <div id=\"stream-message\" class=\"order-1\">\n                            <div class=\"stream-selection-header-colorblock message_header_stream left_part\" tab-index=\"-1\"></div>\n                            <div class=\"right_part\">\n                                <span id=\"compose-lock-icon\">\n                                    <i class=\"fa fa-lock\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a private stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":65},"end":{"line":75,"column":98}}}))
    + "\" aria-hidden=\"true\"></i>\n                                </span>\n                                <span id=\"compose-globe-icon\">\n                                    <i class=\"zulip-icon zulip-icon-globe\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a web-public stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":82},"end":{"line":78,"column":118}}}))
    + "\" aria-hidden=\"true\"></i>\n                                </span>\n                                <a role=\"button\" class=\"narrow_to_compose_recipients zulip-icon zulip-icon-arrow-left-circle order-1\" data-tooltip-template-id=\"narrow_to_compose_recipients_tooltip\" tabindex=\"0\">\n                                </a>\n                                <input type=\"text\" class=\"recipient_box\" name=\"stream_message_recipient_stream\" id=\"stream_message_recipient_stream\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_stream_name_length") : depth0), depth0))
    + "\" value=\"\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":212},"end":{"line":82,"column":227}}}))
    + "\" autocomplete=\"off\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":273},"end":{"line":82,"column":288}}}))
    + "\" />\n                                <i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>\n                                <input type=\"text\" class=\"recipient_box\" name=\"stream_message_recipient_topic\" id=\"stream_message_recipient_topic\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_topic_length") : depth0), depth0))
    + "\" value=\"\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":204},"end":{"line":84,"column":218}}}))
    + "\" autocomplete=\"off\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":264},"end":{"line":84,"column":278}}}))
    + "\" />\n                            </div>\n                        </div>\n                        <div id=\"private-message\" class=\"order-1\">\n                            <div class=\"to_text\">\n                                <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"To",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":38},"end":{"line":89,"column":49}}}))
    + ":</span>\n                            </div>\n                            <div class=\"right_part\">\n                                <div class=\"pm_recipient\">\n                                    <a role=\"button\" class=\"narrow_to_compose_recipients zulip-icon zulip-icon-arrow-left-circle order-1\" data-tooltip-template-id=\"narrow_to_compose_recipients_tooltip\" tabindex=\"0\"></a>\n                                    <div class=\"pill-container\" data-before=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":77},"end":{"line":94,"column":93}}}))
    + "\">\n                                        <div class=\"input\" contenteditable=\"true\" id=\"private_message_recipient\" data-no-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add one or more users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":138},"end":{"line":95,"column":168}}}))
    + "\" data-some-recipients-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add another user...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":197},"end":{"line":95,"column":225}}}))
    + "\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"messagebox-wrapper\">\n                        <div class=\"messagebox\">\n                            <textarea class=\"new_message_textarea\" name=\"content\" id='compose-textarea' placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":117},"end":{"line":103,"column":151}}}))
    + "\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Compose your message here...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":178},"end":{"line":103,"column":215}}}))
    + "\"></textarea>\n                            <div class=\"scrolling_list preview_message_area\" data-simplebar id=\"preview_message_area\" style=\"display:none;\">\n                                <div class=\"markdown_preview_spinner\"></div>\n                                <div class=\"preview_content rendered_markdown\"></div>\n                            </div>\n                            <div class=\"drag\"></div>\n                            <div id=\"below-compose-content\">\n                                <div class=\"compose_bottom_top_container\">\n                                    <div class=\"compose_right_float_container order-3\">\n                                        <button type=\"submit\" id=\"compose-send-button\" class=\"button small send_message animated-purple-button\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":151},"end":{"line":112,"column":164}}}))
    + " (Ctrl + Enter)\">\n                                            <img class=\"loader\" alt=\"\" src=\"\" />\n                                            <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":50},"end":{"line":114,"column":63}}}))
    + "</span>\n                                        </button>\n                                    </div>\n                                    "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                                <div class=\"compose_bottom_bottom_container\">\n                                    <span id=\"compose_limit_indicator\"></span>\n                                    <div class=\"enter_sends\">\n                                        <span class=\"enter_sends_true\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":44},"end":{"line":126,"column":51}}})) != null ? stack1 : "")
    + "                                        </span>\n                                        <span class=\"enter_sends_false\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":44},"end":{"line":132,"column":51}}})) != null ? stack1 : "")
    + "                                        </span>\n                                        <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>\n\n<template id=\"add-global-time-tooltip\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":39},"end":{"line":146,"column":124}}})) != null ? stack1 : "")
    + "</template>\n";
},"3_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":125,"column":48},"end":{"line":125,"column":100}}}) || fn;
  return fn;
  }

,"useDecorators":true,"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":131,"column":48},"end":{"line":131,"column":116}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});