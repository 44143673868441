var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_circle_class") || (depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":46}}}) : helper)))
    + " user_circle popover_user_presence tippy-zulip-tooltip hidden-for-spectators\" data-tippy-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_last_seen_time_status") || (depth0 != null ? lookupProperty(depth0,"user_last_seen_time_status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":9,"column":144},"end":{"line":9,"column":174}}}) : helper)))
    + "\"></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"user_info_popover_action_buttons\">\n            <a class=\"user_info_popover_manage_menu_btn\" role=\"button\" tabindex=\"0\" aria-haspopup=\"true\">\n                <i class=\"popover_action_icon zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i>\n            </a>\n        </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_email") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <li class=\"user_popover_email\">\n                <i class=\"fa fa-clone hide_copy_icon\" data-clipboard-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_email") || (depth0 != null ? lookupProperty(depth0,"user_email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_email","hash":{},"data":data,"loc":{"start":{"line":27,"column":75},"end":{"line":27,"column":91}}}) : helper)))
    + "\"></i>\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"user_email") || (depth0 != null ? lookupProperty(depth0,"user_email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_email","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":32}}}) : helper)))
    + "\n            </li>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"user_popover_email half-opacity italic\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":33,"column":59},"end":{"line":33,"column":177}}})) != null ? stack1 : "")
    + "</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":73},"end":{"line":33,"column":117}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "This bot has been deactivated.";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":125},"end":{"line":33,"column":170}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "This user has been deactivated.";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"bot_owner\" data-tippy-content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":81},"end":{"line":39,"column":104}}})) != null ? stack1 : "")
    + ":\n                <span class=\"bot-owner-name view_user_profile\" data-user-id='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0)) != null ? lookupProperty(stack1,"user_id") : stack1), depth0))
    + "'>\n                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"bot_owner") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "\n                </span>\n            </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "Bot owner";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_system_bot") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":48,"column":8}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":40}}})) != null ? stack1 : "")
    + "</li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "System bot";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":47,"column":33}}})) != null ? stack1 : "")
    + "</li>\n        ";
},"27":function(container,depth0,helpers,partials,data) {
    return "Bot";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_type") || (depth0 != null ? lookupProperty(depth0,"user_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_type","hash":{},"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":50,"column":27}}}) : helper)))
    + "</li>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li data-type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" class=\"field-section custom_user_field\" data-field-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_link") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":72,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a tabindex=\"0\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value custom_profile_fields_link tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</a>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_external_account") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":72,"column":12}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a tabindex=\"0\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"value custom_profile_fields_link tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,lookupProperty(helpers,"eq").call(alias3,(depth0 != null ? lookupProperty(depth0,"subtype") : depth0),"github",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":59,"column":26},"end":{"line":59,"column":52}}}),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":63,"column":27}}})) != null ? stack1 : "")
    + "                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\n                </a>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fa fa-github\" aria-hidden=\"true\"></i>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,(depth0 != null ? lookupProperty(depth0,"subtype") : depth0),"twitter",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":61,"column":30},"end":{"line":61,"column":57}}}),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":63,"column":20}}})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fa fa-twitter\" aria-hidden=\"true\"></i>\n                    ";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_value") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":71,"column":23}}})) != null ? stack1 : "")
    + "            ";
},"42":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"value rendered_markdown tippy-zulip-tooltip\" data-tippy-content=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_value") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":68,"column":109},"end":{"line":68,"column":150}}}))
    + "</span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"value tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "</span>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "Joined {date_joined}";
},"48":function(container,depth0,helpers,partials,data) {
    return "        <hr />\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"hidden-for-spectators local_time\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":53},"end":{"line":83,"column":89}}})) != null ? stack1 : "")
    + "</li>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return "{user_time} local time";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"user_info_status_text\">\n            <span id=\"status_message\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":97,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"status_text\">\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"status_text") || (depth0 != null ? lookupProperty(depth0,"status_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":99,"column":20},"end":{"line":99,"column":35}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":20},"end":{"line":102,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </span>\n        </li>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0)) != null ? lookupProperty(stack1,"emoji_alt_code") : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"emoji_alt_code\">&nbsp:"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0)) != null ? lookupProperty(stack1,"emoji_name") : stack1), depth0))
    + ":</div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0)) != null ? lookupProperty(stack1,"url") : stack1),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":92,"column":20},"end":{"line":96,"column":20}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"emoji status_emoji\" />\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"emoji status_emoji emoji-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0)) != null ? lookupProperty(stack1,"emoji_code") : stack1), depth0))
    + "\"></div>\n                    ";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"clear_status_button\">(<a tabindex=\"0\" class=\"clear_status\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":92},"end":{"line":101,"column":111}}})) != null ? stack1 : "")
    + "</a>)</span>\n";
},"63":function(container,depth0,helpers,partials,data) {
    return "clear";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a tabindex=\"0\" class=\"update_status_text\">\n                <i class=\"fa fa-comments\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_text") : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.program(69, data, 0),"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":116,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invisible_mode") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":119,"column":8},"end":{"line":131,"column":15}}})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":20},"end":{"line":113,"column":45}}})) != null ? stack1 : "")
    + "\n";
},"67":function(container,depth0,helpers,partials,data) {
    return "Edit status";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":20},"end":{"line":115,"column":46}}})) != null ? stack1 : "")
    + "\n";
},"70":function(container,depth0,helpers,partials,data) {
    return "Set a status";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a tabindex=\"0\" class=\"invisible_mode_turn_off\">\n                <i class=\"fa fa-circle-o\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":66},"end":{"line":122,"column":103}}})) != null ? stack1 : "")
    + "\n            </a>\n        </li>\n";
},"73":function(container,depth0,helpers,partials,data) {
    return "Turn off invisible mode";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a tabindex=\"0\" class=\"invisible_mode_turn_on\">\n                <i class=\"fa fa-circle-o\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":66},"end":{"line":128,"column":92}}})) != null ? stack1 : "")
    + "\n            </a>\n        </li>\n";
},"76":function(container,depth0,helpers,partials,data) {
    return "Go invisible";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <hr />\n        <li>\n            <a tabindex=\"0\" class=\"view_full_user_profile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":139,"column":16},"end":{"line":143,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_send_private_message") : depth0),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":8},"end":{"line":152,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"unless","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":8},"end":{"line":169,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(103, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":8},"end":{"line":176,"column":15}}})) != null ? stack1 : "")
    + "        <hr />\n        <li>\n            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pm_with_url") || (depth0 != null ? lookupProperty(depth0,"pm_with_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pm_with_url","hash":{},"data":data,"loc":{"start":{"line":179,"column":21},"end":{"line":179,"column":38}}}) : helper)))
    + "\" class=\"narrow_to_private_messages\">\n                <i class=\"fa fa-envelope\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(106, data, 0),"inverse":container.program(109, data, 0),"data":data,"loc":{"start":{"line":181,"column":16},"end":{"line":185,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n        <li>\n            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sent_by_uri") || (depth0 != null ? lookupProperty(depth0,"sent_by_uri") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sent_by_uri","hash":{},"data":data,"loc":{"start":{"line":189,"column":21},"end":{"line":189,"column":38}}}) : helper)))
    + "\" class=\"narrow_to_messages_sent\">\n                <i class=\"fa fa-paper-plane\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(112, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":69},"end":{"line":190,"column":101}}})) != null ? stack1 : "")
    + "\n            </a>\n        </li>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-user\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":66},"end":{"line":140,"column":97}}})) != null ? stack1 : "")
    + "\n";
},"80":function(container,depth0,helpers,partials,data) {
    return "View your profile";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-user\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(83, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":66},"end":{"line":142,"column":92}}})) != null ? stack1 : "")
    + "\n";
},"83":function(container,depth0,helpers,partials,data) {
    return "View profile";
},"85":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a tabindex=\"0\" class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"private_message_class") || (depth0 != null ? lookupProperty(depth0,"private_message_class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"private_message_class","hash":{},"data":data,"loc":{"start":{"line":148,"column":39},"end":{"line":148,"column":66}}}) : helper)))
    + "\">\n                    <i class=\"fa fa-comment\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(86, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":69},"end":{"line":149,"column":103}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_sender_popover") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":104},"end":{"line":149,"column":172}}})) != null ? stack1 : "")
    + "\n                </a>\n            </li>\n";
},"86":function(container,depth0,helpers,partials,data) {
    return "Send private message";
},"88":function(container,depth0,helpers,partials,data) {
    return "<span class=\"hotkey-hint\">(R)</span>";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"has_message_context") : depth0),{"name":"if","hash":{},"fn":container.program(91, data, 0),"inverse":container.program(100, data, 0),"data":data,"loc":{"start":{"line":155,"column":12},"end":{"line":167,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n";
},"91":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a tabindex=\"0\" class=\"mention_user\">\n                <i class=\"fa fa-at\" aria-hidden=\"true\"></i>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":158,"column":16},"end":{"line":158,"column":114}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_sender_popover") : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":16},"end":{"line":159,"column":84}}})) != null ? stack1 : "")
    + "\n            </a>\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(93, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":30},"end":{"line":158,"column":64}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    return "Reply mentioning bot";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(96, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":72},"end":{"line":158,"column":107}}})) != null ? stack1 : "");
},"96":function(container,depth0,helpers,partials,data) {
    return "Reply mentioning user";
},"98":function(container,depth0,helpers,partials,data) {
    return "<span class=\"hotkey-hint\">(@)</span>";
},"100":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a tabindex=\"0\" class=\"copy_mention_syntax\" data-clipboard-text=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_mention_syntax") || (depth0 != null ? lookupProperty(depth0,"user_mention_syntax") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_mention_syntax","hash":{},"data":data,"loc":{"start":{"line":162,"column":77},"end":{"line":162,"column":102}}}) : helper)))
    + "\">\n                <i class=\"fa fa-at\" aria-hidden=\"true\"></i>\n                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(101, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":16},"end":{"line":164,"column":49}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_sender_popover") : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":16},"end":{"line":165,"column":84}}})) != null ? stack1 : "")
    + "\n            </a>\n";
},"101":function(container,depth0,helpers,partials,data) {
    return "Copy mention syntax";
},"103":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a href=\"/#settings/profile\">\n                <i class=\"fa fa-edit\" aria-hidden=\"true\"></i> "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(104, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":62},"end":{"line":173,"column":93}}})) != null ? stack1 : "")
    + "\n            </a>\n        </li>\n";
},"104":function(container,depth0,helpers,partials,data) {
    return "Edit your profile";
},"106":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(107, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":20},"end":{"line":182,"column":61}}})) != null ? stack1 : "")
    + "\n";
},"107":function(container,depth0,helpers,partials,data) {
    return "View messages with yourself";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(110, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":20},"end":{"line":184,"column":55}}})) != null ? stack1 : "")
    + "\n";
},"110":function(container,depth0,helpers,partials,data) {
    return "View private messages";
},"112":function(container,depth0,helpers,partials,data) {
    return "View messages sent";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list info_popover_actions\" id=\"user_info_popover\" data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_id") || (depth0 != null ? lookupProperty(depth0,"user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":2,"column":83},"end":{"line":2,"column":94}}}) : helper)))
    + "\">\n    <li class=\"popover_user_name_row\">\n        <b class=\"user_full_name\" data-tippy-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_full_name") || (depth0 != null ? lookupProperty(depth0,"user_full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_full_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":72}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"user_full_name") || (depth0 != null ? lookupProperty(depth0,"user_full_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_full_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":74},"end":{"line":4,"column":92}}}) : helper)))
    + "</b>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_manage_menu") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":51,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"display_profile_fields") : depth0),{"name":"each","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":74,"column":13}}})) != null ? stack1 : "")
    + "\n\n    <li class=\"only-visible-for-spectators\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":44},"end":{"line":77,"column":78}}})) != null ? stack1 : "")
    + "</li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"and").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_time") : depth0),(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":79,"column":14},"end":{"line":79,"column":39}}}),(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),(depth0 != null ? lookupProperty(depth0,"status_content_available") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":79,"column":10},"end":{"line":79,"column":71}}}),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":4},"end":{"line":81,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_time") : depth0),(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":82,"column":10},"end":{"line":82,"column":35}}}),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":84,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status_content_available") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":4},"end":{"line":106,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_me") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":4},"end":{"line":132,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"spectator_view") : depth0),{"name":"unless","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":4},"end":{"line":193,"column":15}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});