var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list\">\n    <li>\n        <a href=\"#streams/all\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Browse streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":35}}}))
    + "\n        </a>\n    </li>\n    <li>\n        <a href=\"#streams/new\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create a stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":36}}}))
    + "\n        </a>\n    </li>\n</ul>\n";
},"useData":true});