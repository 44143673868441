var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"button-group\">\n        <div class=\"sub_unsub_button_wrapper inline-block\">\n            <button class=\"button small rounded subscribe-button sub_unsub_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_subscription_button") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":82},"end":{"line":6,"column":150}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribed") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":151},"end":{"line":6,"column":197}}})) != null ? stack1 : "")
    + "\" type=\"button\" name=\"button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_subscription_button") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":227},"end":{"line":6,"column":356}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subscribed") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n        </div>\n        <a href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"preview_url") : depth0), depth0))
    + "\" class=\"button small rounded tippy-zulip-tooltip\" id=\"preview-stream-button\" role=\"button\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"View stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":144},"end":{"line":14,"column":163}}}))
    + " (V)\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"should_display_preview_button") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":169},"end":{"line":14,"column":243}}})) != null ? stack1 : "")
    + "><i class=\"fa fa-eye\"></i></a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_realm_admin") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "tippy-zulip-tooltip";
},"4":function(container,depth0,helpers,partials,data) {
    return "unsubscribed";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Toggle subscription",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":289},"end":{"line":6,"column":316}}}))
    + " (S)\" ";
},"8":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":45}}})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "Unsubscribe";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":43}}})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "Subscribe";
},"16":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button small rounded btn-danger deactivate tippy-zulip-tooltip\" type=\"button\" name=\"delete_button\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Archive stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":142},"end":{"line":16,"column":164}}}))
    + "\"> <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></button>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-settings-tip-container\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_settings_tip.hbs"),depth0,{"name":"stream_settings_tip","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"stream-header\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_privacy_icon.hbs"),depth0,{"name":"stream_privacy_icon","hash":{"is_web_public":(depth0 != null ? lookupProperty(depth0,"is_web_public") : depth0),"invite_only":(depth0 != null ? lookupProperty(depth0,"invite_only") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div class=\"stream-name\">\n                    <span class=\"sub-stream-name\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                </div>\n                <div class=\"stream_change_property_info alert-notification\"></div>\n                <div class=\"button-group\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"can_change_name_description") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":42},"end":{"line":37,"column":112}}})) != null ? stack1 : "")
    + ">\n                    <button id=\"open_stream_info_modal\" class=\"button rounded small btn-warning tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Change stream info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":137},"end":{"line":38,"column":164}}}))
    + "\">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"stream-description\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_description.hbs"),depth0,{"name":"stream_description","hash":{"rendered_description":(depth0 != null ? lookupProperty(depth0,"rendered_description") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"stream_setting_subsection_header\">\n                <h3 class=\"stream_setting_subsection_title\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Stream permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":47}}}))
    + "\n                </h3>\n                <div class=\"stream_permission_change_info alert-notification\"></div>\n                <div class=\"button-group\">\n                    <button class=\"change-stream-privacy button rounded small btn-warning tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Change stream permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":131},"end":{"line":54,"column":165}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"can_change_stream_permissions") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":167},"end":{"line":54,"column":239}}})) != null ? stack1 : "")
    + ">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"subscription-type\">\n                <div class=\"subscription-type-text\">\n                    "
    + ((stack1 = container.invokePartial(require("./stream_permission_description.hbs"),depth0,{"name":"stream_permission_description","hash":{"message_retention_text":(depths[1] != null ? lookupProperty(depths[1],"message_retention_text") : depths[1]),"stream_post_policy_values":(depths[1] != null ? lookupProperty(depths[1],"stream_post_policy_values") : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"23":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"25":function(container,depth0,helpers,partials,data) {
    return "Generate email address";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"input-group\">\n                            "
    + ((stack1 = container.invokePartial(require("./stream_settings_checkbox.hbs"),depth0,{"name":"stream_settings_checkbox","hash":{"label":(depth0 != null ? lookupProperty(depth0,"label") : depth0),"is_disabled":(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),"disabled_realm_setting":(depth0 != null ? lookupProperty(depth0,"disabled_realm_setting") : depth0),"notification_setting":false,"stream_id":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"sub") : depths[1]),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":96,"column":40},"end":{"line":96,"column":67}}}),"is_muted":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"sub") : depths[1]),"is_muted",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":95,"column":39},"end":{"line":95,"column":65}}}),"is_checked":(depth0 != null ? lookupProperty(depth0,"is_checked") : depth0),"setting_name":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"input-group\">\n                            "
    + ((stack1 = container.invokePartial(require("./stream_settings_checkbox.hbs"),depth0,{"name":"stream_settings_checkbox","hash":{"label":(depth0 != null ? lookupProperty(depth0,"label") : depth0),"is_disabled":(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),"disabled_realm_setting":(depth0 != null ? lookupProperty(depth0,"disabled_realm_setting") : depth0),"notification_setting":true,"stream_id":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"sub") : depths[1]),"stream_id",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":119,"column":40},"end":{"line":119,"column":67}}}),"is_muted":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"sub") : depths[1]),"is_muted",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":118,"column":39},"end":{"line":118,"column":65}}}),"is_checked":(depth0 != null ? lookupProperty(depth0,"is_checked") : depth0),"setting_name":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"edit_subscribers_for_stream\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_members.hbs"),depth0,{"name":"stream_members","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stream_settings_header\" data-stream-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\">\n    <div class=\"tab-container\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"sub") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":19,"column":13}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"subscription_settings\" data-stream-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\">\n    <div class=\"inner-box\">\n\n        <div class=\"general_settings stream_section\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"sub") : depth0),{"name":"with","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":66,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"stream-email-box\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"email_address") : stack1),{"name":"unless","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":42},"end":{"line":67,"column":104}}})) != null ? stack1 : "")
    + ">\n                <h3 class=\"stream_setting_subsection_title\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias3,"Email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":20},"end":{"line":69,"column":42}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/message-a-stream-by-email"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </h3>\n                <p>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias3,"You can use email to send messages to Zulip streams.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":73,"column":80}}}))
    + "\n                </p>\n                <p>\n                    <button class=\"button rounded copy_email_button\" type=\"button\" name=\"button\">\n                        <span class=\"copy_button\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias3,{"name":"tr","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":50},"end":{"line":77,"column":86}}})) != null ? stack1 : "")
    + "</span>\n                    </button>\n                </p>\n            </div>\n        </div>\n\n        <div id=\"personal-stream-settings\" class=\"personal_settings stream_section\">\n            <div class=\"subsection-header\">\n                <h3 class=\"stream_setting_subsection_title inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Personal settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":73},"end":{"line":85,"column":99}}}))
    + "</h3>\n                <div id=\"stream_change_property_status"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" class=\"stream_change_property_status alert-notification\"></div>\n            </div>\n            <div class=\"subscription-config\">\n                <div class=\"subsection-parent\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"other_settings") : depth0),{"name":"each","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":102,"column":29}}})) != null ? stack1 : "")
    + "\n                    <div class=\"input-group\">\n                        <label for=\"streamcolor\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Stream color",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":49},"end":{"line":105,"column":70}}}))
    + "</label>\n                        <span class=\"sub_setting_control\">\n                            <input stream_id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"stream_id") : stack1), depth0))
    + "\" class=\"colorpicker\" id=\"streamcolor\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sub") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" tabindex=\"-1\" />\n                        </span>\n                    </div>\n                </div>\n                <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Notification settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":60},"end":{"line":111,"column":90}}}))
    + "</h4>\n                <div class=\"subsection-parent\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":20},"end":{"line":125,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n\n        <div class=\"subscriber_settings stream_section\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"sub") : depth0),{"name":"with","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":12},"end":{"line":135,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});