var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"alert\" id=\"dev_env_msg\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"invite-method-choice\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"or",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":39}}}))
    + " <a role=\"button\" tabindex=\"0\" id=\"generate_multiuse_invite_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate invite link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":107},"end":{"line":20,"column":136}}}))
    + "</a>\n                        </div>\n                        <div id=\"multiuse_radio_section\">\n                            <label class=\"checkbox display-block\" for=\"generate_multiuse_invite_radio\">\n                                <input type=\"checkbox\" name=\"generate_multiuse_invite_radio\" id=\"generate_multiuse_invite_radio\"/>\n                                <span></span>\n                                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate invite link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":61}}}))
    + "\n                            </label>\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":40},"end":{"line":37,"column":76}}})) != null ? stack1 : "")
    + " name=\"expires_in\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                <option name=\"custom_time_choice\" class=\"custom_time_choice\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option name=\"invite_as\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"invite_as_options") : depth0)) != null ? lookupProperty(stack1,"moderator") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Moderators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":100},"end":{"line":62,"column":119}}}))
    + "</option>\n                            <option name=\"invite_as\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"invite_as_options") : depth0)) != null ? lookupProperty(stack1,"admin") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias3,"Organization administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":96},"end":{"line":63,"column":132}}}))
    + "</option>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option name=\"invite_as\" value=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"invite_as_options") : depth0)) != null ? lookupProperty(stack1,"owner") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Organization owners",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":96},"end":{"line":66,"column":124}}}))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"invite-user\" class=\"overlay flex new-style\" tabindex=\"-1\" role=\"dialog\" data-overlay=\"invite\"\n  aria-labelledby=\"invite-user-label\" aria-hidden=\"true\">\n    <div class=\"overlay-content modal-bg\">\n        <div class=\"modal-header\">\n            <button type=\"button\" class=\"exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":73}}}))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n            <h3 id=\"invite-user-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite users to Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":69}}}))
    + "</h3>\n        </div>\n        <form id=\"invite_user_form\" class=\"form-horizontal\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"csrf_input") : depth0), depth0))
    + "\n            <div class=\"modal-body\" data-simplebar data-simplebar-auto-hide=\"false\">\n                <div class=\"alert\" id=\"invite_status\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"development_environment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"input-group\">\n                    <label for=\"invitee_emails\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emails (one on each line or comma-separated)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":101}}}))
    + "</label>\n                    <div>\n                        <textarea rows=\"2\" id=\"invitee_emails\" name=\"invitee_emails\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"One or more email addresses...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":98},"end":{"line":17,"column":137}}}))
    + "\"></textarea>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":29,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"expires_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitation expires after",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":77}}}))
    + "</label>\n                    <div>\n                        <select id=\"expires_in\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"expires_in_options") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":38,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                    <p id=\"expires_on\"></p>\n                    <div id=\"custom-invite-expiration-time\" class=\"dependent-block\">\n                        <label for=\"expires_in\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":48},"end":{"line":43,"column":68}}}))
    + "</label>\n                        <input type=\"text\" autocomplete=\"off\" name=\"custom-expiration-time\" id=\"custom-expiration-time-input\" class=\"custom-expiration-time inline-block\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"time_input") : depth0), depth0))
    + "\" maxlength=\"3\"/>\n                        <select class=\"custom-expiration-time\" id=\"custom-expiration-time-unit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"time_choices") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":28},"end":{"line":48,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n                        <p id=\"custom_expires_on\"></p>\n                    </div>\n                </div>\n                <div class=\"input-group\">\n                    <label for=\"invite_as\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User(s) join as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":43},"end":{"line":54,"column":67}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <div>\n                        <select id=\"invite_as\">\n                            <option name=\"invite_as\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"invite_as_options") : depth0)) != null ? lookupProperty(stack1,"guest") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Guests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":96},"end":{"line":59,"column":111}}}))
    + "</option>\n                            <option name=\"invite_as\" selected=\"selected\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"invite_as_options") : depth0)) != null ? lookupProperty(stack1,"member") : stack1)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":117},"end":{"line":60,"column":133}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":64,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":28},"end":{"line":67,"column":35}}})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                </div>\n                <div>\n                    <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Streams they should join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":27},"end":{"line":72,"column":60}}}))
    + "</label>\n                    <div id=\"streams_to_add\"></div>\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n                <button class=\"button exit small rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":79},"end":{"line":77,"column":94}}}))
    + "</button>\n                <button id=\"submit-invitation\" class=\"button small rounded sea-green\" type=\"button\"\n                  data-loading-text=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inviting...",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":37},"end":{"line":79,"column":57}}}))
    + "\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":80,"column":35}}}))
    + "\n                </button>\n                <div class=\"alert\" id=\"multiuse_invite_status\"></div>\n            </div>\n        </form>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});