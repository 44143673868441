var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"3":function(container,depth0,helpers,partials,data) {
    return " include-sender";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"mention_classname") : depth0), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return " last_message";
},"9":function(container,depth0,helpers,partials,data) {
    return " unread";
},"11":function(container,depth0,helpers,partials,data) {
    return "local";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"date_row no-select\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"is_stream") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":167}}})) != null ? stack1 : "")
    + ">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"date_divider_html") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"box-shadow: inset 3px 0px 0px -1px "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"background_color") : depth0), depth0))
    + ", -1px 0px 0px 0px "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"background_color") : depth0), depth0))
    + ";\"";
},"16":function(container,depth0,helpers,partials,data) {
    return "next_is_same_sender";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=container.hooks.blockHelperMissing, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div zid=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"table_name") : depth0), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n  class=\"message_row"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"is_stream") : stack1), depth0),{"name":"msg/is_stream","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"include_sender") : depth0), depth0),{"name":"include_sender","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":125}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,(depth0 != null ? lookupProperty(depth0,"mention_classname") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":125},"end":{"line":2,"column":179}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"include_footer") : depth0), depth0),{"name":"include_footer","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":179},"end":{"line":2,"column":230}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"unread") : stack1), depth0),{"name":"msg.unread","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":230},"end":{"line":2,"column":267}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"locally_echoed") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":268},"end":{"line":2,"column":306}}})) != null ? stack1 : "")
    + " selectable_row\"\n  role=\"listitem\">\n    <div class=\"unread_marker\"><div class=\"unread-marker-fill\"></div></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,(depth0 != null ? lookupProperty(depth0,"want_date_divider") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"messagebox "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,(depth0 != null ? lookupProperty(depth0,"next_is_same_sender") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":80}}})) != null ? stack1 : "")
    + "\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"is_stream") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":137}}})) != null ? stack1 : "")
    + ">\n        <div class=\"messagebox-content\">\n            "
    + ((stack1 = container.invokePartial(require("./message_body.hbs"),depth0,{"name":"message_body","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});