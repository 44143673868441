var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(lookupProperty(helpers,"t").call(alias1,"Link:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":14}}}))
    + "\n<a href=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"invite_link") : depth0), depth0))
    + "\" id=\"multiuse_invite_link\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"invite_link") : depth0), depth0))
    + "</a>\n&nbsp;\n<a class=\"btn pull-right copy_button_base tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":83},"end":{"line":4,"column":101}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":115},"end":{"line":4,"column":133}}}))
    + "\"\n  id='copy_generated_invite_link' data-clipboard-text=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"invite_link") : depth0), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./copy_to_clipboard_svg.hbs"),depth0,{"name":"copy_to_clipboard_svg","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n";
},"usePartial":true,"useData":true});