var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"alert\" id=\"organization-status\"></div>\n<div id=\"revoke_invite_modal_holder\"></div>\n\n"
    + ((stack1 = container.invokePartial(require("./admin_settings_modals.hbs"),depth0,{"name":"admin_settings_modals","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./organization_profile_admin.hbs"),depth0,{"name":"organization_profile_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./organization_settings_admin.hbs"),depth0,{"name":"organization_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./organization_permissions_admin.hbs"),depth0,{"name":"organization_permissions_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./organization_user_settings_defaults.hbs"),depth0,{"name":"organization_user_settings_defaults","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./emoji_settings_admin.hbs"),depth0,{"name":"emoji_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./user_list_admin.hbs"),depth0,{"name":"user_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./deactivated_users_admin.hbs"),depth0,{"name":"deactivated_users_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./bot_list_admin.hbs"),depth0,{"name":"bot_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./default_streams_list_admin.hbs"),depth0,{"name":"default_streams_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./auth_methods_settings_admin.hbs"),depth0,{"name":"auth_methods_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./linkifier_settings_admin.hbs"),depth0,{"name":"linkifier_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./playground_settings_admin.hbs"),depth0,{"name":"playground_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./invites_list_admin.hbs"),depth0,{"name":"invites_list_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./user_groups_admin.hbs"),depth0,{"name":"user_groups_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./profile_field_settings_admin.hbs"),depth0,{"name":"profile_field_settings_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./data_exports_admin.hbs"),depth0,{"name":"data_exports_admin","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});