var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_header message_header_stream\">\n            <div class=\"message-header-contents\">\n                <div class=\"message_label_clickable stream_label "
    + alias4(((helper = (helper = lookupProperty(helpers,"dark_background") || (depth0 != null ? lookupProperty(depth0,"dark_background") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dark_background","hash":{},"data":data,"loc":{"start":{"line":6,"column":65},"end":{"line":6,"column":84}}}) : helper)))
    + "\"\n                  style=\"background: "
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_color") || (depth0 != null ? lookupProperty(depth0,"stream_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_color","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":53}}}) : helper)))
    + "; border-left-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_color") || (depth0 != null ? lookupProperty(depth0,"stream_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_color","hash":{},"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":90}}}) : helper)))
    + ";\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_name") || (depth0 != null ? lookupProperty(depth0,"stream_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_name","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":35}}}) : helper)))
    + "\n                </div>\n\n                <span class=\"stream_topic\">\n                    <div class=\"message_label_clickable narrows_by_topic\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":33}}}) : helper)))
    + "\n                    </div>\n                </span>\n                <span class=\"recipient_bar_controls\"></span>\n                <div class=\"recipient_row_date\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Last modified",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":76}}}))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"time_stamp") || (depth0 != null ? lookupProperty(depth0,"time_stamp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time_stamp","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":94}}}) : helper)))
    + "</div>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"message_header message_header_private_message dark_background\">\n            <div class=\"message-header-contents\">\n                <div class=\"message_label_clickable stream_label\">\n                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":54}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"recipient_row_date\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last modified",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":55},"end":{"line":26,"column":76}}}))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"time_stamp") || (depth0 != null ? lookupProperty(depth0,"time_stamp") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"time_stamp","hash":{},"data":data,"loc":{"start":{"line":26,"column":78},"end":{"line":26,"column":94}}}) : helper)))
    + "</div>\n            </div>\n\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "You and {recipients}";
},"6":function(container,depth0,helpers,partials,data) {
    return " private-message";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"draft-row\" data-draft-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"draft_id") || (depth0 != null ? lookupProperty(depth0,"draft_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":50}}}) : helper)))
    + "\">\n    <div class=\"draft-info-box\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_stream") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"message_row";
  stack1 = ((helper = (helper = lookupProperty(helpers,"is_stream") || (depth0 != null ? lookupProperty(depth0,"is_stream") : depth0)) != null ? helper : alias2),(options={"name":"is_stream","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":31,"column":31},"end":{"line":31,"column":75}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"is_stream")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" role=\"listitem\">\n            <div class=\"messagebox\">\n                <div class=\"messagebox-content\">\n                    <div class=\"message_top_line\">\n                        <div class=\"draft_controls\">\n                            <i class=\"fa fa-pencil fa-lg restore-draft tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Restore draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":131},"end":{"line":36,"column":153}}}))
    + " (Enter)\"></i>\n                            <i class=\"fa fa-trash-o fa-lg delete-draft tippy-zulip-tooltip\" aria-hidden=\"true\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":131},"end":{"line":37,"column":152}}}))
    + " (Backspace)\"></i>\n                        </div>\n                    </div>\n                    <div class=\"message_content rendered_markdown restore-draft\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Restore draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":88},"end":{"line":40,"column":110}}}))
    + "\">"
    + alias4(lookupProperty(helpers,"rendered_markdown").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":40,"column":112},"end":{"line":40,"column":141}}}))
    + "</div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});