var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "unread_topic";
},"3":function(container,depth0,helpers,partials,data) {
    return "private_conversation_row";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"fa fa-envelope\"></span>\n                <a href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"pm_url") : depth0), depth0))
    + "\">Private messages</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span id=\"stream_sidebar_privacy_swatch_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" class=\"stream-privacy filter-icon\" style=\"color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_color") : depth0), depth0))
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </span>\n                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"topic_url") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream") : depth0), depth0))
    + "</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"right_part\">\n                <span class=\"pm_status_icon "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_group") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":44},"end":{"line":18,"column":87}}})) != null ? stack1 : "")
    + "\" data-tippy-placement=\"top\" data-user-ids-string=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"user_ids_string") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_group") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "show-tooltip";
},"12":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"fa fa-group\"></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0), depth0))
    + " user_circle\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"pm_url") : depth0), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"rendered_pm_with") : depth0), depth0)) != null ? stack1 : "")
    + "</a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"topic_url") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"topic") : depth0), depth0))
    + "</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"recent_topic_actions\">\n                    <div class=\"recent_topics_focusable\">\n                        <span class=\"unread_count unread_count_pm "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"unread_count") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":66},"end":{"line":42,"column":114}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip on_hover_topic_read\" data-user-ids-string=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"user_ids_string") : depth0), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":219},"end":{"line":42,"column":240}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":281},"end":{"line":42,"column":302}}}))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"unread_count") : depth0), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"recent_topic_actions dummy_action_button\">\n                    <div class=\"recent_topics_focusable\">\n                        <i class=\"zulip-icon zulip-icon-mute on_hover_topic_unmute recipient_bar_icon\"></i>\n                    </div>\n                </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "unread_hidden";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"unread_mention_info "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"mention_in_unread") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":49},"end":{"line":52,"column":102}}})) != null ? stack1 : "")
    + "\">@</span>\n                <div class=\"recent_topic_actions\">\n                    <div class=\"recent_topics_focusable hidden-for-spectators\">\n                        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"unread_count") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":50},"end":{"line":55,"column":98}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip on_hover_topic_read\" data-stream-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" data-topic-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"topic") : depth0), depth0))
    + "\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":219},"end":{"line":55,"column":240}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":281},"end":{"line":55,"column":302}}}))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"unread_count") : depth0), depth0))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"recent_topic_actions\">\n                    <div class=\"recent_topics_focusable hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_muted") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":60,"column":24},"end":{"line":64,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-mute on_hover_topic_unmute recipient_bar_icon tippy-zulip-tooltip\" data-stream-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" data-topic-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"topic") : depth0), depth0))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":202},"end":{"line":61,"column":223}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":264},"end":{"line":61,"column":285}}}))
    + "\"></i>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"zulip-icon zulip-icon-mute on_hover_topic_mute recipient_bar_icon tippy-zulip-tooltip\" data-stream-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" data-topic-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"topic") : depth0), depth0))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":200},"end":{"line":63,"column":219}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":260},"end":{"line":63,"column":279}}}))
    + "\"></i>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"recent_topics_participant_item tippy-zulip-tooltip\" data-tooltip-template-id=\"recent_topics_participant_overflow_tooltip:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conversation_key") : depth0), depth0))
    + "\">\n                <span class=\"recent_topics_participant_overflow\">+"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"other_senders_count") : depth0), depth0))
    + "</span>\n            </li>\n            <template id=\"recent_topics_participant_overflow_tooltip:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conversation_key") : depth0), depth0))
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"other_sender_names_html") : depth0), depth0)) != null ? stack1 : "")
    + "</template>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":88,"column":23}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"recent_topics_participant_item participant_profile tippy-zulip-tooltip\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Muted user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":119},"end":{"line":81,"column":137}}}))
    + "\" data-user-id=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\">\n                    <span><i class=\"fa fa-user recent_topics_participant_overflow\"></i></span>\n                </li>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"recent_topics_participant_item participant_profile tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"full_name") : depth0), depth0))
    + "\" data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\">\n                    <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"avatar_url_small") : depth0), depth0))
    + "\" class=\"recent_topics_participant_avatar\" />\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr id=\"recent_conversation:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"conversation_key") : depth0), depth0))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"unread_count") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":96}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":97},"end":{"line":1,"column":146}}})) != null ? stack1 : "")
    + "\" data-unread-count=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"unread_count") : depth0), depth0))
    + "\" data-muted=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"muted") : depth0), depth0))
    + "\" data-participated=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"participated") : depth0), depth0))
    + "\" data-private=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"is_private") : depth0), depth0))
    + "\">\n    <td class=\"recent_topic_stream\">\n        <div class=\"flex_container flex_container_pm\">\n            <div class=\"left_part recent_topics_focusable\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </td>\n    <td class=\"recent_topic_name\">\n        <div class=\"flex_container\">\n            <div class=\"left_part recent_topics_focusable line_clamp\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":36,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"right_part\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":67,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </td>\n    <td class='recent_topic_users'>\n        <ul class=\"recent_topics_participants\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"other_senders_count") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":78,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"senders") : depth0),{"name":"each","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":12},"end":{"line":89,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </td>\n    <td class=\"recent_topic_timestamp\">\n        <div class=\"last_msg_time tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"full_last_msg_date_time") : depth0), depth0))
    + "\">\n            <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"last_msg_url") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"last_msg_time") : depth0), depth0))
    + "</a>\n        </div>\n    </td>\n</tr>\n";
},"usePartial":true,"useData":true});