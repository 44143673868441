var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Configure the <z-link>default personal preference settings</z-link>\n            for new users joining your organization.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/configure-default-new-user-settings\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"realm-user-default-settings\" class=\"settings-section\" data-name=\"organization-level-user-defaults\">\n    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    "
    + ((stack1 = container.invokePartial(require("./display_settings.hbs"),depth0,{"name":"display_settings","hash":{"full_name":(depth0 != null ? lookupProperty(depth0,"full_name") : depth0),"for_realm_settings":true,"prefix":"realm_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./notification_settings.hbs"),depth0,{"name":"notification_settings","hash":{"for_realm_settings":true,"prefix":"realm_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"form-horizontal privacy_settings org-subsection-parent\">\n        <div class=\"subsection-header inline-block\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Privacy settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":62}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":false,"section_name":"privacy-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","help_link":"/help/status-and-availability","label_parens_text":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_presence_enabled_parens_text") : stack1),"label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_presence_enabled") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"presence_enabled") : stack1),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"help_link":"/help/read-receipts","prefix":"realm_","label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_send_read_receipts") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"send_read_receipts") : stack1),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"form-horizontal other_settings org-subsection-parent\">\n        <div class=\"subsection-header inline-block\">\n            <h3 class=\"inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":37},"end":{"line":35,"column":60}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":false,"section_name":"other-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":"realm_","label":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"realm_enter_sends") : stack1),"is_checked":((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"enter_sends") : stack1),"setting_name":"enter_sends"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":163}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});