var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"add-user-group-form\">\n    <div>\n        <label for=\"user_group_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":50}}}))
    + "</label>\n        <input type=\"text\" name=\"name\" id=\"user_group_name\" maxlength=\"100\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"marketing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":89},"end":{"line":4,"column":107}}}))
    + "\" />\n    </div>\n    <div>\n        <label for=\"user_group_description\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":64}}}))
    + "</label>\n        <input type=\"text\" name=\"description\" id=\"user_group_description\" maxlength=\"300\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Marketing team",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":103},"end":{"line":8,"column":126}}}))
    + "\" />\n    </div>\n</form>\n";
},"useData":true});