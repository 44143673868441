var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p id=\"mark_as_read_turned_off_content\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"To preserve your reading state, this view does not mark messages as read.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":86}}}))
    + "\n</p>\n<div id=\"mark_as_read_controls\">\n    <button id=\"mark_view_read\" class=\"btn btn-warning\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":84}}}))
    + "\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":29}}}))
    + "\n    </button>\n</div>\n<button type=\"button\" id=\"mark_as_read_close\" class=\"close\">×</button>\n";
},"useData":true});