var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-group white-box\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":44}}}) : helper)))
    + "\">\n    <div class=\"alert user-group-status\"></div>\n    <h4>\n        <span class=\"name\" data-placeholder=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":58}}}))
    + "\" contenteditable=\"true\" spellcheck=\"false\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":102},"end":{"line":5,"column":110}}}) : helper)))
    + "</span>\n        <span class=\"spacer\">—</span>\n        <span class=\"description\" data-placeholder=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":52},"end":{"line":7,"column":72}}}))
    + "\" contenteditable=\"true\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":7,"column":97},"end":{"line":7,"column":112}}}) : helper)))
    + "</span>\n        <button class=\"button save-status sea-green small\">\n            <img class=\"checkmark\" src=\"/static/images/checkbox-green.svg\" />\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Saved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":26}}}))
    + "\n        </button>\n        <button class=\"button save-status btn-danger small\">\n            <i class=\"fa fa-undo\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":46},"end":{"line":13,"column":61}}}))
    + "\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":70},"end":{"line":13,"column":85}}}))
    + "\"></i>\n        </button>\n        <button class=\"button rounded small delete btn-danger\">\n            <i class=\"fa fa-trash-o\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":64}}}))
    + "\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":73},"end":{"line":16,"column":88}}}))
    + "\"></i>\n        </button>\n    </h4>\n    <p class=\"subscribers\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":27},"end":{"line":19,"column":47}}}))
    + "</p>\n    <div class=\"pill-container\" data-group-pills=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":56}}}) : helper)))
    + "\">\n        <div class=\"input\" contenteditable=\"true\" data-placeholder=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Add member…",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":68},"end":{"line":21,"column":88}}}))
    + "\"></div>\n    </div>\n    <p class=\"save-instructions\">\n        "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Click outside the input box to save. We'll automatically notify anyone that was added or removed.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":113}}}))
    + "\n    </p>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"user_group") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":27,"column":9}}})) != null ? stack1 : "");
},"useData":true});