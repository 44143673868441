var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"create_user_group_button animated-purple-button\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Create user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":98},"end":{"line":28,"column":124}}}))
    + "</button>\n                    <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":33,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            First time? Read our <z-link>guidelines</z-link> for creating user groups.\n                            \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/user-groups\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"groups_overlay\" class=\"overlay new-style\" data-overlay=\"group_subscriptions\">\n    <div class=\"flex overlay-content\">\n        <div class=\"user-groups-container modal-bg\">\n            <div class=\"user-groups-header\">\n                <div class=\"fa fa-chevron-left\"></div>\n                <span class=\"user-groups-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":68}}}))
    + "</span>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"left\">\n                <div class=\"input-append group_name_search_section\" id=\"group_filter\">\n                    <input type=\"text\" name=\"group_name\" id=\"search_group_name\" autocomplete=\"off\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":57}}}))
    + "\" value=\"\"/>\n                    <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_group_name\">\n                        <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n                <div class=\"user-groups-list\" data-simplebar>\n                </div>\n            </div>\n            <div class=\"right\">\n                <div class=\"display-type\">\n                    <div id=\"user_group_settings_title\" class=\"user-group-info-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":86},"end":{"line":24,"column":114}}}))
    + "</div>\n                </div>\n                <div class=\"nothing-selected\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_create_or_edit_user_groups") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div id=\"user_group_settings\" class=\"settings\" data-simplebar data-simplebar-auto-hide=\"false\">\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./user_group_creation_form.hbs"),depth0,{"name":"user_group_creation_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":154}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});