var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            Configure regular expression patterns that will be\n            automatically linkified when used in Zulip message bodies or\n            topics.  For example to automatically linkify commit IDs and\n            issue numbers (e.g. #123) to the corresponding items in a GitHub\n            project, you could use the following:\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Or, to automatically linkify GitHub's <code>org/repo#1234</code> syntax:\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                More details are available <z-link>in the Help Center article</z-link>.\n                \n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/add-a-custom-linkifier\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form class=\"form-horizontal admin-linkifier-form\">\n            <div class=\"add-new-linkifier-box grey-box\">\n                <div class=\"new-linkifier-form wrapper\">\n                    <div class=\"settings-section-title new-linkifier-section-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":84},"end":{"line":48,"column":112}}}))
    + "</div>\n                    <div class=\"alert\" id=\"admin-linkifier-status\"></div>\n                    <div class=\"input-group\">\n                        <label for=\"linkifier_pattern\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":55},"end":{"line":51,"column":71}}}))
    + "</label>\n                        <input type=\"text\" id=\"linkifier_pattern\" name=\"pattern\" placeholder=\"#(?P<id>[0-9]+)\" />\n                        <div class=\"alert\" id=\"admin-linkifier-pattern-status\"></div>\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"linkifier_format_string\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL format string",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":61},"end":{"line":56,"column":87}}}))
    + "</label>\n                        <input type=\"text\" id=\"linkifier_format_string\" name=\"url_format_string\" placeholder=\"https://github.com/zulip/zulip/issues/%(id)s\" />\n                        <div class=\"alert\" id=\"admin-linkifier-format-status\"></div>\n                    </div>\n                    <button type=\"submit\" class=\"button rounded sea-green\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":46}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </form>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":40},"end":{"line":80,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"linkifier-settings\" class=\"settings-section\" data-name=\"linkifier-settings\">\n    <div class=\"admin-table-wrapper\">\n\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <ul>\n            <li>\n                <code>#(?P&lt;id&gt;[0-9]+)</code>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":28}}}))
    + "\n                <code>https://github.com/zulip/zulip/issues/%(id)s</code>\n            </li>\n            <li>\n                <code>(?P&lt;id&gt;[0-9a-f]{40})</code>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":28}}}))
    + "\n                <code>https://github.com/zulip/zulip/commit/%(id)s</code>\n            </li>\n        </ul>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <ul>\n            <li>\n                <code>(?P&lt;org&gt;[a-zA-Z0-9_-]+)/(?P&lt;repo&gt;[a-zA-Z0-9_-]+)#(?P&lt;id&gt;[0-9]+)</code>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":28}}}))
    + "\n                <code>https://github.com/%(org)s/%(repo)s/issues/%(id)s</code>\n            </li>\n        </ul>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":16},"end":{"line":69,"column":34}}}))
    + "</h3>\n            <div class=\"alert-notification edit-linkifier-status\" id=\"linkifier-field-status\"></div>\n            <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":59},"end":{"line":71,"column":85}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":99},"end":{"line":71,"column":125}}}))
    + "\"/>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar>\n            <table class=\"table table-condensed table-striped wrapped-table admin_linkifiers_table\">\n                <thead class=\"table-sticky-headers\">\n                    <th class=\"active\" data-sort=\"pattern\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":59},"end":{"line":77,"column":75}}}))
    + "</th>\n                    <th data-sort=\"url\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL format string",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":40},"end":{"line":78,"column":66}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":81,"column":27}}})) != null ? stack1 : "")
    + "                </thead>\n                <tbody id=\"admin_linkifiers_table\" class=\"required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No linkifiers set.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":85},"end":{"line":83,"column":112}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"5_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":40,"column":153}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});