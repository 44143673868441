var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stream-row\" data-stream-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" data-stream-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n\n    <div class=\"check "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subscribed") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":55}}})) != null ? stack1 : "")
    + " sub_unsub_button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"should_display_subscription_button") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":138}}})) != null ? stack1 : "")
    + "\">\n        <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"100%\" height=\"100%\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512;\" xml:space=\"preserve\">\n            <path d=\"M448,71.9c-17.3-13.4-41.5-9.3-54.1,9.1L214,344.2l-99.1-107.3c-14.6-16.6-39.1-17.4-54.7-1.8 c-15.6,15.5-16.4,41.6-1.7,58.1c0,0,120.4,133.6,137.7,147c17.3,13.4,41.5,9.3,54.1-9.1l206.3-301.7 C469.2,110.9,465.3,85.2,448,71.9z\"/>\n        </svg>\n        <div class='sub_unsub_status'></div>\n    </div>\n    "
    + ((stack1 = container.invokePartial(require("./subscription_setting_icon.hbs"),depth0,{"name":"subscription_setting_icon","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"sub-info-box\">\n        <div class=\"top-bar\">\n            <div class=\"stream-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n            <div class=\"subscriber-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Subscriber count",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":82},"end":{"line":15,"column":107}}}))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./subscriber_count.hbs"),depth0,{"name":"subscriber_count","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"bottom-bar\">\n            <div class=\"description rendered_markdown\" data-no-description=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"No description.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":99}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"rendered_markdown").call(alias3,(depth0 != null ? lookupProperty(depth0,"rendered_description") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":20,"column":101},"end":{"line":20,"column":143}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_old_stream") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-message-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Estimated messages per week",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":86},"end":{"line":22,"column":122}}}))
    + "\">\n                <i class=\"fa fa-bar-chart\"></i>\n                <span class=\"stream-message-count-text\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"stream_weekly_traffic") : depth0), depth0))
    + "</span>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"stream-message-count tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream created recently",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":86},"end":{"line":27,"column":118}}}))
    + "\">\n                <span class=\"stream-message-count-text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":68}}}))
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":34,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});