var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_header message_header_stream right_part\">\n    <div class=\"message-header-wrapper\">\n        <div class=\"message-header-contents\">\n            <a class=\"message_label_clickable narrows_by_recipient stream_label "
    + alias4(((helper = (helper = lookupProperty(helpers,"color_class") || (depth0 != null ? lookupProperty(depth0,"color_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color_class","hash":{},"data":data,"loc":{"start":{"line":6,"column":80},"end":{"line":6,"column":95}}}) : helper)))
    + "\"\n              style=\"background: "
    + alias4(((helper = (helper = lookupProperty(helpers,"background_color") || (depth0 != null ? lookupProperty(depth0,"background_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"background_color","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":53}}}) : helper)))
    + "; border-left-color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"background_color") || (depth0 != null ? lookupProperty(depth0,"background_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"background_color","hash":{},"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":94}}}) : helper)))
    + ";\"\n              href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_url") || (depth0 != null ? lookupProperty(depth0,"stream_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_url","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":34}}}) : helper)))
    + "\"\n              title=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":21},"end":{"line":9,"column":83}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invite_only") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_web_public") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"display_recipient") || (depth0 != null ? lookupProperty(depth0,"display_recipient") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"display_recipient","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":39}}}) : helper)))
    + "</a>\n\n            <span class=\"copy-paste-text\">&gt;</span>\n\n            <span class=\"stream_topic\">\n                <a class=\"message_label_clickable narrows_by_topic\"\n                  href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_url") || (depth0 != null ? lookupProperty(depth0,"topic_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_url","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":37}}}) : helper)))
    + "\"\n                  title=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":25},"end":{"line":31,"column":114}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"use_match_properties") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </span>\n            <span class=\"recipient_bar_controls no-select\">\n                <span class=\"topic_edit hidden-for-spectators\">\n                    <span class=\"topic_edit_form\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":54},"end":{"line":41,"column":60}}}) : helper)))
    + "\"></span>\n                </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_links") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":49,"column":25}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"always_visible_topic_edit") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_can_resolve_topic") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_muted") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":70,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n            <span class=\"recipient_row_date "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"group_date_divider_html") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":72,"column":44},"end":{"line":72,"column":99}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":72,"column":101},"end":{"line":72,"column":111}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Narrow to stream &quot;{display_recipient}&quot;";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fa fa-lock recipient-row-stream-icon\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a private stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":75},"end":{"line":12,"column":108}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a private stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":122},"end":{"line":12,"column":155}}}))
    + "\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"zulip-icon zulip-icon-globe recipient-row-stream-icon\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a web-public stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":92},"end":{"line":16,"column":128}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is a web-public stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":142},"end":{"line":16,"column":178}}}))
    + "\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "Narrow to stream &quot;{display_recipient}&quot;, topic &quot;{topic}&quot;";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"match_topic") || (depth0 != null ? lookupProperty(depth0,"match_topic") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"match_topic","hash":{},"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":33,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":33}}}) : helper)))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"no-underline\">\n                        <i class=\"fa fa-external-link-square recipient_bar_icon\" data-tippy-content=\"Open "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"External link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":133},"end":{"line":47,"column":155}}}))
    + "\"></i>\n                    </a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-pencil always_visible_topic_edit recipient_bar_icon hidden-for-spectators\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":111},"end":{"line":53,"column":182}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":203},"end":{"line":53,"column":221}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":262},"end":{"line":53,"column":281}}}))
    + "\"></i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none\"";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"on_hover_topic_edit") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":56,"column":16}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"fa fa-pencil on_hover_topic_edit recipient_bar_icon hidden-for-spectators\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_allow_message_editing") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":105},"end":{"line":55,"column":176}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":197},"end":{"line":55,"column":215}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":256},"end":{"line":55,"column":275}}}))
    + "\"></i>\n                ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"topic_is_resolved") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":63,"column":27}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"fa fa-check on_hover_topic_unresolve recipient_bar_icon hidden-for-spectators\" data-topic-name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":60,"column":130},"end":{"line":60,"column":139}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":161},"end":{"line":60,"column":188}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":229},"end":{"line":60,"column":256}}}))
    + "\"></i>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"fa fa-check on_hover_topic_resolve recipient_bar_icon hidden-for-spectators\" data-topic-name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":62,"column":128},"end":{"line":62,"column":137}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":159},"end":{"line":62,"column":184}}}))
    + "\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":225},"end":{"line":62,"column":250}}}))
    + "\"></i>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"zulip-icon zulip-icon-mute on_hover_topic_unmute recipient_bar_icon\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":67,"column":115},"end":{"line":67,"column":128}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":67,"column":147},"end":{"line":67,"column":156}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":178},"end":{"line":67,"column":199}}}))
    + " (M)\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":244},"end":{"line":67,"column":265}}}))
    + "\"></i>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"zulip-icon zulip-icon-mute on_hover_topic_mute recipient_bar_icon hidden-for-spectators\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":69,"column":135},"end":{"line":69,"column":148}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":69,"column":167},"end":{"line":69,"column":176}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":198},"end":{"line":69,"column":217}}}))
    + " (M)\" role=\"button\" tabindex=\"0\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":262},"end":{"line":69,"column":281}}}))
    + "\"></i>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return "";
},"33":function(container,depth0,helpers,partials,data) {
    return "hide-date";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_header message_header_private_message dark_background\">\n    <div class=\"message-header-wrapper\">\n        <div class=\"message-header-contents\">\n            <a class=\"message_label_clickable narrows_by_recipient stream_label\"\n              href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pm_with_url") || (depth0 != null ? lookupProperty(depth0,"pm_with_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pm_with_url","hash":{},"data":data,"loc":{"start":{"line":81,"column":20},"end":{"line":81,"column":35}}}) : helper)))
    + "\"\n              title=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":21},"end":{"line":82,"column":90}}})) != null ? stack1 : "")
    + "\">\n                "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":83,"column":56}}})) != null ? stack1 : "")
    + "\n            </a>\n\n            <span class=\"recipient_row_date "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"group_date_divider_html") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":86,"column":44},"end":{"line":86,"column":99}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":86,"column":101},"end":{"line":86,"column":111}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n    </div>\n</div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "Narrow to your private messages with {display_reply_to}";
},"38":function(container,depth0,helpers,partials,data) {
    return "You and {display_reply_to}";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_stream") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":90,"column":7}}})) != null ? stack1 : "");
},"useData":true});