var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"your-bots\">\n                        <i class=\"icon fa fa-github\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":42},"end":{"line":34,"column":55}}}))
    + "</div>\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"uploaded-files\">\n                        <i class=\"icon fa fa-paperclip\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Uploaded files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":65}}}))
    + "</div>\n                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"locked fa fa-lock\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":60},"end":{"line":62,"column":126}}}))
    + "\"></i>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "hide-org-settings";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"locked fa fa-lock\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization administrators can edit these settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":60},"end":{"line":69,"column":125}}}))
    + "\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\"locked fa fa-lock\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Guest users cannot edit custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":64},"end":{"line":83,"column":110}}}))
    + "\"></i>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_emoji_settings_lock") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":24},"end":{"line":86,"column":24}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\"locked fa fa-lock\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization administrators can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":64},"end":{"line":85,"column":129}}}))
    + "\"></i>\n                        ";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"user-groups-admin\">\n                        <i class=\"icon fa fa-group\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"User groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":42},"end":{"line":105,"column":62}}}))
    + "</div>\n                    </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"user-list-admin\">\n                        <i class=\"icon fa fa-user\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":42},"end":{"line":111,"column":56}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":24},"end":{"line":114,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"deactivated-users-admin\">\n                        <i class=\"icon fa fa-user-times\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Deactivated users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":42},"end":{"line":120,"column":68}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":123,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"bot-list-admin\">\n                        <i class=\"icon fa fa-github\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Bots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":42},"end":{"line":129,"column":55}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":24},"end":{"line":132,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"invites-list-admin\">\n                        <i class=\"icon fa fa-user-plus\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invitations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":42},"end":{"line":138,"column":62}}}))
    + "</div>\n                    </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"profile-field-settings\">\n                        <i class=\"icon fa fa-id-card\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Custom profile fields",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":42},"end":{"line":144,"column":72}}}))
    + "</div>\n                    </li>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":53},"end":{"line":155,"column":101}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"default-streams-list\">\n                        <i class=\"icon fa fa-exchange\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Default streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":42},"end":{"line":157,"column":66}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":24},"end":{"line":160,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"locked fa fa-lock\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization owners can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":167,"column":60},"end":{"line":167,"column":118}}}))
    + "\"></i>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li tabindex=\"0\" data-section=\"data-exports-admin\">\n                        <i class=\"icon fa fa-database\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Data exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":173,"column":42},"end":{"line":173,"column":63}}}))
    + "</div>\n                    </li>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"collapse-settings-btn\">\n                        <i id='toggle_collapse_chevron' class='fa fa-angle-double-down'></i>\n                        <p id='toggle_collapse'>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Show more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":48},"end":{"line":179,"column":66}}}))
    + "</p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"settings_page\" class=\"new-style overlay-content modal-bg\">\n    <div class=\"settings-header mobile\">\n        <i class=\"fa fa-chevron-left\" aria-hidden=\"true\"></i>\n        <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":29}}}))
    + "<span class=\"section\"></span></h1>\n        <div class=\"exit\">\n            <span class=\"exit-sign\">&times;</span>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div class=\"sidebar-wrapper\">\n        <div class=\"center tab-container settings-sticky-bar\"></div>\n        <div class=\"sidebar left\" data-simplebar>\n            <div class=\"sidebar-list dark-grey small-text\">\n                <ul class=\"normal-settings-list\">\n                    <li tabindex=\"0\" data-section=\"profile\">\n                        <i class=\"icon fa fa-user\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":58}}}))
    + "</div>\n                    </li>\n                    <li tabindex=\"0\" data-section=\"account-and-privacy\">\n                        <i class=\"icon fa fa-lock\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account & privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":68}}}))
    + "</div>\n                    </li>\n                    <li tabindex=\"0\" data-section=\"display-settings\">\n                        <i class=\"icon fa fa-clock-o\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Display settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":67}}}))
    + "</div>\n                    </li>\n                    <li tabindex=\"0\" data-section=\"notifications\">\n                        <i class=\"icon fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":64}}}))
    + "</div>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + "                    <li tabindex=\"0\" data-section=\"alert-words\">\n                        <i class=\"icon fa fa-book\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Alert words",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":62}}}))
    + "</div>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_uploaded_files_section") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + "                    <li tabindex=\"0\" data-section=\"muted-topics\">\n                        <i class=\"icon zulip-icon zulip-icon-mute\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Muted topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":42},"end":{"line":49,"column":63}}}))
    + "</div>\n                    </li>\n                    <li tabindex=\"0\" data-section=\"muted-users\">\n                        <i class=\"icon fa fa-eye-slash\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Muted users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":42},"end":{"line":53,"column":62}}}))
    + "</div>\n                    </li>\n                </ul>\n\n                <ul class=\"org-settings-list\">\n                    <li tabindex=\"0\" data-section=\"organization-profile\">\n                        <i class=\"icon fa fa-id-card\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":71}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":63,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n                    <li class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":53},"end":{"line":65,"column":101}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-settings\">\n                        <i class=\"icon fa fa-sliders\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":42},"end":{"line":67,"column":72}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":70,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n                    <li class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":53},"end":{"line":72,"column":101}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-permissions\">\n                        <i class=\"icon fa fa-lock\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":42},"end":{"line":74,"column":75}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":77,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n                    <li tabindex=\"0\" data-section=\"emoji-settings\">\n                        <i class=\"icon fa fa-smile-o\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":42},"end":{"line":81,"column":63}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":86,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n                    <li tabindex=\"0\" data-section=\"linkifier-settings\">\n                        <i class=\"icon fa fa-font\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":42},"end":{"line":90,"column":61}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":24},"end":{"line":93,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n                    <li tabindex=\"0\" data-section=\"playground-settings\">\n                        <i class=\"icon fa fa-external-link\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":42},"end":{"line":97,"column":67}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":100,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":20},"end":{"line":107,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":20},"end":{"line":116,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":20},"end":{"line":125,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":20},"end":{"line":134,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":20},"end":{"line":140,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":146,"column":27}}})) != null ? stack1 : "")
    + "                    <li class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":53},"end":{"line":147,"column":101}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"organization-level-user-defaults\">\n                        <i class=\"icon fa fa-cog\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default user settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":149,"column":42},"end":{"line":149,"column":72}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":24},"end":{"line":152,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":20},"end":{"line":162,"column":31}}})) != null ? stack1 : "")
    + "                    <li class=\"collapse-org-settings "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":53},"end":{"line":163,"column":101}}})) != null ? stack1 : "")
    + "\" tabindex=\"0\" data-section=\"auth-methods\">\n                        <i class=\"icon fa fa-key\" aria-hidden=\"true\"></i>\n                        <div class=\"text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Authentication methods",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":42},"end":{"line":165,"column":73}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":24},"end":{"line":168,"column":35}}})) != null ? stack1 : "")
    + "                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":20},"end":{"line":175,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":20},"end":{"line":181,"column":31}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n        </div>\n    </div>\n    <div class=\"content-wrapper right\">\n        <div class=\"settings-header\">\n            <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":188,"column":16},"end":{"line":188,"column":33}}}))
    + "<span class=\"section\"></span></h1>\n            <div class=\"exit\">\n                <span class=\"exit-sign\">&times;</span>\n            </div>\n        </div>\n        <div id=\"settings_content\" data-simplebar data-simplebar-auto-hide=\"false\">\n            <div class=\"organization-box organization\">\n\n            </div>\n            <div class=\"settings-box\">\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});