var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "user_sidebar_entry_me ";
},"3":function(container,depth0,helpers,partials,data) {
    return " user-with-count ";
},"5":function(container,depth0,helpers,partials,data) {
    return " user-fade ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"user-name-and-status-wrapper\">\n                    <div class=\"user-name-and-status-emoji\">\n                        <span class=\"user-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                        "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),(depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"status-text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status_text") : depth0), depth0))
    + "</span>\n                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"user-name\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),(depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"num_unread") : depth0), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\" class=\"user_sidebar_entry "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_current_user") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":109}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"num_unread") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":109},"end":{"line":1,"column":151}}})) != null ? stack1 : "")
    + " narrow-filter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"faded") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":166},"end":{"line":1,"column":197}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"selectable_sidebar_block\">\n        <span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0), depth0))
    + " user_circle\"></span>\n        <a class=\"user-presence-link\"\n          href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"href") : depth0), depth0))
    + "\"\n          data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"user_id") : depth0), depth0))
    + "\"\n          data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_list_style") : depth0)) != null ? lookupProperty(stack1,"WITH_STATUS") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n        <span class=\"unread_count\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"num_unread") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":35},"end":{"line":21,"column":74}}})) != null ? stack1 : "")
    + "</span>\n    </div>\n    <span class=\"user-list-sidebar-menu-icon\"><i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i></span>\n</li>\n";
},"usePartial":true,"useData":true});