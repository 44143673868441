var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " sender-status-controls";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"edit_content message_control_button\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"reaction_button message_control_button\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":76},"end":{"line":7,"column":103}}}))
    + " (:)\">\n        <i class=\"fa fa-smile-o\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":77}}})) != null ? stack1 : "")
    + " (:)\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\"></i>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Add emoji reaction";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"actions_hover message_control_button\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":74},"end":{"line":13,"column":103}}})) != null ? stack1 : "")
    + " (i)\" >\n        <i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" role=\"button\" aria-haspopup=\"true\" tabindex=\"0\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":118},"end":{"line":14,"column":147}}})) != null ? stack1 : "")
    + "\"></i>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "Message actions";
},"11":function(container,depth0,helpers,partials,data) {
    return "notvisible";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"star_container message_control_button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"starred") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":98}}})) != null ? stack1 : "")
    + "\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"msg") : depth0),{"name":"with","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":120},"end":{"line":29,"column":185}}})) != null ? stack1 : "")
    + " (Ctrl + s)\">\n        <i role=\"button\" tabindex=\"0\" class=\"star fa "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"starred") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":103}}})) != null ? stack1 : "")
    + "\"></i>\n    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "";
},"16":function(container,depth0,helpers,partials,data) {
    return "empty-star";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":133},"end":{"line":29,"column":176}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "{starred_status} this message";
},"21":function(container,depth0,helpers,partials,data) {
    return "fa-star";
},"23":function(container,depth0,helpers,partials,data) {
    return "fa-star-o";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"message_controls";
  stack1 = ((helper = (helper = lookupProperty(helpers,"status_message") || (depth0 != null ? lookupProperty(depth0,"status_message") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"status_message","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":89}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"status_message")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " no-select\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"sent_by_me") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"sent_by_me") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"locally_echoed") : stack1),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"message_failed "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"failed_request") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":31},"end":{"line":18,"column":82}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"message_control_button failed_message_action\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":86},"end":{"line":19,"column":100}}}))
    + "\">\n            <i class=\"fa fa-refresh refresh-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":72},"end":{"line":20,"column":86}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n        </div>\n\n        <div class=\"message_control_button failed_message_action\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":86},"end":{"line":23,"column":101}}}))
    + "\">\n            <i class=\"fa fa-times-circle remove-failed-message\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":76},"end":{"line":24,"column":91}}}))
    + "\" role=\"button\" tabindex=\"0\"></i>\n        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"locally_echoed") : stack1),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});