var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"org-info org-version\">\n                    <a href=\"#about-zulip\" role=\"menuitem\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"version_display_string") || (depth0 != null ? lookupProperty(depth0,"version_display_string") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"version_display_string","hash":{},"data":data,"loc":{"start":{"line":15,"column":59},"end":{"line":15,"column":85}}}) : helper)))
    + "</a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"server_needs_upgrade") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"org-info org-upgrade small-font-size\">\n                    <a href=\"https://zulip.readthedocs.io/en/stable/production/upgrade-or-modify.html\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade to the latest release",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":161},"end":{"line":19,"column":199}}}))
    + "</a>\n                </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"org-info org-plan hidden-for-spectators small-font-size\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_plan_limited") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"/plans\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">Zulip Cloud Free</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_plan_standard") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":30,"column":20}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"/plans\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">Zulip Cloud Standard</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_plan_standard_sponsored_for_free") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":30,"column":20}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"/plans\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">Zulip Cloud Standard (sponsored)</a>\n                    ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"org-info org-upgrade small-font-size\">\n                <a href=\"/upgrade\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":93},"end":{"line":35,"column":138}}})) != null ? stack1 : "")
    + "</a>\n            </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "Upgrade to {standard_plan_name}";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_education_org") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":47,"column":23}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"org-info org-upgrade small-font-size\">\n                    <a href=\"/upgrade\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request education pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":97},"end":{"line":41,"column":131}}}))
    + "</a>\n                </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_business_org") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":43,"column":26},"end":{"line":43,"column":47}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":47,"column":16}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"org-info org-upgrade small-font-size\">\n                    <a href=\"/upgrade\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request sponsorship",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":97},"end":{"line":45,"column":125}}}))
    + "</a>\n                </li>\n                ";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"hidden-for-spectators\" role=\"presentation\">\n                <a href=\"/stats\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-bar-chart\" aria-hidden=\"true\"></i>\n                    <span>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Usage statistics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":26},"end":{"line":70,"column":51}}}))
    + "</span>\n                </a>\n            </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"presentation\">\n                <a href=\"/help/contact-support\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-envelope\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Contact support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":70},"end":{"line":120,"column":94}}}))
    + "\n                </a>\n            </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\"/billing\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-credit-card\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Billing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":73},"end":{"line":143,"column":89}}}))
    + "\n                </a>\n            </li>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\"https://github.com/sponsors/zulip\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-heart\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Support Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":150,"column":67},"end":{"line":150,"column":89}}}))
    + "\n                </a>\n            </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\"/plans\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-rocket\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Plans and pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":68},"end":{"line":157,"column":94}}}))
    + "\n                </a>\n            </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"presentation\">\n                <a href=\"#invite\" role=\"menuitem\">\n                    <i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":71},"end":{"line":165,"column":92}}}))
    + "\n                </a>\n            </li>\n            <li class=\"divider\" role=\"presentation\"></li>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Grant Zulip the Kerberos tickets needed to run your Zephyr mirror via Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":171,"column":23},"end":{"line":171,"column":111}}}))
    + "\" id=\"webathena_login_menu\" role=\"presentation\">\n                <a href=\"#webathena\" class=\"webathena_login\" role=\"menuitem\">\n                    <i class=\"fa fa-bolt\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link with Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":173,"column":65},"end":{"line":173,"column":93}}}))
    + "\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav\" role=\"navigation\">\n    <li class=\"dropdown actual-dropdown-menu\" id=\"gear-menu\">\n        <a id=\"settings-dropdown\" tabindex=\"0\" role=\"button\" class=\"dropdown-toggle\" data-target=\"nada\" data-toggle=\"dropdown\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":134},"end":{"line":3,"column":147}}}))
    + " (g)\">\n            <i class=\"fa fa-cog settings-dropdown-cog\" aria-hidden=\"true\"></i>\n        </a>\n        <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"settings-dropdown\">\n            <li class=\"invisible\" style=\"display:none;\" role=\"presentation\"><a href=\"#message_feed_container\" data-toggle=\"tab\"></a></li>\n            <li class=\"org-info org-name\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"realm_name") || (depth0 != null ? lookupProperty(depth0,"realm_name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"realm_name","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":56}}}) : helper)))
    + "</li>\n            <li class=\"org-info org-url\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"realm_uri") || (depth0 != null ? lookupProperty(depth0,"realm_uri") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"realm_uri","hash":{},"data":data,"loc":{"start":{"line":12,"column":41},"end":{"line":12,"column":54}}}) : helper)))
    + "</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_self_hosted") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_plan_limited") : depth0),(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":33,"column":18},"end":{"line":33,"column":48}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_plan_limited") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "            <li class=\"divider\" role=\"presentation\"></li>\n            <li class=\"hidden-for-spectators\" role=\"presentation\">\n                <a href=\"#streams/subscribed\" role=\"menuitem\">\n                    <i class=\"fa fa-exchange\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Manage streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":70},"end":{"line":52,"column":93}}}))
    + "\n                </a>\n            </li>\n            <li class=\"hidden-for-spectators\" role=\"presentation\">\n                <a href=\"#settings\" role=\"menuitem\">\n                    <i class=\"fa fa-wrench\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Personal settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":68},"end":{"line":57,"column":94}}}))
    + "\n                </a>\n            </li>\n            <li class=\"admin-menu-item hidden-for-spectators\" role=\"presentation\">\n                <a href=\"#organization\" role=\"menuitem\">\n                    <i class=\"fa fa-bolt\" aria-hidden=\"true\"></i>\n                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Manage organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":26},"end":{"line":63,"column":54}}}))
    + "</span>\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_guest") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + "            <li role=\"presentation\" class=\"only-visible-for-spectators\">\n                <a class=\"change-language-spectator\" role=\"menuitem\">\n                    <i class=\"zulip-icon zulip-icon-language\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":86},"end":{"line":76,"column":110}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\" class=\"only-visible-for-spectators\">\n                <a class=\"dark-theme\" role=\"menuitem\">\n                    <i class=\"fa fa-moon-o\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Switch to dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":68},"end":{"line":81,"column":97}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\" class=\"only-visible-for-spectators\">\n                <a class=\"light-theme\" role=\"menuitem\">\n                    <i class=\"fa fa-sun-o\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Switch to light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":67},"end":{"line":86,"column":97}}}))
    + "\n                </a>\n            </li>\n            <li class=\"divider\" role=\"presentation\"></li>\n            <li role=\"presentation\">\n                <a href=\"/help\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-question-circle\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":77},"end":{"line":92,"column":97}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\">\n                <a tabindex=\"0\" role=\"menuitem\" data-overlay-trigger=\"keyboard-shortcuts\">\n                    <i class=\"fa fa-keyboard-o\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":72},"end":{"line":97,"column":99}}}))
    + " <span class=\"hotkey-hint\">(?)</span>\n                </a>\n            </li>\n            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a tabindex=\"0\" role=\"menuitem\" data-overlay-trigger=\"message-formatting\">\n                    <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":68},"end":{"line":102,"column":95}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\">\n                <a tabindex=\"0\" role=\"menuitem\" data-overlay-trigger=\"search-operators\">\n                    <i class=\"fa fa-search\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search operators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":68},"end":{"line":107,"column":93}}}))
    + "\n                </a>\n            </li>\n            <li class=\"divider only-visible-for-spectators\" role=\"presentation\"></li>\n            <li role=\"presentation\" id=\"gear_menu_about_zulip\">\n                <a href=\"#about-zulip\" role=\"menuitem\" class=\"menuitem-version\">\n                    <span class=\"white_zulip_icon_without_text\"></span>\n                    <span class=\"about_zulip_text\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"About Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":51},"end":{"line":114,"column":71}}}))
    + "</span>\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"corporate_enabled") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":123,"column":19}}})) != null ? stack1 : "")
    + "            <li class=\"divider\" role=\"presentation\"></li>\n            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"apps_page_url") || (depth0 != null ? lookupProperty(depth0,"apps_page_url") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"apps_page_url","hash":{},"data":data,"loc":{"start":{"line":126,"column":25},"end":{"line":126,"column":44}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-desktop\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop & mobile apps",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":69},"end":{"line":127,"column":99}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\"/integrations\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-github\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Integrations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":68},"end":{"line":132,"column":89}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\" class=\"hidden-for-spectators\">\n                <a href=\"/api\" target=\"_blank\" rel=\"noopener noreferrer\" role=\"menuitem\">\n                    <i class=\"fa fa-sitemap\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"API documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":69},"end":{"line":137,"column":95}}}))
    + "\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_billing") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":12},"end":{"line":146,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"promote_sponsoring_zulip") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":12},"end":{"line":153,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_plans") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":12},"end":{"line":160,"column":19}}})) != null ? stack1 : "")
    + "            <li class=\"divider hidden-for-spectators\" role=\"presentation\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_invite_others_to_realm") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":12},"end":{"line":169,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_webathena") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":12},"end":{"line":176,"column":19}}})) != null ? stack1 : "")
    + "            <li role=\"presentation\" class=\"only-visible-for-spectators\">\n                <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"login_link") || (depth0 != null ? lookupProperty(depth0,"login_link") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"login_link","hash":{},"data":data,"loc":{"start":{"line":178,"column":25},"end":{"line":178,"column":39}}}) : helper)))
    + "\" role=\"menuitem\">\n                    <i class=\"fa fa-sign-in\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":69},"end":{"line":179,"column":84}}}))
    + "\n                </a>\n            </li>\n            <li role=\"presentation\">\n                <a href=\"#logout\" class=\"logout_button hidden-for-spectators\" role=\"menuitem\">\n                    <i class=\"fa fa-power-off\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":184,"column":71},"end":{"line":184,"column":87}}}))
    + "\n                </a>\n            </li>\n        </ul>\n    </li>\n</ul>\n";
},"useData":true});