var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='tip'>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Only organization owners can edit these settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":78}}}))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"organization-auth-settings\" class=\"settings-section\" data-name=\"auth-methods\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":15}}})) != null ? stack1 : "")
    + "    <form class=\"form-horizontal admin-realm-form org-authentications-form\">\n        <div id=\"org-auth_settings\" class=\"admin-table-wrapper org-subsection-parent\">\n            <div class =\"subsection-header\">\n                <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Authentication methods",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":51}}}))
    + "</h3>\n                "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"section_name":"auth_settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n\n            <div>\n                <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure the authentication methods for your organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":19},"end":{"line":13,"column":86}}}))
    + "</p>\n                <div id=\"id_realm_authentication_methods\">\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});