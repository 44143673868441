var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active-sub-filter";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero-topic-unreads";
},"5":function(container,depth0,helpers,partials,data) {
    return "muted_topic";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"unread_mention_info\">\n                @\n            </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active_topic") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_zero") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":115}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":116},"end":{"line":1,"column":150}}})) != null ? stack1 : "")
    + " topic-list-item' data-topic-name='"
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":185},"end":{"line":1,"column":199}}}) : helper)))
    + "'>\n    <span class='topic-box'>\n        <span class=\"sidebar-topic-check\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_resolved_prefix") || (depth0 != null ? lookupProperty(depth0,"topic_resolved_prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_resolved_prefix","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":37}}}) : helper)))
    + "\n        </span>\n        <a href='"
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":24}}}) : helper)))
    + "' class=\"topic-name\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":66}}}) : helper)))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_display_name") || (depth0 != null ? lookupProperty(depth0,"topic_display_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_display_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":34}}}) : helper)))
    + "\n        </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contains_unread_mention") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_zero") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":34},"end":{"line":14,"column":66}}})) != null ? stack1 : "")
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"unread") || (depth0 != null ? lookupProperty(depth0,"unread") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"unread","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":22}}}) : helper)))
    + "\n        </span>\n    </span>\n    <span class=\"topic-sidebar-menu-icon\">\n        <i class=\"zulip-icon zulip-icon-ellipsis-v-solid\" aria-hidden=\"true\"></i>\n    </span>\n</li>\n";
},"useData":true});