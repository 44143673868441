var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "data-sort=\"email\"";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-user-list\" class=\"settings-section\" data-name=\"user-list-admin\">\n\n    <div class=\"clear-float\"></div>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":25}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"user-field-status\"></div>\n        <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":55},"end":{"line":8,"column":76}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":90},"end":{"line":8,"column":111}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-condensed table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":85},"end":{"line":14,"column":98}}}))
    + "</th>\n                <th "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_sort_by_email") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":69}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":70},"end":{"line":15,"column":84}}}))
    + "</th>\n                <th class=\"user_id\" data-sort=\"id\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":67}}}))
    + "</th>\n                <th class=\"user_role\" data-sort=\"role\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":68}}}))
    + "</th>\n                <th class=\"last_active\" data-sort=\"last_active\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last active",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":64},"end":{"line":18,"column":84}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_users_table\" class=\"admin_user_table required-text thick\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No users match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":70}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_users_loading_indicator\"></div>\n</div>\n";
},"useData":true});