var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"remove_subscription\">\n        <div class=\"subscription_list_remove\">\n            <button type=\"button\" name=\"unsubscribe\" class=\"remove-subscription-button button small rounded btn-danger "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_private_stream_unsub_tooltip") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":119},"end":{"line":11,"column":186}}})) != null ? stack1 : "")
    + "\" data-tippy-content='"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Use stream settings to unsubscribe from private streams.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":208},"end":{"line":11,"column":272}}}))
    + "'>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unsubscribe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":36}}}))
    + "\n            </button>\n        </div>\n    </td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "tippy-zulip-tooltip";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-stream-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\">\n    <td class=\"subscription_list_stream\">\n        <span id=\"stream_sidebar_privacy_swatch_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_id") : depth0), depth0))
    + "\" class=\"stream-privacy filter-icon\" style=\"color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_color") : depth0), depth0))
    + "\">\n            "
    + ((stack1 = container.invokePartial(require("./stream_privacy.hbs"),depth0,{"name":"stream_privacy","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n        <a class = \"stream_list_item\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_edit_url") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_unsubscribe_button") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"usePartial":true,"useData":true});