var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "zero-pm-unreads";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"show_more_private_messages\" class=\"pm-list-item bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"more_conversations_unread_count") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":72},"end":{"line":1,"column":141}}})) != null ? stack1 : "")
    + "\">\n    <span>\n        <a class=\"pm-name\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"more conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":67}}}))
    + "</a>\n        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"more_conversations_unread_count") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":98}}})) != null ? stack1 : "")
    + "\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"more_conversations_unread_count") || (depth0 != null ? lookupProperty(depth0,"more_conversations_unread_count") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"more_conversations_unread_count","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":47}}}) : helper)))
    + "\n        </span>\n    </span>\n</li>\n";
},"useData":true});