var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value='"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":15,"column":35},"end":{"line":15,"column":43}}}) : helper)))
    + "'>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-horizontal admin-profile-field-form new-style\" id=\"add-new-custom-profile-field-form\">\n    <div class=\"new-profile-field-form wrapper\">\n        <div class=\"input-group\">\n            <label for=\"profile_field_type\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":58}}}))
    + "</label>\n            <select id=\"profile_field_type\" name=\"field_type\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"custom_profile_field_types") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\" id=\"profile_field_external_accounts\">\n            <label for=\"profile_field_external_accounts_type\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"External account type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":63},"end":{"line":12,"column":93}}}))
    + "</label>\n            <select id=\"profile_field_external_accounts_type\" name=\"external_acc_field_type\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_default_external_accounts") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "                <option value=\"custom\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":54}}}))
    + "</option>\n            </select>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"profile_field_name\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":45},"end":{"line":21,"column":59}}}))
    + "</label>\n            <input type=\"text\" id=\"profile_field_name\" name=\"name\" autocomplete=\"off\" maxlength=\"40\" />\n        </div>\n        <div class=\"input-group\">\n            <label for=\"profile_field_hint\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hint (up to 80 characters)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":45},"end":{"line":25,"column":80}}}))
    + "</label>\n            <input type=\"text\" id=\"profile_field_hint\" name=\"hint\" autocomplete=\"off\" maxlength=\"80\" />\n            <div class=\"alert\" id=\"admin-profile-field-hint-status\"></div>\n        </div>\n        <div class=\"input-group\" id=\"profile_field_choices_row\">\n            <label for=\"profile_field_choices\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Field choices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":48},"end":{"line":30,"column":70}}}))
    + "</label>\n            <table class=\"profile_field_choices_table\">\n                <tbody id=\"profile_field_choices\" class=\"profile-field-choices\"></tbody>\n            </table>\n        </div>\n        <div class=\"input-group\" id=\"custom_external_account_url_pattern\">\n            <label for=\"custom_field_url_pattern\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":51},"end":{"line":36,"column":71}}}))
    + "</label>\n            <input type=\"url\" id=\"custom_field_url_pattern\" name=\"url_pattern\" autocomplete=\"off\" maxlength=\"1024\" placeholder=\"https://example.com/path/%(username)s\"/>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"checkbox profile_field_display_label\" for=\"profile_field_display_in_profile_summary\">\n                <input type=\"checkbox\" id=\"profile_field_display_in_profile_summary\" name=\"display_in_profile_summary\"/>\n                <span></span>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Display on user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":45}}}))
    + "\n            </label>\n        </div>\n    </div>\n</form>\n";
},"useData":true});