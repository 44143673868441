var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"stream-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":38}}}))
    + "\">\n    <form id=\"stream_creation_form\">\n        <div class=\"alert stream_create_info\"></div>\n        <div id=\"stream_creating_indicator\"></div>\n        <div class=\"stream-creation-body\" data-simplebar>\n            <section class=\"block\">\n                <label for=\"create_stream_name\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":40}}}))
    + "\n                </label>\n                <input type=\"text\" name=\"stream_name\" id=\"create_stream_name\"\n                  placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":51}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_stream_name_length") : depth0), depth0))
    + "\" />\n                <div id=\"stream_name_error\" class=\"stream_creation_error\"></div>\n            </section>\n            <section class=\"block\">\n                <label for=\"create_stream_description\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":47}}}))
    + "\n                    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/change-the-stream-description"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </label>\n                <input type=\"text\" name=\"stream_description\" id=\"create_stream_description\"\n                  placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":31},"end":{"line":21,"column":58}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"max_stream_description_length") : depth0), depth0))
    + "\" />\n            </section>\n            <section class=\"block\" id=\"make-invite-only\">\n                <div class=\"stream-types\">\n                    "
    + ((stack1 = container.invokePartial(require("./stream_types.hbs"),depth0,{"name":"stream_types","hash":{"is_stream_edit":false,"stream_post_policy":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"stream_post_policy_values") : depth0)) != null ? lookupProperty(stack1,"everyone") : stack1)) != null ? lookupProperty(stack1,"code") : stack1)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </section>\n            <section class=\"block\">\n                <label for=\"people_to_add\">\n                    <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":64},"end":{"line":32,"column":91}}}))
    + "</h4>\n                </label>\n                <div id=\"stream_subscription_error\" class=\"stream_creation_error\"></div>\n                <div class=\"controls\" id=\"people_to_add\"></div>\n            </section>\n        </div>\n        <div class=\"modal-footer\">\n            <button class=\"button small white rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":76},"end":{"line":39,"column":91}}}))
    + "</button>\n            <button class=\"finalize_create_stream button small sea-green rounded\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":96},"end":{"line":40,"column":111}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});