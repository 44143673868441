var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Warning: <strong>{stream_name}</strong> is a private stream.";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose_private_stream_alert\" data-stream_name=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stream_name") || (depth0 != null ? lookupProperty(depth0,"stream_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stream_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":75}}}) : helper)))
    + "\">\n    <span>"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":84}}})) != null ? stack1 : "")
    + "</span>\n    <div class=\"compose_private_stream_alert_controls\">\n        <button type=\"button\" class=\"compose_private_stream_alert_close close\">&times;</button>\n    </div>\n</div>\n";
},"useData":true});