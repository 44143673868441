var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Drafts are not synced to other devices and browsers.";
},"3":function(container,depth0,helpers,partials,data) {
    return "Drafts older than <strong>{draft_lifetime}</strong> days are automatically removed.";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = container.invokePartial(require("./draft.hbs"),depth0,{"name":"draft","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"draft_overlay\" class=\"overlay new-style\" data-overlay=\"drafts\">\n    <div class=\"flex overlay-content\">\n        <div class=\"drafts-container modal-bg\">\n            <div class=\"drafts-header\">\n                <h1>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":35}}}))
    + "</h1>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n                <div class=\"removed-drafts\">\n                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":86}}})) != null ? stack1 : "")
    + "\n                    <br />\n                    "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":117}}})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n            <div class=\"drafts-list\">\n                <div class=\"no-drafts\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No drafts.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":38}}}))
    + "\n                </div>\n\n                <div id=\"drafts-from-conversation\">\n                    <h2>"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"narrow_drafts_header") : depth0), depth0))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"narrow_drafts") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n                <div id=\"other-drafts\">\n                    <h2 id=\"other-drafts-header\">Other drafts</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"other_drafts") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":30,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});