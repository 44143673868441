var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group_settings_header\" data-group-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n    <div class=\"tab-container\"></div>\n</div>\n<div class=\"user_group_settings_wrapper hide\" data-group-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n    <div class=\"inner-box\">\n\n        <div class=\"group_general_settings group_setting_section\">\n            <div class=\"group-header\">\n                <div class=\"group-name-wrapper\">\n                    <span class=\"group-name\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n                </div>\n                <div class=\"group_change_property_info alert-notification\"></div>\n                <div class=\"button-group\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":42},"end":{"line":13,"column":93}}})) != null ? stack1 : "")
    + ">\n                    <button id=\"open_group_info_modal\" class=\"button rounded small btn-warning tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias3,"Change group info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":136},"end":{"line":14,"column":162}}}))
    + "\">\n                        <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                    </button>\n                </div>\n            </div>\n            <div class=\"group-description-wrapper\">\n                <span class=\"group-description\">\n                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"group") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n                </span>\n            </div>\n        </div>\n\n        <div class=\"group_member_settings group_setting_section\">\n            <div class=\"edit_members_for_user_group\">\n                "
    + ((stack1 = container.invokePartial(require("./user_group_members.hbs"),depth0,{"name":"user_group_members","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});