var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "By deactivating your account, you will be logged out immediately.";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":82}}})) != null ? stack1 : "")
    + "</p>\n<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Note that any bots that you maintain will be disabled.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":66}}}))
    + "</p>\n<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Are you sure you want to deactivate your account?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":3,"column":60}}}))
    + "</p>\n";
},"useData":true});