var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form id=\"topic_edit_form\" class=\"form-horizontal\">\n    <input type=\"text\" value=\"\" class=\"inline_topic_edit header-v\" id=\"inline_topic_edit\"\n      autocomplete=\"off\" maxlength=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"max_topic_length") || (depth0 != null ? lookupProperty(depth0,"max_topic_length") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"max_topic_length","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":58}}}) : helper)))
    + "\" />\n    <button type=\"button\" class=\"topic_edit_save small_square_button animated-purple-button\"><i class=\"fa fa-check\" aria-hidden=\"true\"></i></button>\n    <button type=\"button\" class=\"topic_edit_cancel small_square_button small_square_x\"><i class=\"fa fa-remove\" aria-hidden=\"true\"></i></button>\n    <div class=\"alert alert-error edit_error\" style=\"display: none\"></div>\n    <div class=\"topic_edit_spinner\"></div>\n</form>\n";
},"useData":true});