var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Configure external code playgrounds for your Zulip organization. Code playgrounds are interactive in-browser development\n                environments, such as <z-link-repl>replit</z-link-repl>, that are designed to make it convenient to edit and debug\n                code. Zulip code blocks that are <z-link-markdown-help>tagged with a programming language</z-link-markdown-help> will have\n                a button visible on hover that allows you to open the code block in the code playground site.\n                \n                \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"https://replit.com/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/code-blocks\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"6":function(container,depth0,helpers,partials,data) {
    return "            For example, to configure code playgrounds for languages like Python or JavaScript, you could specify the <i>Language</i>\n            and <i>URL prefix</i> fields as:\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form class=\"form-horizontal admin-playground-form\">\n            <div class=\"add-new-playground-box grey-box\">\n                <div class=\"new-playground-form wrapper\">\n                    <div class=\"settings-section-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new code playground",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":58}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/code-blocks#code-playgrounds"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"alert\" id=\"admin-playground-status\"></div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_pygments_language\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":67},"end":{"line":34,"column":84}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_pygments_language\" name=\"pygments_language\" autocomplete=\"off\" placeholder=\"Python\" />\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_name\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":54},"end":{"line":38,"column":67}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_name\" name=\"playground_name\" autocomplete=\"off\" placeholder=\"Python3 playground\" />\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"playground_url_prefix\"> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL prefix",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":60},"end":{"line":42,"column":79}}}))
    + "</label>\n                        <input type=\"text\" id=\"playground_url_prefix\" name=\"url_prefix\" placeholder=\"https://replit.com/languages/python3/?code=\" />\n                    </div>\n                    <button type=\"submit\" id=\"submit_playground_button\" class=\"button rounded sea-green\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add code playground",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":52}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </form>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":40},"end":{"line":65,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"playground-settings\" class=\"settings-section\" data-name=\"playground-settings\">\n    <div class=\"admin-table-wrapper\">\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n        <ul>\n            <li><code>Python</code> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":48}}}))
    + " <code>https://replit.com/languages/python3/?code=</code></li>\n            <li><code>JavaScript</code> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":52}}}))
    + " <code>https://replit.com/languages/javascript/?code=</code></li>\n        </ul>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":54,"column":40}}}))
    + "</h3>\n            <input type=\"text\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":59},"end":{"line":55,"column":91}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter code playgrounds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":105},"end":{"line":55,"column":137}}}))
    + "\"/>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar>\n            <table class=\"table table-condensed table-striped wrapped-table admin_playgrounds_table\">\n                <thead class=\"table-sticky-headers\">\n                    <th class=\"active\" data-sort=\"pygments_language\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":69},"end":{"line":61,"column":86}}}))
    + "</th>\n                    <th data-sort=\"playground_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":52},"end":{"line":62,"column":65}}}))
    + "</th>\n                    <th data-sort=\"url\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL prefix",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":40},"end":{"line":63,"column":59}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + "                </thead>\n                <tbody id=\"admin_playgrounds_table\" class=\"required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No playgrounds configured.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":86},"end":{"line":68,"column":121}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-repl"],"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":149}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-markdown-help"],"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":156}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});