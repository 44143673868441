var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"stream\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_settings_link") : depth0), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./navbar_icon_and_title.hbs"),depth0,{"name":"navbar_icon_and_title","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n<div class=\"divider only-visible-for-spectators\">|</div>\n<a class=\"sub_count no-style tippy-zulip-tooltip hidden-for-spectators\" data-tippy-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sub_count_tooltip_text") : depth0), depth0))
    + "\" data-tippy-placement=\"bottom\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stream_settings_link") : depth0), depth0))
    + "\">\n    <i class=\"fa fa-user-o\"></i>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formatted_sub_count") : depth0), depth0))
    + "\n</a>\n<span class=\"narrow_description navbar-click-opens-search rendered_markdown\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_narrow_description") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_narrow_description") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":53}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(no description)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":28}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span class=\"navbar-click-opens-search\">\n    "
    + ((stack1 = container.invokePartial(require("./navbar_icon_and_title.hbs"),depth0,{"name":"navbar_icon_and_title","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stream_settings_link") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "")
    + "<span class=\"search_icon search_closed\" ><i class=\"fa fa-search\" aria-hidden=\"true\"></i></span>\n";
},"usePartial":true,"useData":true});