var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"change_email_container\" class=\"new-style\">\n    <label for=\"email\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"New email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":41}}}))
    + "</label>\n    <input type=\"text\" name=\"email\" value=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"delivery_email") : stack1), depth0))
    + "\" autocomplete=\"off\" spellcheck=\"false\" autofocus=\"autofocus\"/>\n</form>\n";
},"useData":true});