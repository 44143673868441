var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"above_composebox_narrow_url") || (depth0 != null ? lookupProperty(depth0,"above_composebox_narrow_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"above_composebox_narrow_url","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":71}}}) : helper)))
    + "\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"link_class") || (depth0 != null ? lookupProperty(depth0,"link_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link_class","hash":{},"data":data,"loc":{"start":{"line":3,"column":80},"end":{"line":3,"column":94}}}) : helper)))
    + "\" data-message-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"link_msg_id") || (depth0 != null ? lookupProperty(depth0,"link_msg_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link_msg_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":113},"end":{"line":3,"column":128}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"link_text") || (depth0 != null ? lookupProperty(depth0,"link_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link_text","hash":{},"data":data,"loc":{"start":{"line":3,"column":130},"end":{"line":3,"column":143}}}) : helper)))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-notifications-content\">\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"note") || (depth0 != null ? lookupProperty(depth0,"note") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"note","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":12}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"link_class") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":154}}})) != null ? stack1 : "")
    + "\n    <button type=\"button\" class=\"out-of-view-notification-close close\">&times;</button>\n</div>\n";
},"useData":true});