var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"u-"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? lookupProperty(stack1,"sender_id") : stack1), depth0))
    + " inline_profile_picture ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"sender_is_guest") || (depth0 != null ? lookupProperty(depth0,"sender_is_guest") : depth0)) != null ? helper : alias3),(options={"name":"sender_is_guest","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":55},"end":{"line":1,"column":108}}}),(typeof helper === alias4 ? helper.call(alias2,options) : helper));
  if (!lookupProperty(helpers,"sender_is_guest")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" aria-hidden=\"true\">\n    <img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"small_avatar_url") || (depth0 != null ? lookupProperty(depth0,"small_avatar_url") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"small_avatar_url","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":34}}}) : helper)))
    + "\" alt=\"\" class=\"no-drag\"/>\n</div>";
},"useData":true});