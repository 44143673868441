var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <option></option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"profile-settings\" class=\"settings-section show\" data-name=\"profile\">\n    <div class=\"profile-settings-form\">\n        <div class=\"profile-main-panel inline-block\">\n            <h3 class=\"inline-block hide\" id=\"user-profile-header\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":67},"end":{"line":4,"column":83}}}))
    + "</h3>\n            <div id=\"user_details_section\">\n                <div class=\"full-name-change-container\">\n                    <div class=\"input-group inline-block grid user-name-parent\">\n                        <div class=\"user-name-section inline-block\">\n                            <label for=\"full_name\" class=\"title inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Full name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":78},"end":{"line":9,"column":96}}}))
    + "</label>\n                            <i class=\"fa fa-question-circle change_name_tooltip tippy-zulip-tooltip settings-info-icon\"\n                              "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_can_change_name") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":30},"end":{"line":11,"column":85}}})) != null ? stack1 : "")
    + "\n                              data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name changes are disabled in this organization. Contact an administrator to change your name.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":50},"end":{"line":12,"column":152}}}))
    + "\">\n                            </i>\n                            <div class=\"alert-notification full-name-status\"></div>\n                            <div class=\"name-input\">\n                                <input id=\"full_name\" name=\"full_name\" type=\"text\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_can_change_name") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":119},"end":{"line":16,"column":181}}})) != null ? stack1 : "")
    + " maxlength=\"60\" />\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <form class=\"form-horizontal timezone-setting-form\">\n                    <div class=\"input-group grid\">\n                        <label for=\"timezone\" class=\"dropdown-title inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time zone",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":82},"end":{"line":24,"column":100}}}))
    + "</label>\n                        <div class=\"alert-notification timezone-setting-status\"></div>\n                        <div class=\"timezone-input\">\n                            <select name=\"timezone\" id=\"user_timezone\" class>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"timezone") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":30,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"timezones") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":34,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n                    </div>\n                </form>\n\n                <form class=\"form-horizontal custom-profile-fields-form grid\"></form>\n                <button class=\"button rounded sea-green w-200 block\" id=\"show_my_user_profile_modal\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":44}}}))
    + "\n                    <i class=\"fa fa-external-link\" aria-hidden=\"true\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":77},"end":{"line":43,"column":101}}}))
    + "\"></i>\n                </button>\n            </div>\n        </div>\n\n        <div class=\"profile-side-panel\">\n            <div class=\"inline-block user-avatar-section\">\n                "
    + ((stack1 = container.invokePartial(require("./image_upload_widget.hbs"),depth0,{"name":"image_upload_widget","hash":{"image":((stack1 = (depth0 != null ? lookupProperty(depth0,"page_params") : depth0)) != null ? lookupProperty(stack1,"avatar_url_medium") : stack1),"is_editable_by_current_user":(depth0 != null ? lookupProperty(depth0,"user_can_change_avatar") : depth0),"disabled_text":lookupProperty(helpers,"t").call(alias1,"Avatar changes are disabled in this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":34},"end":{"line":54,"column":88}}}),"delete_text":lookupProperty(helpers,"t").call(alias1,"Delete profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":32},"end":{"line":53,"column":60}}}),"upload_text":lookupProperty(helpers,"t").call(alias1,"Upload new profile picture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":32},"end":{"line":52,"column":64}}}),"widget":"user-avatar"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <div id=\"user-avatar-source\">\n                    <a href=\"https://en.gravatar.com/\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Avatar from Gravatar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":97},"end":{"line":58,"column":126}}}))
    + "</a>\n                </div>\n            </div>\n            <div class=\"user-details\">\n                <div class=\"input-group\">\n                    <span class=\"user-details-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":53},"end":{"line":63,"column":66}}}))
    + ":</span>\n                    <span class=\"user-details-desc\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"user_role_text") : depth0), depth0))
    + "</span>\n                </div>\n\n                <div class=\"input-group\">\n                    <span class=\"user-details-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Joined",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":53},"end":{"line":68,"column":68}}}))
    + ": </span>\n                    <span class=\"user-details-desc\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"date_joined_text") : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});