var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <label for=\"change_user_group_name\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":32}}}))
    + "\n    </label>\n    <input type=\"text\" id=\"change_user_group_name\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"group_name") || (depth0 != null ? lookupProperty(depth0,"group_name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"group_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":74}}}) : helper)))
    + "\" />\n</div>\n<div>\n    <label for=\"change_user_group_description\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":39}}}))
    + "\n    </label>\n    <textarea id=\"change_user_group_description\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"group_description") || (depth0 != null ? lookupProperty(depth0,"group_description") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"group_description","hash":{},"data":data,"loc":{"start":{"line":11,"column":49},"end":{"line":11,"column":72}}}) : helper)))
    + "</textarea>\n</div>\n";
},"useData":true});