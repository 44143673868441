var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"btn-wrapper inline-block\">\n                    <button type=\"button\" class=\"button small rounded sea-green message_edit_save\">\n                        <img class=\"loader\" alt=\"\" src=\"\" />\n                        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":43}}}))
    + "</span>\n                    </button>\n                </div>\n                <div class=\"btn-wrapper inline-block\">\n                    <button type=\"button\" class=\"button small rounded message_edit_cancel\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":91},"end":{"line":27,"column":106}}}))
    + "</button>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_editable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"message-edit-feature-group\">\n                    "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"button small rounded message_edit_close\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":86},"end":{"line":35,"column":100}}}))
    + "</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message-edit-timer\">\n                <span class=\"message_edit_countdown_timer\"></span>\n                <span>\n                    <i id=\"message_edit_tooltip\" class=\"tippy-zulip-tooltip message_edit_tooltip fa fa-question-circle\" aria-hidden=\"true\"\n                      data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":42},"end":{"line":42,"column":173}}})) != null ? stack1 : "")
    + "\">\n                    </i>\n                </span>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "This organization is configured to restrict editing of message content to {minutes_to_edit} minutes after it is sent.";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form id=\"edit_form_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\" class=\"new-style\">\n    <div class=\"alert\" id=\"message-edit-send-status-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\">\n        <span class=\"send-status-close\">&times;</span>\n        <span class=\"error-msg\"></span>\n    </div>\n    <div class=\"edit-controls\">\n        "
    + ((stack1 = container.invokePartial(require("./copy_message_button.hbs"),depth0,{"name":"copy_message_button","hash":{"message_id":(depth0 != null ? lookupProperty(depth0,"message_id") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <textarea class=\"message_edit_content\" maxlength=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_message_length") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"content") : depth0), depth0))
    + "</textarea>\n        <div class=\"scrolling_list preview_message_area\" id=\"preview_message_area_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message_id") : depth0), depth0))
    + "\" style=\"display:none;\">\n            <div class=\"markdown_preview_spinner\"></div>\n            <div class=\"preview_content rendered_markdown\"></div>\n        </div>\n    </div>\n    <div class=\"action-buttons\">\n        <div class=\"message_edit_spinner\"></div>\n        <div class=\"controls edit-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_editable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":36,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"is_editable") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":46,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"alert alert-error edit_error hide\"></div>\n</form>\n";
},"usePartial":true,"useData":true});