var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"profile-field-row movable-profile-field-row\" data-profile-field-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":79},"end":{"line":2,"column":85}}}) : helper)))
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"can_modify") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"profile_field_name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"profile_field_hint\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"hint") || (depth0 != null ? lookupProperty(depth0,"hint") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hint","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"profile_field_type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":49}}}) : helper)))
    + "</span>\n    </td>\n    <td class=\"display_in_profile_summary_cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid_to_display_in_summary") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"can_modify") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n        <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"profile_field_display_in_profile_summary\">\n            <label class=\"checkbox display_in_profile_summary_"
    + alias4(((helper = (helper = lookupProperty(helpers,"display_in_profile_summary") || (depth0 != null ? lookupProperty(depth0,"display_in_profile_summary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":19,"column":62},"end":{"line":19,"column":92}}}) : helper)))
    + "\" for=\"profile_field_display_in_profile_summary_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":19,"column":140},"end":{"line":19,"column":146}}}) : helper)))
    + "\">\n                <input class=\"display_in_profile_summary display_in_profile_summary_checkbox_"
    + alias4(((helper = (helper = lookupProperty(helpers,"display_in_profile_summary") || (depth0 != null ? lookupProperty(depth0,"display_in_profile_summary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"display_in_profile_summary","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":123}}}) : helper)))
    + "\" type=\"checkbox\" id=\"profile_field_display_in_profile_summary_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":186},"end":{"line":20,"column":192}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"display_in_profile_summary") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":194},"end":{"line":20,"column":254}}})) != null ? stack1 : "")
    + " data-profile-field-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":278},"end":{"line":20,"column":284}}}) : helper)))
    + "\"/>\n                <span></span>\n            </label>\n        </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <button class=\"button rounded small btn-warning open-edit-form-modal\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":85},"end":{"line":28,"column":98}}}))
    + "\" data-profile-field-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":123},"end":{"line":28,"column":129}}}) : helper)))
    + "\">\n            <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n        </button>\n        <button class=\"button rounded small delete btn-danger\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":70},"end":{"line":31,"column":85}}}))
    + "\" data-profile-field-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":110},"end":{"line":31,"column":116}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"profile_field") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":37,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});