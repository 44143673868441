var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <textarea maxlength=\"500\" class=\"custom_user_field_value\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"field_value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "</textarea>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_select_field") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"custom_user_field_value\">\n            <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"field_choices") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":85}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_user_field") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pill-container person_picker\">\n            <div class=\"input\" contenteditable=\"true\"></div>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_date_field") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value datepicker\" data-field-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" type=\"text\"\n          value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field_value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" />\n        <span class=\"remove_date\"><i class=\"fa fa-close\"></i></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_url_field") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value\" type=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"field_type") || (depth0 != null ? lookupProperty(depth0,"field_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":24,"column":53},"end":{"line":24,"column":69}}}) : helper)))
    + "\" value=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"field_value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\"2048\" />\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_pronouns_field") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":8}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value pronouns_type_field\" type=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"field_type") || (depth0 != null ? lookupProperty(depth0,"field_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":26,"column":73},"end":{"line":26,"column":89}}}) : helper)))
    + "\" value=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"field_value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\"50\" />\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"custom_user_field_value\" type=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"field_type") || (depth0 != null ? lookupProperty(depth0,"field_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"field_type","hash":{},"data":data,"loc":{"start":{"line":28,"column":53},"end":{"line":28,"column":69}}}) : helper)))
    + "\" value=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"field_value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\"50\" />\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"custom_user_field\" name=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" data-field-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n    <label class=\"inline-block\" for=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" class=\"title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</label>\n    <div class=\"alert-notification custom-field-status\"></div>\n    <div class=\"field_hint\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"field") : depth0)) != null ? lookupProperty(stack1,"hint") : stack1), depth0))
    + "</div>\n    <div class=\"field\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_long_text_field") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});