var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled\n  ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notification_setting") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":91}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "sub_notification_setting "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":91}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "muted-sub";
},"7":function(container,depth0,helpers,partials,data) {
    return "checked";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"mute-note "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":69}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "hide-mute-note";
},"14":function(container,depth0,helpers,partials,data) {
    return "        Muted streams don't show up in \"All messages\" or generate notifications unless you are mentioned.\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <i class=\"fa fa-question-circle settings-info-icon "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled_realm_setting") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":55},"end":{"line":24,"column":104}}})) != null ? stack1 : "")
    + " tippy-zulip-tooltip\"\n      data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Mobile push notifications are not configured on this server.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":26},"end":{"line":25,"column":95}}}))
    + "\">\n    </i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"sub_"
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":29}}}) : helper)))
    + "_setting\"\n  class=\"sub_setting_checkbox\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled_realm_setting") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":5,"column":98}}})) != null ? stack1 : "")
    + " new-style\">\n    <label class=\"checkbox\">\n        <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":35}}}) : helper)))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":7,"column":36},"end":{"line":7,"column":49}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":57},"end":{"line":7,"column":73}}}) : helper)))
    + "\"\n          class=\"sub_setting_control\" type=\"checkbox\"\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_checked") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":42}}})) != null ? stack1 : "")
    + "\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":55}}})) != null ? stack1 : "")
    + " />\n        <span></span>\n    </label>\n    <label class=\"inline\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":47}}}) : helper)))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":61}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":13,"column":63},"end":{"line":13,"column":72}}}) : helper)))
    + "</label>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,(depth0 != null ? lookupProperty(depth0,"setting_name") : depth0),"is_muted",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":16,"column":38}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,(depth0 != null ? lookupProperty(depth0,"setting_name") : depth0),"push_notifications",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":23,"column":10},"end":{"line":23,"column":48}}}),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});