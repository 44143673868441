var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(lookupProperty(helpers,"t").call(alias1,"Everyone sees this in their own time zone.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":51}}}))
    + "\n<br/>\n"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your time zone:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":24}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"tz_offset_str") || (depth0 != null ? lookupProperty(depth0,"tz_offset_str") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"tz_offset_str","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":44}}}) : helper)))
    + "\n";
},"useData":true});