var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"save-button-controls hide\">\n    <div class=\"inline-block organization-submission subsection-changes-save\">\n        <button class=\"save-discard-widget-button button primary save-button\" id=\"org-submit-"
    + alias4(((helper = (helper = lookupProperty(helpers,"section_name") || (depth0 != null ? lookupProperty(depth0,"section_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"section_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":93},"end":{"line":4,"column":109}}}) : helper)))
    + "\" data-status=\"save\">\n            <span class=\"fa fa-spinner fa-spin save-discard-widget-button-loading\"></span>\n            <span class=\"fa fa-check save-discard-widget-button-icon\"></span>\n            <span class=\"save-discard-widget-button-text\">\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Save changes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":37}}}))
    + "\n            </span>\n        </button>\n    </div>\n    <div class=\"inline-block subsection-changes-discard\">\n        <button class=\"save-discard-widget-button button discard-button\" id=\"org-discard-"
    + alias4(((helper = (helper = lookupProperty(helpers,"section_name") || (depth0 != null ? lookupProperty(depth0,"section_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"section_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":89},"end":{"line":13,"column":105}}}) : helper)))
    + "\">\n            <span class=\"fa fa-times save-discard-widget-button-icon\"></span>\n            <span class=\"save-discard-widget-button-text\">\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Discard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":32}}}))
    + "\n            </span>\n        </button>\n    </div>\n    <div class=\"inline-block subsection-failed-status\"><p class=\"hide\"></p></div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-notification "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"section_name") || (depth0 != null ? lookupProperty(depth0,"section_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"section_name","hash":{},"data":data,"loc":{"start":{"line":23,"column":31},"end":{"line":23,"column":47}}}) : helper)))
    + "-status\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_only_indicator") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":24,"column":11}}})) != null ? stack1 : "");
},"useData":true});