var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"5":function(container,depth0,helpers,partials,data) {
    return " prop-element";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":53}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"prefix") || (depth0 != null ? lookupProperty(depth0,"prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prefix","hash":{},"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":69}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":85}}}) : helper)))
    + "\"\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_muted") : depth0),(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":41}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":60}}})) != null ? stack1 : "")
    + "\n          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_checked") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":52}}})) != null ? stack1 : "")
    + " data-setting-widget-type=\"boolean\"\n          class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"setting_name") || (depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"setting_name","hash":{},"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":33}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":80}}})) != null ? stack1 : "")
    + "\"/>\n        <span></span>\n    </label>\n</td>\n";
},"useData":true});