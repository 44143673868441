var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),"prefer-html",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":46}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"input-group\" id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "-input-group\">\n                <label class=\"checkbox\">\n                    <input class=\"tag-checkbox\" id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" type=\"checkbox\"/>\n                    <span></span>\n                </label>\n                <label class=\"inline\" for=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0)) != null ? stack1 : "")
    + "</label>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <hr />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"copy-email-modal\">\n    <div class=\"new-style\">\n        <p class=\"question-which-parts\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Which parts of the email should be included in the Zulip message sent to this stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":105}}}))
    + "\n        </p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "        <hr />\n        <p class=\"stream-email-header\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream email address:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":41}}}))
    + "\n        </p>\n        <div class=\"stream-email\">\n            <div class=\"email-address\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"email_address") || (depth0 != null ? lookupProperty(depth0,"email_address") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"email_address","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":56}}}) : helper)))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});