var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"recent_topics_filter_buttons\" class=\"btn-group\" role=\"group\">\n    <div id=\"recent_filters_group\">\n        "
    + ((stack1 = container.invokePartial(require("./recent_topics_filters.hbs"),depth0,{"name":"recent_topics_filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"search_group\" role=\"group\">\n        <input type=\"text\" id=\"recent_topics_search\" value=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"search_val") : depth0), depth0))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Filter topics (t)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":110},"end":{"line":6,"column":136}}}))
    + "\" />\n        <button type=\"button\" class=\"btn clear_search_button\" id=\"recent_topics_search_clear\">\n            <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n        </button>\n    </div>\n</div>\n<div class=\"table_fix_head\" data-simplebar>\n    <table class=\"table table-responsive\">\n        <thead>\n            <tr>\n                <th data-sort=\"stream_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":44},"end":{"line":16,"column":59}}}))
    + "</th>\n                <th data-sort=\"topic_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":57}}}))
    + "</th>\n                <th class='participants_header'>"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Participants",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":69}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"last_msg_id\" class=\"last_msg_time_header active descend\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":113},"end":{"line":19,"column":126}}}))
    + "</th>\n            </tr>\n        </thead>\n        <tbody data-empty=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"No topics match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":72}}}))
    + "\"></tbody>\n    </table>\n</div>\n";
},"usePartial":true,"useData":true});