var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "            <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"full_name") || (depth0 != null ? lookupProperty(depth0,"full_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":19,"column":18},"end":{"line":19,"column":31}}}) : helper)))
    + "</span>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_bot") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"user_circle "
    + alias4(((helper = (helper = lookupProperty(helpers,"user_circle_class") || (depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":16,"column":41},"end":{"line":16,"column":62}}}) : helper)))
    + "  popover_user_presence\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"user_last_seen_time_status") || (depth0 != null ? lookupProperty(depth0,"user_last_seen_time_status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":16,"column":94},"end":{"line":16,"column":124}}}) : helper)))
    + "\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group-info\">\n    <div class=\"group-name\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"group_name") || (depth0 != null ? lookupProperty(depth0,"group_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"group_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":43}}}) : helper)))
    + " </div>\n    <div class=\"group-description\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"group_description") || (depth0 != null ? lookupProperty(depth0,"group_description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"group_description","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":29}}}) : helper)))
    + "\n    </div>\n</div>\n<hr />\n<ul class=\"nav nav-list member-list\" data-simplebar data-simplebar-auto-hide=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"members") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n<hr />\n<ul class=\"nav nav-list manage-group\">\n    <li>\n        <a href=\"#organization/user-groups-admin\">\n            <i class=\"fa fa-cog\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Manage user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":39}}}))
    + "\n        </a>\n    </li>\n</ul>\n";
},"useData":true});