var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"subscriber_list_add float-left\">\n    "
    + ((stack1 = container.invokePartial(require("./add_subscribers_form.hbs"),depth0,{"name":"add_subscribers_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n<br />\n\n"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Do you want to add everyone?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":6,"column":36}}}))
    + "\n<button class=\"add_all_users_to_stream small button rounded sea-green\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add all users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":71},"end":{"line":7,"column":92}}}))
    + "</button>\n\n<div class=\"create_stream_subscriber_list_header\">\n    <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":68}}}))
    + "</h4>\n    <input class=\"add-user-list-filter\" name=\"user_list_filter\" type=\"text\"\n      autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":65}}}))
    + "\" />\n</div>\n\n<div class=\"subscriber-list-box\">\n    <div class=\"subscriber_list_container\" data-simplebar>\n        <table class=\"subscriber-list table table-striped\">\n            <thead class=\"table-sticky-headers\">\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":33}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":34}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":36}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":35}}}))
    + "</th>\n            </thead>\n            <tbody id=\"create_stream_subscribers\" class=\"subscriber_table\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});