var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a id=\"invite-user-link\" href=\"#invite\"><i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite more users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":102},"end":{"line":27,"column":128}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"right-sidebar\" id=\"right-sidebar\" role=\"navigation\">\n    <div class=\"right-sidebar-items\">\n        <div id=\"user-list\">\n            <div id=\"userlist-header\">\n                <h4 class='sidebar-title'\n                  id='userlist-title' data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":58},"end":{"line":6,"column":80}}}))
    + " (w)\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"USERS",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":34}}}))
    + "\n                </h4>\n                <i id=\"user_filter_icon\" class=\"fa fa-search\"\n                  aria-hidden=\"true\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":49},"end":{"line":10,"column":71}}}))
    + "\"\n                  data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":60}}}))
    + " (w)\">\n                </i>\n            </div>\n            <div class=\"input-append notdisplayed\" id=\"user_search_section\">\n                <input class=\"user-list-filter home-page-input\" type=\"text\" autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search people",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":108},"end":{"line":15,"column":130}}}))
    + "\" />\n                <button type=\"button\" class=\"btn clear_search_button\" id=\"clear_search_people_button\">\n                    <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n            <div id=\"buddy_list_wrapper\" class=\"scrolling_list\" data-simplebar>\n                <ul id=\"user_presences\" class=\"filters required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No matching users.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":82},"end":{"line":21,"column":109}}}))
    + "\"></ul>\n                <div id=\"buddy_list_wrapper_padding\"></div>\n            </div>\n        </div>\n        <div class=\"right-sidebar-shortcuts\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_invite_others_to_realm") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "            <a id=\"sidebar-keyboard-shortcuts\" data-overlay-trigger=\"keyboard-shortcuts\" class=\"hidden-for-spectators\">\n                <i class=\"fa fa-keyboard-o fa-2x tippy-zulip-tooltip\" id=\"keyboard-icon\" data-tooltip-template-id=\"keyboard-icon-tooltip\"></i>\n                <template id=\"keyboard-icon-tooltip\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":53},"end":{"line":31,"column":80}}}))
    + " <span class=\"hotkey-hint\">(?)</span></template>\n            </a>\n            <div class=\"only-visible-for-spectators\">\n                <div class=\"realm-description\">\n                    <div class=\"rendered_markdown\">"
    + alias2(lookupProperty(helpers,"rendered_markdown").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_rendered_description") : depth0),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":35,"column":51},"end":{"line":35,"column":100}}}))
    + "</div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});