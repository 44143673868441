var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "org-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <i class=\"fa fa-question-circle settings-info-icon tippy-zulip-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mobile push notifications are not configured on this server.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":116},"end":{"line":16,"column":185}}}))
    + "\"></i>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":39,"column":33}}})) != null ? stack1 : "")
    + "                    </tr>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = container.invokePartial(require("./notification_settings_checkboxes.hbs"),depth0,{"name":"notification_settings_checkboxes","hash":{"prefix":(depths[2] != null ? lookupProperty(depths[2],"prefix") : depths[2]),"is_disabled":(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),"is_checked":(depth0 != null ? lookupProperty(depth0,"is_checked") : depth0),"setting_name":(depth0 != null ? lookupProperty(depth0,"setting_name") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tbody id=\"stream-specific-notify-table\">\n                "
    + ((stack1 = container.invokePartial(require("./stream_specific_notification_row.hbs"),depth0,{"name":"../settings/stream_specific_notification_row","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </tbody>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p><a class=\"send_test_notification\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Test desktop notification",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":45},"end":{"line":61,"column":79}}}))
    + "</a></p>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depths[1] != null ? lookupProperty(depths[1],"prefix") : depths[1]),"label":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_label") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":51}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_object") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":67,"column":25},"end":{"line":67,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "              disabled\n              ";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":(depths[1] != null ? lookupProperty(depths[1],"prefix") : depths[1]),"show_push_notifications_tooltip":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"show_push_notifications_tooltip") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":114,"column":46},"end":{"line":114,"column":94}}}),"label":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_label") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":113,"column":20},"end":{"line":113,"column":51}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"settings_object") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":112,"column":25},"end":{"line":112,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"notification-settings-form\">\n    <div class=\"general_notifications "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":117}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notification triggers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":46}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":5,"column":105},"end":{"line":5,"column":129}}}),"section_name":"general-notifiy-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure how Zulip notifies you about new messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":72}}}))
    + "</p>\n        <table class=\"notification-table table table-condensed table-bordered wrapped-table\">\n            <thead>\n                <tr>\n                    <th rowspan=\"2\" width=\"30%\"></th>\n                    <th colspan=\"2\" width=\"28%\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":48},"end":{"line":12,"column":63}}}))
    + "</th>\n                    <th rowspan=\"2\" width=\"14%\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"show_push_notifications_tooltip") : depth0)) != null ? lookupProperty(stack1,"push_notifications") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":142}}})) != null ? stack1 : "")
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":38}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"realm_push_notifications_enabled") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":15,"column":30},"end":{"line":15,"column":68}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":17,"column":31}}})) != null ? stack1 : "")
    + "                    </th>\n                    <th rowspan=\"2\" width=\"14%\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":61}}}))
    + "</th>\n                    <th rowspan=\"2\" width=\"14%\">@all\n                        <i class=\"fa fa-question-circle settings-info-icon tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Whether wildcard mentions like @all are treated as mentions for the purpose of notifications.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":116},"end":{"line":21,"column":218}}}))
    + "\"></i>\n                    </th>\n                </tr>\n                <tr>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Visual",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":38}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Audible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":39}}}))
    + "</th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"general_settings") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":41,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n\n    <div class=\"desktop_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":43},"end":{"line":51,"column":122}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":54,"column":54}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/desktop-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":57,"column":105},"end":{"line":57,"column":129}}}),"section_name":"desktop-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":62,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0)) != null ? lookupProperty(stack1,"desktop_notification_settings") : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":70,"column":17}}})) != null ? stack1 : "")
    + "\n        <label for=\"notification_sound\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notification sound",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":73,"column":39}}}))
    + "\n        </label>\n\n        <div class=\"input-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enable_sound_select") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":32},"end":{"line":76,"column":96}}})) != null ? stack1 : "")
    + "\">\n            <select name=\"notification_sound\" class=\"setting_notification_sound prop-element\" data-setting-widget-type=\"string\"\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enable_sound_select") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":14},"end":{"line":80,"column":25}}})) != null ? stack1 : "")
    + ">\n                <option value=\"none\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"None",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":37},"end":{"line":81,"column":50}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_object") : depth0)) != null ? lookupProperty(stack1,"available_notification_sounds") : stack1),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":84,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"play_notification_sound\">\n                <i class=\"fa fa-play-circle\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Play sound",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":57},"end":{"line":87,"column":76}}}))
    + "\"></i>\n            </span>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"desktop_icon_count_display\" class=\"dropdown-title\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"settings_label") : depth0)) != null ? lookupProperty(stack1,"desktop_icon_count_display") : stack1), depth0))
    + "</label>\n            <select name=\"desktop_icon_count_display\" class=\"setting_desktop_icon_count_display prop-element\"\n              data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":(depth0 != null ? lookupProperty(depth0,"desktop_icon_count_display_values") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <div class=\"mobile_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":100,"column":42},"end":{"line":100,"column":121}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":16},"end":{"line":103,"column":53}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/mobile-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":106,"column":104},"end":{"line":106,"column":128}}}),"section_name":"mobile-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0)) != null ? lookupProperty(stack1,"mobile_notification_settings") : stack1),{"name":"each","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":8},"end":{"line":116,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"email_message_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":119,"column":49},"end":{"line":119,"column":128}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":122,"column":52}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/email-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":125,"column":103},"end":{"line":125,"column":127}}}),"section_name":"email-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group form-horizontal time-limit-setting\">\n\n            <label for=\"email_notifications_batching_period\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delay before sending message notification emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":131,"column":16},"end":{"line":131,"column":73}}}))
    + "\n            </label>\n            <select name=\"email_notifications_batching_period_seconds\" class=\"setting_email_notifications_batching_period_seconds prop-element\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"email_notifications_batching_period_values") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":16},"end":{"line":136,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <div class=\"dependent-block\">\n                <label for=\"email_notification_batching_period_edit_minutes\" class=\"inline-block\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delay period (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":140,"column":20},"end":{"line":140,"column":51}}}))
    + ":\n                </label>\n                <input type=\"text\"\n                  name=\"email_notification_batching_period_edit_minutes\"\n                  class=\"email_notification_batching_period_edit_minutes admin-realm-time-limit-input\"\n                  data-setting-widget-type=\"time-limit\"\n                  autocomplete=\"off\"\n                  id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"prefix") : depth0), depth0))
    + "email_notification_batching_period_edit_minutes\"/>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0)) != null ? lookupProperty(stack1,"email_message_notification_settings") : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":8},"end":{"line":157,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"other_email_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":160,"column":47},"end":{"line":160,"column":126}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":16},"end":{"line":163,"column":37}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,(depth0 != null ? lookupProperty(depth0,"for_realm_settings") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":164,"column":102},"end":{"line":164,"column":126}}}),"section_name":"other-emails-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"notification_settings") : depth0)) != null ? lookupProperty(stack1,"other_email_settings") : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":8},"end":{"line":172,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</form>\n";
},"usePartial":true,"useData":true,"useDepths":true});