var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"muted-topic-settings\" class=\"settings-section\" data-name=\"muted-topics\">\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Muted topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":32}}}))
    + "</h3>\n        <input id=\"muted_topics_search\" class=\"search\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter muted topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":80},"end":{"line":4,"column":108}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter muted topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":122},"end":{"line":4,"column":150}}}))
    + "\"/>\n    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-condensed table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"stream\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":82}}}))
    + "</th>\n                <th data-sort=\"alphabetic\" data-sort-prop=\"topic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":66},"end":{"line":10,"column":80}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"date_muted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date muted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":87}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"muted_topics_table\" class=\"required-text\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not muted any topics yet.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":77},"end":{"line":14,"column":119}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"useData":true});