var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"invite_row\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_multiuse") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_admin") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"invited_at\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"invited_absolute_time") || (depth0 != null ? lookupProperty(depth0,"invited_absolute_time") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invited_absolute_time","hash":{},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":58}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"expiry_date_absolute_time") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td>\n        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"invited_as_text") || (depth0 != null ? lookupProperty(depth0,"invited_as_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invited_as_text","hash":{},"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":33}}}) : helper)))
    + "</span>\n    </td>\n    <td class=\"actions\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_multiuse") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "        <button class=\"button rounded small revoke btn-danger\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disable_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":63},"end":{"line":40,"column":112}}})) != null ? stack1 : "")
    + " data-invite-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":40,"column":129},"end":{"line":40,"column":135}}}) : helper)))
    + "\" data-is-multiuse=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"is_multiuse") || (depth0 != null ? lookupProperty(depth0,"is_multiuse") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"is_multiuse","hash":{},"data":data,"loc":{"start":{"line":40,"column":155},"end":{"line":40,"column":170}}}) : helper)))
    + "\">\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Revoke",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":41,"column":27}}}))
    + "\n        </button>\n    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"email\">\n            <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"link_url") || (depth0 != null ? lookupProperty(depth0,"link_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"link_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":21},"end":{"line":6,"column":33}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":35}}}))
    + "\n            </a>\n        </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"email\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":37}}}) : helper)))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"referred_by\">\n            <a data-user-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"invited_by_user_id") || (depth0 != null ? lookupProperty(depth0,"invited_by_user_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invited_by_user_id","hash":{},"data":data,"loc":{"start":{"line":17,"column":29},"end":{"line":17,"column":51}}}) : helper)))
    + "\" class=\"view_user_profile\" tabindex=\"0\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"referrer_name") || (depth0 != null ? lookupProperty(depth0,"referrer_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"referrer_name","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":109}}}) : helper)))
    + "</a>\n        </span>\n    </td>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"expires_at\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"expiry_date_absolute_time") || (depth0 != null ? lookupProperty(depth0,"expiry_date_absolute_time") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"expiry_date_absolute_time","hash":{},"data":data,"loc":{"start":{"line":26,"column":33},"end":{"line":26,"column":62}}}) : helper)))
    + "</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"expires_at\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Never expires",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":54}}}))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class=\"button rounded small resend btn-warning\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disable_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":64},"end":{"line":36,"column":113}}})) != null ? stack1 : "")
    + " data-invite-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":36,"column":130},"end":{"line":36,"column":136}}}) : helper)))
    + "\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Resend",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":27}}}))
    + "\n        </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"invite") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":45,"column":9}}})) != null ? stack1 : "");
},"useData":true});