var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"uploaded_file_row\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":42}}}) : helper)))
    + "\" data-attachment-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":64},"end":{"line":2,"column":70}}}) : helper)))
    + "\">\n    <td>\n        <a type=\"submit\" href=\"/user_uploads/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path_id") || (depth0 != null ? lookupProperty(depth0,"path_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path_id","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":56}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"View file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":107},"end":{"line":4,"column":125}}}))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":22}}}) : helper)))
    + "\n        </a>\n    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"create_time_str") || (depth0 != null ? lookupProperty(depth0,"create_time_str") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"create_time_str","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":29}}}) : helper)))
    + "</td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"size_str") || (depth0 != null ? lookupProperty(depth0,"size_str") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"size_str","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":22}}}) : helper)))
    + "</td>\n    <td class=\"actions\">\n        <span class=\"edit-attachment-buttons\">\n            <a type=\"submit\" href=\"/user_uploads/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path_id") || (depth0 != null ? lookupProperty(depth0,"path_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path_id","hash":{},"data":data,"loc":{"start":{"line":23,"column":49},"end":{"line":23,"column":60}}}) : helper)))
    + "\" class=\"btn no-style\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Download file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":90},"end":{"line":23,"column":112}}}))
    + "\" id=\"download_attachment\" download>\n                <i class=\"fa fa-download sea-green\" aria-hidden=\"true\"></i>\n            </a>\n        </span>\n        <span class=\"edit-attachment-buttons\">\n            <button type=\"submit\"\n              class=\"button small no-style remove-attachment\"\n              title=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":21},"end":{"line":30,"column":41}}}))
    + "\" data-attachment=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":30,"column":60},"end":{"line":30,"column":66}}}) : helper)))
    + "\">\n                <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n            </button>\n        </span>\n    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"attachment-messages\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"ind-message\" href=\"/#narrow/id/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                    #"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attachment") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":36,"column":9}}})) != null ? stack1 : "");
},"useData":true});