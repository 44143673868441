var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active-sub-filter";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero-pm-unreads";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"fa fa-group\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"user_circle_class") : depth0), depth0))
    + " user_circle\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":52}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_zero") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":90}}})) != null ? stack1 : "")
    + " pm-list-item bottom_left_row' data-user-ids-string='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"user_ids_string") : depth0), depth0))
    + "'>\n    <span class='pm-box' id='pm_user_status' data-user-ids-string='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"user_ids_string") : depth0), depth0))
    + "' data-is-group='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"is_group") : depth0), depth0))
    + "'>\n\n        <div class=\"pm_left_col\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_group") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <a href='"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "' class=\"conversation-partners\">\n            "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"recipients") : depth0), depth0))
    + "\n            "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),(depth0 != null ? lookupProperty(depth0,"status_emoji_info") : depth0),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </a>\n        <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_zero") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":34},"end":{"line":16,"column":66}}})) != null ? stack1 : "")
    + "\">\n            "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"unread") : depth0), depth0))
    + "\n        </span>\n    </span>\n</li>\n";
},"usePartial":true,"useData":true});