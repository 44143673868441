var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-process=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"data_process") || (depth0 != null ? lookupProperty(depth0,"data_process") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"data_process","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":35}}}) : helper)))
    + "\" class=\"alert alert-info "
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_class") || (depth0 != null ? lookupProperty(depth0,"custom_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_class","hash":{},"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":77}}}) : helper)))
    + "\">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"rendered_alert_content_html") || (depth0 != null ? lookupProperty(depth0,"rendered_alert_content_html") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rendered_alert_content_html","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\n    <span class=\"close\" data-dismiss=\"alert\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":57},"end":{"line":3,"column":71}}}))
    + "\" role=\"button\" tabindex=0>&times;</span>\n</div>\n\n";
},"useData":true});