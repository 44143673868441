var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"method_row\" data-method=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"is_disabled":lookupProperty(helpers,"not").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_owner") : depth0),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":32}}}),"label":(depth0 != null ? lookupProperty(depth0,"method") : depth0),"is_checked":(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),"prefix":(depth0 != null ? lookupProperty(depth0,"prefix") : depth0),"setting_name":"realm_authentication_methods"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});