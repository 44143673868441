var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"api_key_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"api_key_modal_label\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title\" id=\"api_key_modal_label\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":41}}}))
    + "\n                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\">\n                <div id=\"password_confirmation\">\n                    <span class=\"alert-notification\" id=\"api_key_status\"></span>\n                    <div id=\"api_key_form\">\n                        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Please re-enter your password to confirm your identity.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":91}}}))
    + "\n                            <a href=\"/accounts/password/reset/\" target=\"_blank\" rel=\"noopener noreferrer\">\n                                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Never had one? Forgotten it?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":69}}}))
    + "\n                            </a>\n                        </p>\n                        <div class=\"password-div\">\n                            <label for=\"password\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Current password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":75}}}))
    + "</label>\n                            <input type=\"password\" autocomplete=\"off\" name=\"password\" id=\"get_api_key_password\" value=\"\" />\n                            <i class=\"fa fa-eye-slash password_visibility_toggle tippy-zulip-tooltip\" role=\"button\"></i>\n                        </div>\n                    </div>\n                </div>\n                <div id=\"show_api_key\">\n                    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your API key:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":23},"end":{"line":27,"column":45}}}))
    + "</p>\n                    <p><b><span id=\"api_key_value\"></span></b></p>\n                    <div id=\"user_api_key_error\" class=\"text-error\"></div>\n                </div>\n            </main>\n            <footer class=\"modal__footer\">\n                <button type=\"submit\" name=\"view_api_key\" id=\"get_api_key_button\" class=\"modal__btn dialog_submit_button\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Get API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":40}}}))
    + "\n                </button>\n                <div id=\"api_key_buttons\">\n                    <button class=\"modal__btn dialog_submit_button\" id=\"regenerate_api_key\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":104},"end":{"line":37,"column":133}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Generate new API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":135},"end":{"line":37,"column":164}}}))
    + "</button>\n                    <a class=\"modal__btn dialog_submit_button\" id=\"download_zuliprc\" download=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"zuliprc") || (depth0 != null ? lookupProperty(depth0,"zuliprc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"zuliprc","hash":{},"data":data,"loc":{"start":{"line":38,"column":95},"end":{"line":38,"column":106}}}) : helper)))
    + "\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download .zuliprc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":121},"end":{"line":38,"column":147}}}))
    + "</a>\n                </div>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"useData":true});