var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                Version {zulip_version}\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <br />\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "                    <i class=\"fa fa-copy tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":82},"end":{"line":20,"column":103}}}))
    + "\" data-tippy-placement=\"right\" data-clipboard-text=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"zulip_merge_base") || (depth0 != null ? lookupProperty(depth0,"zulip_merge_base") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"zulip_merge_base","hash":{},"data":data,"loc":{"start":{"line":20,"column":155},"end":{"line":20,"column":175}}}) : helper)))
    + "\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    Forked from upstream at {zulip_merge_base}\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"about-zulip\" class=\"overlay flex new-style\" tabindex=\"-1\" role=\"dialog\" data-overlay=\"about-zulip\" aria-hidden=\"true\">\n    <div class=\"overlay-content modal-bg\">\n        <button type=\"button\" class=\"exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":69}}}))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n        <div class=\"modal-body\">\n            <div class=\"about-zulip-logo\">\n                <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://zulip.com\"><img src=\"/static/images/logo/zulip-org-logo.svg\" alt=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":141},"end":{"line":6,"column":155}}}))
    + "\" /></a>\n            </div>\n            <p>\n                <strong>Zulip Server</strong>\n                <br />\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "                <i class=\"fa fa-copy tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":78},"end":{"line":14,"column":99}}}))
    + "\" data-tippy-placement=\"right\" data-clipboard-text=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"zulip_version") || (depth0 != null ? lookupProperty(depth0,"zulip_version") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"zulip_version","hash":{},"data":data,"loc":{"start":{"line":14,"column":151},"end":{"line":14,"column":168}}}) : helper)))
    + "\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_fork") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </p>\n            <p>\n                Copyright 2012–2015 Dropbox, Inc., 2015–2021 Kandra Labs, Inc., and contributors.\n            </p>\n            <p>\n                Zulip is <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://github.com/zulip/zulip#readme\">open-source software</a>,\n                distributed under the Apache 2.0 license.\n            </p>\n        </div>\n    </div>\n</div>\n";
},"useData":true});