var Handlebars = require("../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-mute-topic\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":13,"column":75},"end":{"line":13,"column":90}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":109},"end":{"line":13,"column":125}}}) : helper)))
    + "\">\n            <i class=\"zulip-icon zulip-icon-mute\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":30}}}))
    + "\n        </a>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-unmute-topic\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":20,"column":77},"end":{"line":20,"column":92}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":20,"column":111},"end":{"line":20,"column":127}}}) : helper)))
    + "\">\n            <i class=\"zulip-icon zulip-icon-mute\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":32}}}))
    + "\n        </a>\n    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-unstar-all-in-topic\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":29,"column":84},"end":{"line":29,"column":99}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":29,"column":118},"end":{"line":29,"column":134}}}) : helper)))
    + "\">\n            <i class=\"fa fa-star-o\" aria-hidden=\"true\"></i>\n            "
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":54}}})) != null ? stack1 : "")
    + "\n        </a>\n    </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "Unstar all messages in topic";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <hr />\n\n    <li>\n        <a tabindex=\"0\" class=\"sidebar-popover-move-topic-messages\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":54,"column":84},"end":{"line":54,"column":99}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":54,"column":118},"end":{"line":54,"column":134}}}) : helper)))
    + "\">\n            <i class=\"fa fa-arrows\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Move topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":30}}}))
    + "\n        </a>\n    </li>\n    <li>\n        <a tabindex=\"0\" class=\"sidebar-popover-toggle-resolved\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":60,"column":80},"end":{"line":60,"column":95}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":60,"column":114},"end":{"line":60,"column":130}}}) : helper)))
    + "\">\n            <i class=\"fa fa-check\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_is_resolved") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":63,"column":38}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":12},"end":{"line":65,"column":36}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a tabindex=\"0\" class=\"sidebar-popover-delete-topic-messages\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":73,"column":86},"end":{"line":73,"column":101}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":73,"column":120},"end":{"line":73,"column":136}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":75,"column":32}}}))
    + "\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list topics_popover\">\n    <li>\n        <p class=\"topic-name\">\n            <i class=\"fa fa-chevron-right\" aria-hidden=\"true\" style=\"color: "
    + alias4(((helper = (helper = lookupProperty(helpers,"color") || (depth0 != null ? lookupProperty(depth0,"color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":85}}}) : helper)))
    + "\"></i>\n            <b>"
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":29}}}) : helper)))
    + "</b>\n        </p>\n    </li>\n\n    <hr />\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_muted") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_starred_messages") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n    <li class=\"hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-mark-topic-read\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":37,"column":80},"end":{"line":37,"column":95}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":37,"column":114},"end":{"line":37,"column":130}}}) : helper)))
    + "\">\n            <i class=\"fa fa-book\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":39,"column":45}}}))
    + "\n        </a>\n    </li>\n\n    <li>\n        <a tabindex=\"0\" class=\"sidebar-popover-copy-link-to-topic\" data-stream-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"stream_id") || (depth0 != null ? lookupProperty(depth0,"stream_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":44,"column":83},"end":{"line":44,"column":98}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":44,"column":117},"end":{"line":44,"column":133}}}) : helper)))
    + "\">\n            <i class=\"fa fa-link\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":46,"column":38}}}))
    + "\n        </a>\n    </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_move_topic") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":69,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_realm_admin") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":4},"end":{"line":78,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});