var Handlebars = require("../../../../../../srv/zulip-npm-cache/7766aa30d572ce8608132f08f639a92a9b1dd01b/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"playground_row\">\n    <td>\n        <span class=\"playground_pygments_language\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pygments_language") || (depth0 != null ? lookupProperty(depth0,"pygments_language") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pygments_language","hash":{},"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":72}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"playground_name") || (depth0 != null ? lookupProperty(depth0,"playground_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":38},"end":{"line":7,"column":57}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_url_prefix\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"url_prefix") || (depth0 != null ? lookupProperty(depth0,"url_prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url_prefix","hash":{},"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":58}}}) : helper)))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"can_modify") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        <button class=\"button small delete btn-danger tippy-zulip-tooltip\" data-playground-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":95},"end":{"line":14,"column":101}}}) : helper)))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":123},"end":{"line":14,"column":138}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"playground_name") || (depth0 != null ? lookupProperty(depth0,"playground_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":14,"column":139},"end":{"line":14,"column":160}}}) : helper)))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":174},"end":{"line":14,"column":189}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"playground_name") || (depth0 != null ? lookupProperty(depth0,"playground_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"playground_name","hash":{},"data":data,"loc":{"start":{"line":14,"column":190},"end":{"line":14,"column":211}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"playground") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":20,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});